import { fsrColumnsDataOrders } from './variables/ColumnsDataOrders'
import FSRListTable from './components/List'
import Card from 'components/card'
import apiConfig from 'common/config/apiConfig'
import { useState, useEffect } from 'react'
import { useSpinner } from 'common/SpinnerLoader'
import { useToaster } from 'common/Toaster'
import ApiCaller from 'common/services/apiServices'
import CrawfordConstants from 'common/config/crawfordConstants'

/**
 * Renders the FSR List component with the fetched FSR data.
 *
 * @return {JSX.Element} The rendered FSR List component
 */
const FSRList = () => {
  const [allFSRData, setFSRData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const itemsPerPage = 10
  const [isFSRLoading, setFSRLoading] = useState(false)
  const apiService = ApiCaller()
  const { TOAST } = CrawfordConstants

  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()
  const fetchData = async () => {
    try {
      setFSRLoading(true)
      const apiUrl = apiConfig.FSR_LIST
      const payload = {
        page: currentPage,
        keyword: searchValue,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        setFSRData(response?.data?.data?.results)
        setTotalCount(response?.data?.data?.total || 0)
      } else {
        setFSRData([])
        setTotalCount(0)
      }
    } catch (error) {
      console.error('Error during API call:', error)
    } finally {
      setFSRLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchValue])

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const setGlobalFilter = (value) => {
    try {
      setCurrentPage(1)
      setSearchValue(value)
    } catch (err) {
      console.log('err', err)
    } finally {
      setFSRLoading(false)
    }
  }

  return (
    <>
      <Card extra={'w-full h-full bg-white mt-3'}>
        <FSRListTable
          tableData={allFSRData}
          columnsData={fsrColumnsDataOrders}
          totalCount={totalCount}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          isFSRLoading={isFSRLoading}
          setFSRLoading={setFSRLoading}
          setGlobalFilter={setGlobalFilter}
        />
      </Card>
    </>
  )
}

export default FSRList
