import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import { RxHamburgerMenu } from 'react-icons/rx'
import CrawfordConstants from 'common/config/crawfordConstants'

const apiService = ApiCaller()
const AssignInvestigator = ({ setSelectedInvestigator }) => {
  const { NO_DATA } = CrawfordConstants
  const [investigatorsNearestToHome, setInvestigatorsNearestToHome] = useState(
    []
  ) // State for fetched data
  const [investigatorsNearestToOffice, setInvestigatorsNearestToOffice] =
    useState([]) // State for fetched data
  const [assignment, setAssignment] = useState({})
  const [selectInvestigators, setSelectInvestigators] = useState([])
  const { id } = useParams()

  const fetchAssignmentById = async () => {
    try {
      const apiUrl = apiConfig.ASSIGNMENT_DETAIL // getOneAssignment API Call
      const payload = {
        _id: id,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.data) {
        setAssignment(response.data.data)
      }
    } catch (error) {
      console.error('Error fetching assignment:', error)
    }
  }

  const fetchNearestInvestigators = async () => {
    try {
      const apiUrl = apiConfig.NEAREST_INVESTIGATOR_LIST // Get Nearest Investigator API Call
      const payload = {
        officeLng: assignment.officeLng,
        officeLat: assignment.officeLat,
        homeLng: assignment.homeLng,
        homeLat: assignment.homeLat,
        surveyDate: assignment.surveyDate || undefined,
        type: 'investigator',
        showAll: false,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.data) {
        setInvestigatorsNearestToHome(response.data.data.nearestToHome)
        setInvestigatorsNearestToOffice(response.data.data.nearestToOffice)
      }
    } catch (error) {
      console.error('Error fetching investigators:', error)
    }
  }

  // Run `fetchAssignmentById` on component mount
  useEffect(() => {
    fetchAssignmentById()
  }, [id])

  // Run `fetchNearestInvestigators` only when `assignment` is updated
  useEffect(() => {
    if (Object.keys(assignment).length) {
      fetchNearestInvestigators()
    }
  }, [assignment])

  const handleSelectInvestigator = (investigator) => {
    setSelectedInvestigator(investigator)
  }

  return (
    <div>
      {/* Assign Investigator Home Section */}
      <div className="container mx-auto">
        <h2 className="mb-2 text-lg font-semibold">Investigator Home</h2>
        <div className="grid grid-cols-1 gap-4 p-2 sm:grid-cols-2 lg:grid-cols-3">
          {investigatorsNearestToHome.map((investigator, index) => (
            <div
              className={`rounded-lg border ${
                selectInvestigators.includes(investigator._id)
                  ? 'border-blue-600'
                  : 'border-gray-300'
              } bg-white shadow-md hover:cursor-pointer`}
              key={index}
              onClick={() => {
                handleSelectInvestigator(investigator)
              }}
            >
              {/* Profile Picture and Name */}
              <div className="flex items-center p-2">
                <div className="flex h-6 w-6 items-center justify-center rounded-full bg-blue-300">
                  <span className="text-lg font-semibold text-white">
                    {investigator.name.charAt(0).toUpperCase()}
                  </span>
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-semibold">{investigator.name}</h3>
                </div>
              </div>
              {/* Horizontal Line */}
              <hr className="border-t border-gray-300" />
              {/* Field of Expertise */}
              <div className="mb-2 mt-2 flex items-center px-4">
                <RxHamburgerMenu />
                <span className="ml-2 text-sm text-gray-600">
                  {investigator.field ? investigator.field : 'Investigator'}
                </span>
              </div>

              {/* Distance */}
              <div className="mb-2 flex items-center px-4">
                <RxHamburgerMenu />
                <span className="ml-2 text-sm text-gray-600">
                  {investigator.distance} km
                </span>
              </div>
            </div>
          ))}
          {investigatorsNearestToHome.length === 0 && (
            <div className="flex justify-between items-center mb-4">
              <div className="text-md flex w-full justify-center text-center text-gray-500 dark:text-gray-400">
                {NO_DATA}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Assign Investigator Office Section */}
      <div className="container mx-auto mt-4">
        <h2 className="mb-2 text-lg font-semibold">Investigator Site</h2>
        <div className="grid grid-cols-1 gap-4 p-2 sm:grid-cols-2 lg:grid-cols-3">
          {investigatorsNearestToOffice.map((investigator, index) => (
            <div
              className={`rounded-lg border ${selectInvestigators.includes(investigator._id) ? 'border-blue-600' : 'border-gray-300'} bg-white shadow-md hover:cursor-pointer`}
              key={index}
              onClick={() => handleSelectInvestigator(investigator)}
            >
              {/* Profile Picture and Name */}
              <div className="flex items-center p-2">
                <div className="flex h-6 w-6 items-center justify-center rounded-full bg-blue-300">
                  <span className="text-lg font-semibold text-white">
                    {investigator.name.charAt(0).toUpperCase()}
                  </span>
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-semibold">{investigator.name}</h3>
                </div>
              </div>
              {/* Horizontal Line */}
              <hr className="border-t border-gray-300" />
              {/* Field of Expertise */}
              <div className="mb-2 mt-2 flex items-center px-4">
                <RxHamburgerMenu />
                <span className="ml-2 text-sm text-gray-600">
                  {investigator.field ? investigator.field : 'Investigator'}
                </span>
              </div>

              {/* Distance */}
              <div className="mb-2 flex items-center px-4">
                <RxHamburgerMenu />
                <span className="ml-2 text-sm text-gray-600">
                  {investigator.distance} km
                </span>
              </div>
            </div>
          ))}
          {investigatorsNearestToOffice.length === 0 && (
            <div className="flex justify-between items-center mb-4">
              <div className="text-md flex w-full justify-center text-center text-gray-500 dark:text-gray-400">
                {NO_DATA}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AssignInvestigator
