export const invoiceExpenditureColumnsDataOrders = [
  {
    Header: 'S.No',
    accessor: 'index',
    disableSortBy: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
    disableSortBy: true,
  },
  {
    Header: 'Unit',
    accessor: 'unit',
    disableSortBy: true,
  },
  {
    Header: 'Rate',
    accessor: 'rate',
    disableSortBy: true,
  },
  {
    Header: 'Type',
    accessor: 'expenditureType',
    disableSortBy: true,
  },
  {
    Header: 'ACTIONS',
    accessor: 'CURRENCY ACTIONS',
    disableSortBy: true,
  },
]
