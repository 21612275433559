import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import Card from 'components/card'
import { useSpinner } from 'common/SpinnerLoader'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import { useToaster } from 'common/Toaster'
import SwitchWithText from 'components/switch-with-text'
import InputField from 'components/fields/InputField'
import CrawfordConstants from 'common/config/crawfordConstants'

const AddNOL = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const apiService = ApiCaller()
  const { TOAST } = CrawfordConstants

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nolName: '',
      code: '',
    },
  })
  const [isNOLActive, setNOLStatus] = useState(false)

  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()
  const changeNOLStatus = () => {
    setNOLStatus(!isNOLActive)
  }

  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.NOL_CREATE
      const payload = {
        _id: id ? id : undefined,
        name: formData.nolName,
        code: formData.code,
        status: isNOLActive,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        addToast({
          title: `Nature Loss "${formData.nolName}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        navigate(`/insurance/natureloss-list`)
      } else {
        addToast({ title: 'Nature Loss creation failed.', type: 'error' })
      }
    } catch (error) {
      addToast({ title: TOAST.MESSAGES.ERROR.swwError, type: 'error' })
    } finally {
      hideSpinner()
    }
  }

  const getNOLDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.NOL_DETAIL
      const response = await apiService.apiCall('post', apiUrl, { _id: id })
      if (response?.data?.value) {
        setValue('nolName', response?.data?.data?.name || '')
        setValue('code', response?.data?.data?.code || '')
        setNOLStatus(response?.data?.data?.status)
      } else {
        addToast({ title: 'Some Error Occurred', type: 'error' })
      }
    } catch (error) {
      console.error('Error:', error)
    } finally {
      hideSpinner()
    }
  }

  useEffect(() => {
    if (id) getNOLDetails()
  }, [id])

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
      <div className="flex justify-between mt-[25px] ">
        <div>
          <p className="text-xl font-bold text-navy-700 dark:text-white">
            {id ? 'Edit' : 'Add'} Nature Loss
          </p>
        </div>

        <div className="col-span-2 lg:col-span-1">
          <div className="flex w-full justify-end">
            <SwitchWithText
              textLeft="OFF"
              textRight="ON"
              checked={isNOLActive}
              onChange={changeNOLStatus}
            />
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="mt-7 grid grid-cols-2 gap-3">
          <div className="col-span-2 grid lg:col-span-1">
            <InputField
              extra="w-full"
              label="Nature of Loss"
              id="nolName"
              type="text"
              placeholder="Enter nature of loss"
              registrationProps={register('nolName', {
                required: 'Nature of Loss is required',
              })}
              isFieldRequired={true}
              state={errors.nolName && 'error'}
            />
            <div className="error-message text-right">
              {errors.nolName?.message}
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Nature Code"
              id="code"
              type="text"
              placeholder="Enter Nature Code"
              registrationProps={register('code', {
                required: 'Nature code is required',
              })}
              isFieldRequired={true}
              state={errors.code && 'error'}
            />
            <div className="error-message text-right">
              {errors.code?.message}
            </div>
          </div>
        </div>
        <div className="mt-4 flex w-full justify-end">
          <button
            className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
            type="submit"
          >
            <span>{id ? 'Update' : 'Save'}</span>
          </button>
          <button
            className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base focus:outline-none focus:ring-2 focus:ring-gray-700 active:ring-2 active:ring-gray-500`}
            type="button"
            onClick={() => navigate(`/insurance/natureloss-list`)}
          >
            <span>Cancel</span>
          </button>
        </div>
      </form>
    </Card>
  )
}

export default AddNOL
