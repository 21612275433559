import React, { useState, useEffect } from 'react'
import Dropdown from 'components/dropdown'
import { FiAlignJustify } from 'react-icons/fi'
import { BsArrowBarUp } from 'react-icons/bs'
import { IoMdLogOut, IoMdNotificationsOutline, IoMdAdd } from 'react-icons/io'
import { useNavigate, useLocation, Link, useParams } from 'react-router-dom'
import { useSpinner } from 'common/SpinnerLoader'
import ApiCaller from 'common/services/apiServices'
import apiConfig from 'common/config/apiConfig'
import { useToaster } from 'common/Toaster'
import CrawfordConstants from 'common/config/crawfordConstants'
import RoutesComponent from 'routes'
import { getUserInformations, getLoginEmployee } from 'common/commonFunction'
const { TOAST, HEY } = CrawfordConstants
const apiService = ApiCaller()

/**
 * Component for the Navbar.
 *
 * @param {Object} props - The properties for the Navbar component.
 * @return {JSX.Element} The JSX for the Navbar component.
 */

const Navbar = (props) => {
  const { addToast } = useToaster()
  const location = useLocation()
  const [breadcrum, setBreadcrum] = useState([])
  const [routesData, setRoutesData] = useState([])
  const { showSpinner, hideSpinner } = useSpinner()
  const [userData, setUserData] = useState(null) // Initialize with null
  const { id, emailId } = useParams()
  const navigate = useNavigate()
  const getUserInfo = async () => {
    try {
      showSpinner()
      const userInformationStore = JSON.parse(localStorage.getItem('userData'))
      if (userInformationStore) {
        setUserData(userInformationStore)
      }
      if (!userInformationStore) {
        const response = await getUserInformations()
        if (response?.data?.value === true) {
          const userInformation = response?.data?.data
          setUserData(userInformation)
          localStorage.setItem('userData', JSON.stringify(userInformation))
          if (userInformation?.email) {
            const loginEmployee = await getLoginEmployee(userInformation?.email)
            localStorage.setItem(
              'getLoginEmployee',
              JSON.stringify(loginEmployee?.data?.data)
            )
          }
        }
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  const { onOpenSidenav, mini, brandText, isExpand, hovered } = props
  const [darkmode, setDarkmode] = useState(localStorage.darkMode === 'true')

  const routes = RoutesComponent()

  useEffect(() => {
    if (routes) {
      setRoutesData(routes)
      getUserInfo()
    }
  }, [routes])

  useEffect(() => {
    /**
     * Asynchronously finds the route that matches the given location's pathname and sets the breadcrumb accordingly.
     *
     * @param {Object} location - The location object containing the pathname.
     * @return {Promise<void>} A promise that resolves when the breadcrumb is set.
     */
    const findRouteAndSetBreadcrumb = async (location) => {
      const route = await routesData?.find((route) => {
        if (location.pathname.startsWith('/locations')) {
          return route.path === '/locations'
        } else if (location.pathname.startsWith('/company-setup')) {
          return route.path === '/company-setup'
        } else if (location.pathname.startsWith('/customers')) {
          return route.path === '/customers'
        } else if (location.pathname.startsWith('/employee')) {
          return route.path === '/employee'
        } else if (location.pathname.startsWith('/insurance')) {
          return route.path === '/insurance'
        } else if (location.pathname.startsWith('/finance')) {
          return route.path === '/finance'
        } else if (location.pathname.startsWith('/assignments')) {
          return route.path === '/assignments'
        } else if (location.pathname.startsWith('/email')) {
          return route.path === '/email'
        } else if (location.pathname.startsWith('/masters')) {
          return route.path === '/masters'
        } else if (location.pathname.startsWith('/templates')) {
          return route.path === '/templates'
        } else if (
          route.path === location.pathname ||
          (route.path.includes(':') &&
            compareSegments(route.path, location.pathname))
        ) {
          return true
        }
        return false
      })

      if (route) {
        const breadcrumb =
          route.items?.find((item) =>
            id
              ? item.path.replace(':id', id) === location.pathname
              : emailId
                ? item.path.replace(':emailId', emailId) === location.pathname
                : item.path === location.pathname
          )?.breadcrum ||
          route.breadcrum ||
          []
        setBreadcrum(breadcrumb)
      } else {
        setBreadcrum([])
      }
    }
    /**
     * Compares segments of two paths.
     *
     * @param {string} routePath - The path of the route.
     * @param {string} locationPath - The path of the location.
     * @return {boolean} Returns true if segments match, false otherwise.
     */
    const compareSegments = (routePath, locationPath) => {
      const routeSegments = routePath.split('/')
      const locationSegments = locationPath.split('/')
      if (routeSegments.length !== locationSegments.length) return false
      for (let i = 0; i < routeSegments.length; i++) {
        if (
          !routeSegments[i].startsWith(':') &&
          routeSegments[i] !== locationSegments[i]
        ) {
          return false
        }
      }
      return true
    }

    findRouteAndSetBreadcrumb(location)
  }, [location, routesData, id])

  /**
   * handleLogout Function is used to handle user logout.
   * It sends a request to the server to logout the user,
   * clears local storage upon successful logout,
   * and navigates to the sign-in page.
   * Additionally, it displays a success toast message upon successful logout,
   * and an error toast message in case of failure.
   * @returns {Promise<void>} A Promise that resolves when the logout process is complete.
   */
  const handleLogout = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.LOGOUT_USER
      const payload = {}
      const response = await apiService.apiCall('post', apiUrl, payload)
      localStorage.clear()
      if (response.status === 200) {
        localStorage.clear()
        navigate('/sign-in')
        addToast({
          title: TOAST.MESSAGES.SUCCESS.logoutSuccess,
          type: 'success',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  useEffect(() => {
    if (darkmode) document.body.classList.add('dark')
    else document.body.classList.remove('dark')
  }, [darkmode])

  return (
    <nav
      className={`duration-175 linear fixed right-3 top-0 flex flex-row flex-wrap items-center justify-between rounded-xl pt-2 transition-all ${
        !mini && isExpand
          ? 'w-[90%] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]'
          : mini && hovered
            ? 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]'
            : isExpand === false
              ? 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_152px)] 2xl:w-[calc(100vw_-_152px)]'
              : 'w-90%'
      } z-50 p-2 backdrop-blur-xl dark:bg-[#0b14374d] md:right-[30px] md:top-0 xl:top-[0px]`}
    >
      <div className="ml-[6px]">
        <div className="h-6 pt-1 dark:text-white">
          {breadcrum &&
            breadcrum.map((item, index) => (
              <React.Fragment key={index}>
                {index > 0 && ' / '}{' '}
                {/* Add a separator if it's not the first breadcrumb */}
                <Link
                  to={item.link}
                  className="!no-underline text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
                >
                  {item.label}
                </Link>
              </React.Fragment>
            ))}
        </div>
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="!no-underline font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div className="mt-[3px] flex gap-2">
        <a
          href="/assignments/assignment-create"
          className={`!no-underline w-35 mt-2 flex cursor-pointer items-center justify-around rounded-xl bg-brand-500 p-2 text-sm text-white transition duration-200
          hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base`}
          type="submit"
        >
          <IoMdAdd className="mr-1" />
          Add Assignment
        </a>
        <div className=" relative flex  h-[61px] w-[200px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-8 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[200px] md:flex-grow-0 md:gap-1 xl:w-[150px] xl:gap-2">
          {/* start Notification */}
          <span
            className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden "
            onClick={onOpenSidenav}
          >
            <FiAlignJustify className="h-5 w-5 hover:font-bold hover:text-navy-700" />
          </span>

          <Dropdown
            button={
              <p className="cursor-pointer ">
                <IoMdNotificationsOutline className="h-4 w-4 text-gray-600 hover:font-bold hover:text-navy-700 dark:text-white" />
              </p>
            }
            animation="origin-[65%_0%] origin-top-middle md:origin-top-right transition-all duration-300 ease-in-out"
            children={
              <div className="flex w-[285px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
                <div className="flex items-center justify-between">
                  <p className="text-base font-bold text-navy-700 dark:text-white">
                    Notification
                  </p>
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    Mark all read
                  </p>
                </div>

                <button className="flex w-full items-center">
                  <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brand-400 to-brand-500 py-4 text-2xl text-white">
                    <BsArrowBarUp />
                  </div>
                  <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                    <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                      New Update: Crawford Coming Soon...
                    </p>
                    <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                      A new update for Our Crawford is available!
                    </p>
                  </div>
                </button>
              </div>
            }
            classNames={'py-2 top-4 -left-[90px] md:-left-[440px] w-max'}
          />

          <div
            className="cursor-pointer text-gray-600"
            onClick={() => {
              if (darkmode) {
                setDarkmode(false)
                localStorage.darkMode = false
              } else {
                setDarkmode(true)
                localStorage.darkMode = true
              }
            }}
          ></div>
          {/* Profile & Dropdown */}
          <Dropdown
            button={
              <span className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-blueSecondary uppercase text-white dark:bg-brand-400">
                {userData?.name
                  ? userData?.name.charAt(0)
                  : userData?.email.substring(0, 1) +
                    ' ' +
                    userData?.email.substring(1, 2)}
              </span>
            }
            children={
              <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                <div className="ml-4 mt-3">
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-bold  text-navy-700 dark:text-white">
                      👋 <span className="ml-1">{HEY}</span>{' '}
                      <span className="capitalize">{userData?.name}</span>
                    </p>{' '}
                  </div>
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-bold  text-navy-700 dark:text-white">
                      <span>{userData?.email}</span>
                    </p>{' '}
                  </div>
                </div>
                <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />
                <div
                  className="ml-4 mt-3 hover:cursor-pointer "
                  onClick={() => handleLogout()}
                >
                  <div className="flex items-center gap-2">
                    <p className="flex text-sm   font-medium text-red-500 hover:font-bold hover:text-red-500 ">
                      <IoMdLogOut className="m-0.5" />
                      <span className="ml-1">Log Out </span>
                    </p>{' '}
                  </div>
                </div>
              </div>
            }
            classNames={'py-2 top-8 -left-[180px] w-max'}
          />
        </div>
      </div>
    </nav>
  )
}

export default Navbar
