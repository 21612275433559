import React, { useState, useEffect } from 'react'
import EmailInterface from 'views/Email'
import { MdEmail, MdAssignmentTurnedIn, MdShoppingCart } from 'react-icons/md'
import { RiShieldUserFill } from 'react-icons/ri'
import { BsBuildingsFill } from 'react-icons/bs'
import { HiUserGroup, HiTemplate } from 'react-icons/hi'
import { FaHome, FaUserClock, FaFileInvoice, FaInbox } from 'react-icons/fa'
import { IoDocumentsSharp } from 'react-icons/io5'
import {
  FaMapLocationDot,
  FaRegFileLines,
  FaMoneyCheckDollar,
} from 'react-icons/fa6'
import locationRoutes from './views/Location/locationsRoutes'
import companyRoutes from './views/CompanySetup/companyRoutes'
import customersRoutes from 'views/Customers/customersRoutes'
import employeeRoutes from 'views/Employee/employeeRoutes'
import insuranceRoutes from 'views/Insurance/insuranceRoutes'
import assignmentsRoutes from 'views/Assignments/assignmentRoutes'
import financeRoutes from 'views/Finance/financeRoutes'
import fsrMasterRoutes from 'views/FsrMasters/fsrMasterRoutes'
import templatesRoutes from 'views/Templates/templatesRoutes'

const RoutesComponent = () => {
  const [routes, setRoutes] = useState([])
  useEffect(() => {
    setRoutes([
      {
        name: 'Home',
        path: '/',
        layout: '/afterLogin',
        icon: <FaHome className="text-inherit h-6 w-6" />,
        component: <EmailInterface />,
        isComing: false,
        isVisible: true,
      },
      {
        name: 'Email',
        path: '/email',
        icon: <MdEmail className="text-inherit h-6 w-6" />,
        collapse: true,
        items: [
          {
            name: 'Inbox',
            path: '/email/inbox',
            layout: '/afterLogin',
            icon: <FaInbox className="text-inherit h-6 w-6" />,
            component: <EmailInterface />,
            isComing: false,
            isVisible: true,
            breadcrum: [
              { label: 'Email', link: '' },
              { label: 'Inbox', link: '/email/inbox' },
            ],
          },
        ],
      },
      {
        name: 'Role',
        path: '/role',
        layout: '/afterLogin',
        icon: <RiShieldUserFill className="text-inherit h-6 w-6" />,
        // component: <MainProjectList />,
        isComing: false,
        isVisible: true,
      },
      {
        name: 'Company Setup',
        path: '/company-setup',
        icon: <BsBuildingsFill className="text-inherit h-6 w-6" />,
        collapse: true,
        items: companyRoutes,
      },
      {
        name: 'Locations',
        path: '/locations',
        icon: <FaMapLocationDot className="text-inherit h-6 w-6" />,
        collapse: true,
        items: locationRoutes,
      },
      {
        name: 'Finance',
        path: '/finance',
        icon: <FaMoneyCheckDollar className="text-inherit h-6 w-6" />,
        collapse: true,
        items: financeRoutes,
      },
      {
        name: 'Customers',
        path: '/customers',
        icon: <HiUserGroup className="text-inherit h-6 w-6" />,
        collapse: true,
        items: customersRoutes,
      },
      {
        name: 'Employee',
        path: '/employee',
        icon: <FaUserClock className="text-inherit h-6 w-6" />,
        collapse: true,
        items: employeeRoutes,
      },
      {
        name: 'Insurance',
        path: '/insurance',
        icon: <FaRegFileLines className="text-inherit h-6 w-6" />,
        collapse: true,
        items: insuranceRoutes,
      },
      // {
      //   name: 'Investigator',
      //   path: '/investigator',
      //   layout: '/afterLogin',
      //   icon: <IoShieldCheckmarkSharp className="text-inherit h-6 w-6" />,
      //   component: <AssignInvestigator />,
      //   isComing: false,
      //   isVisible: true,
      // },
      {
        name: 'Masters',
        path: '/masters',
        icon: <BsBuildingsFill className="text-inherit h-6 w-6" />,
        collapse: true,
        items: fsrMasterRoutes,
      },
      {
        name: 'Templates',
        path: '/templates',
        icon: <HiTemplate className="text-inherit h-6 w-6" />,
        collapse: true,
        items: templatesRoutes,
      },
      // {
      //   name: 'Invoice',
      //   path: '/invoice',
      //   layout: '/afterLogin',
      //   icon: <FaFileInvoice className="text-inherit h-6 w-6" />,
      //   // component: <CreateProjectList />,
      //   isComing: false,
      //   isVisible: true,
      // },
      {
        name: 'Assignments',
        path: '/assignments',
        icon: <FaFileInvoice className="text-inherit h-6 w-6" />,
        collapse: true,
        items: assignmentsRoutes,
      },
      {
        name: 'Approvals',
        path: '/approvals',
        layout: '/afterLogin',
        icon: <MdAssignmentTurnedIn className="text-inherit h-6 w-6" />,
        // component: <CreateProjectList />,
        isComing: false,
        isVisible: true,
      },
      {
        name: 'Logistics',
        path: '/logistics',
        layout: '/afterLogin',
        icon: <MdShoppingCart className="text-inherit h-6 w-6" />,
        // component: <CreateProjectList />,
        isComing: false,
        isVisible: true,
      },
      {
        name: 'Reports',
        path: '/reports',
        layout: '/afterLogin',
        icon: <IoDocumentsSharp className="text-inherit h-6 w-6" />,
        // component: <CreateProjectList />,
        isComing: false,
        isVisible: true,
      },
    ])
  }, [])

  return routes
}

export default RoutesComponent
