import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import Card from 'components/card'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { useToaster } from 'common/Toaster'
import CrawfordConstants from 'common/config/crawfordConstants'
import { useSpinner } from 'common/SpinnerLoader'
import { useParams, useNavigate } from 'react-router-dom'
import InputField from 'components/fields/InputField'
const apiService = ApiCaller()
const { TOAST } = CrawfordConstants

/**
 * Renders a form to add or edit a function.
 *
 * @return {JSX.Element} The rendered form component.
 */
const AddFunction = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      function_name: '',
    },
  })
  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()

  /**
   * Handles the form submission for creating a new function.
   *
   * @async
   * @function onFormSubmit
   * @param {Object} formData - The form data containing the function details.
   * @param {string} formData.function_name - The name of the function.
   * @returns {Promise<void>} A promise that resolves when the form submission is complete.
   */
  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.FUNCTION_CREATE
      const payload = {
        name: formData.function_name,
        _id: id ? id : undefined,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        addToast({
          title: `Function "${formData.function_name}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        navigate(`/employee/function-list`)
      } else {
        addToast({
          title: `Function creation failed.`,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  /**
   * Fetches the details of a specific function and sets the form values.
   *
   * @async
   * @function getFunctionDetails
   * @returns {Promise<void>} A promise that resolves when the function details are fetched and the form values are set.
   */
  const getFunctionDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.FUNCTION_DETAIL.replace(':id', id)
      const payload = {
        _id: id,
        checkRoles: false,
        accessOf: ['FuncEdit'],
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data === 'Access Denied') {
        addToast({
          title: 'Access Denied',
          type: 'error',
        })
        navigate(`/employee/function-list`)
      } else if (response?.data?.value) {
        setValue('function_name', response?.data?.data?.name || '')
      } else {
        addToast({
          title: 'Some Error Occured',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error verifying token:', error)
    } finally {
      hideSpinner()
    }
  }

  const handleCancel = () => {
    navigate(`/employee/function-list`)
  }

  useEffect(() => {
    if (id) {
      getFunctionDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
      <div className="mt-[25px]">
        <p className="text-xl font-bold text-navy-700 dark:text-white">
          {id ? 'Edit' : 'Add'} Function
        </p>
      </div>

      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="mt-7 grid grid-cols-1 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Function Name"
              id="function_name"
              type="text"
              placeholder="Enter function name"
              registrationProps={register('function_name', {
                required: 'Function name is required',
              })}
              isFieldRequired={true}
              state={errors.function_name && 'error'}
            />
            <div className="error-message text-right">
              {errors.function_name?.message}
            </div>
          </div>

          {/* Button aligned to the right */}
          <div className="flex w-full justify-end">
            <button
              className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
              type="submit"
            >
              <span>{id ? 'Update' : 'Save'}</span>
            </button>

            <button
              className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base focus:outline-none focus:ring-2 focus:ring-gray-700 active:ring-2 active:ring-gray-500`}
              type="button"
              onClick={handleCancel}
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </form>
    </Card>
  )
}

export default AddFunction
