import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Card from 'components/card'
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai' // Import icons for add and remove
import { FaCircleMinus, FaCirclePlus } from 'react-icons/fa6'
import InputField from 'components/fields/InputField'
import { useForm } from 'react-hook-form'
import { useSpinner } from 'common/SpinnerLoader'
import { useToaster } from 'common/Toaster'
import CrawfordConstants from 'common/config/crawfordConstants'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import PopupInput from './Popup'

const apiService = ApiCaller()
const { TOAST } = CrawfordConstants
const AddFSR = () => {
  const { id } = useParams()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {},
  })

  const initialForms = [
    {
      displayName: 'Overview',
      head: 0,
      isEditing: false, // New flag for editing mode
      items: [
        {
          name: 'Summary',
          type: 'User Input',
          value: 'Textarea',
          field:
            "This report examines an insurance claim for the demise of Mr. Jitendra Singh Tomar under the group personal accident policy. The investigation seeks to validate the claim's legitimacy, analyze the incident's context, and ascertain the insurer's responsibility.",
        },
      ],
    },
  ]

  const navigate = useNavigate()
  const { showSpinner, hideSpinner } = useSpinner()
  const { addToast } = useToaster()
  const [isLoading, setIsLoading] = useState(false)

  // Manage state for forms dynamically
  const [forms, setForms] = useState(initialForms)
  const [isEditing, setIsEditing] = useState({}) // Track which section is in edit mode
  const [editingThirdCol, setEditingThirdCol] = useState({}) //Track 3rd column is in edit mode
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [inputType, setInputType] = useState('text')
  const [editingItem, setEditingItem] = useState({
    //Track 1st column is in edit mode
    sectionIndex: null,
    itemIndex: null,
  })
  const [validationErrors, setValidationErrors] = useState({
    sections: {},
    items: {},
    inputTypes: {},
  })

  // Add state to track editing of input type
  const [editingInputType, setEditingInputType] = useState({
    //Track 2nd column is in edit mode
    sectionIndex: null,
    itemIndex: null,
  })
  // Add options for the dropdown
  //Second Column
  const inputTypeOptions = [
    { value: 'Custom Input', label: 'Custom Input' },
    // { value: 'ILA Fields', label: 'ILA Fields' },
    { value: 'System Fields', label: 'System Fields' },
    { value: 'Dropdown', label: 'Dropdown' },
  ]

  const handleInputTypeChange = (value, sectionIndex, itemIndex) => {
    const updatedForms = [...forms]
    updatedForms[sectionIndex].items[itemIndex].type = value
    setForms(updatedForms)

    // Optional: Validate input type
    if (value) {
      setValidationErrors((prev) => ({
        ...prev,
        inputTypes: {
          ...prev.inputTypes,
          [`${sectionIndex}-${itemIndex}`]: null,
        },
      }))
    }
  }

  const handleCancel = () => {
    navigate(`/masters/fsr-list`)
  }

  const validateField = (
    value,
    fieldName,
    category,
    sectionIndex,
    itemIndex
  ) => {
    const error = value.trim() ? null : `${fieldName} is required.`

    setValidationErrors((prev) => {
      const updatedCategory = { ...prev[category] }

      if (itemIndex !== null && itemIndex !== undefined) {
        // Handle nested structure for items or values
        if (!updatedCategory[sectionIndex]) updatedCategory[sectionIndex] = {}
        updatedCategory[sectionIndex][itemIndex] = error
      } else {
        // Handle flat structure for sections
        updatedCategory[sectionIndex] = error
      }

      return {
        ...prev,
        [category]: updatedCategory,
      }
    })

    return error
  }

  const validateSectionName = (sectionIndex) => {
    const displayName = forms[sectionIndex]?.displayName || ''
    const error = validateField(
      displayName,
      'Section Name',
      'sections',
      sectionIndex,
      null
    )

    if (!error) {
      setIsEditing((prev) => ({
        ...prev,
        [sectionIndex]: false,
      }))
    }
  }

  const handleValueChange = (value, sectionIndex, itemIndex) => {
    const updatedForms = [...forms]
    updatedForms[sectionIndex].items[itemIndex].value = value
    setForms(updatedForms)
    setIsPopupOpen(true)
  }

  const handleInputChangeForPopup = (value, sectionIndex, itemIndex) => {
    const updatedForms = [...forms]
    updatedForms[sectionIndex].items[itemIndex].field = value
    setForms(updatedForms)
    setIsPopupOpen(false)
  }

  useEffect(() => {
    setIsPopupOpen(isPopupOpen)
  }, [isPopupOpen, forms])

  const handleDropdownChange = (e) => {
    const selectedType = e.target.value
    setInputType(selectedType) // Set inputType based on dropdown selection

    // Open the popup for all input types
    setIsPopupOpen(true) // Open the popup when any type is selected
  }

  const handleItemClick = (sectionIndex, itemIndex) => {
    const selectedItem = forms[sectionIndex].items[itemIndex]
    const selectedType = selectedItem.value // Get the 'value' (which should be the type like 'Date', 'Text', etc.)

    setInputType(selectedType) // Update the inputType to the value of the selected item
  }

  // Toggle function to close the popup
  const togglePopup = () => {
    setIsPopupOpen(false)
  }

  // Function to add a new section
  const addSection = () => {
    const newSection = {
      displayName: 'New Section',
      head: forms.length,
      items: [
        { name: 'New Item', type: 'Custom Input', value: 'Text', field: '' },
      ],
    }
    setForms([...forms, newSection])
  }

  // Function to add a new column (row) to an existing section
  const addColumn = (sectionIndex) => {
    const updatedForms = [...forms]
    updatedForms[sectionIndex].items.push({
      name: 'New Column',
      type: 'Custom Input',
      value: 'Text',
      field: '',
    })
    setForms(updatedForms)
  }

  // Function to remove a section
  const removeSection = (sectionIndex) => {
    const updatedForms = forms.filter((_, index) => index !== sectionIndex)
    setForms(updatedForms)
  }

  // Function to remove a column (item) from a section
  const removeColumn = (sectionIndex, itemIndex) => {
    const updatedForms = [...forms]
    if (updatedForms.length < 0) {
      removeSection(sectionIndex)
    }
    updatedForms[sectionIndex].items = updatedForms[sectionIndex].items.filter(
      (_, index) => index !== itemIndex
    )

    // If no items remain in the section, remove the section
    if (updatedForms[sectionIndex].items.length === 0) {
      updatedForms.splice(sectionIndex, 1)
    }
    setForms(updatedForms)
  }

  const onFormSubmit = async (formData) => {
    try {
      // Validate if any new section has not been renamed
      const isSectionNameValid = forms.every((form) => {
        return (
          form.displayName.trim() !== '' && form.displayName !== 'New Section'
        )
      })

      // If the section name is invalid (still the default name), reject the submission
      if (!isSectionNameValid) {
        addToast({
          title: 'Please provide a name for all sections before saving.',
          type: 'error',
        })
        return // Stop further execution and do not make the API call
      }

      showSpinner()
      const apiUrl = apiConfig.FSR_SAVE
      const payload = {
        name: formData.fsrName,
        forms: forms,
        accessToken: process.env.REACT_APP_GOOGLE_KEY,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)

      // Check if the response is successful
      if (response?.data?.data) {
        addToast({
          title: `FSR "${formData.fsrName}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        navigate(`/masters/fsr-list`) // Navigate to the FSR list page after successful save
      } else {
        // If the response indicates failure
        addToast({
          title: `FSR ${id ? 'edit' : 'save'} failed. Please try again.`,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  const getFSRDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.FSR_DETAIL
      const payload = {
        name: id,
        accessToken: process.env.REACT_APP_GOOGLE_KEY,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data === 'Access Denied') {
        addToast({
          title: 'Access Denied',
          type: 'error',
        })
        navigate(`/masters/fsr-list`)
      } else if (response?.data?.value) {
        const fsrDetail = response?.data?.data
        setValue('fsrName', fsrDetail?.name)
        setForms(fsrDetail?.forms)
      } else {
        addToast({
          title: 'Some Error Occured',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error:', error)
    } finally {
      hideSpinner()
    }
  }

  useEffect(() => {
    if (id) getFSRDetails()
  }, [])

  const handleDisplayNameChange = (value, sectionIndex) => {
    const updatedForms = [...forms]
    updatedForms[sectionIndex].displayName = value
    setForms(updatedForms)
  }

  const handleItemNameChange = (value, sectionIndex, itemIndex) => {
    const updatedForms = [...forms]
    updatedForms[sectionIndex].items[itemIndex].name = value
    setForms(updatedForms)
  }

  return (
    <Card extra={'w-full h-full pb-6 pt-4 px-6'}>
      <div className="mt-4 mb-5">
        <p className="text-xl font-bold text-gray-800">
          {id ? 'Edit' : 'Add'} FSR Master
        </p>
      </div>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="mb-8 grid grid-cols-1 gap-3">
          <div className="mt-2 grid grid-cols-1 gap-3">
            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mt-2 w-full"
                label="FSR Name"
                id="fsrname"
                type="text"
                placeholder="Enter FSR name"
                registrationProps={register('fsrName', {
                  required: 'FSR name is required',
                })}
                isFieldRequired={true}
                state={errors.fsrName && 'error'}
              />
              <div className="error-message text-right">
                {errors.fsrName?.message}
              </div>
            </div>
          </div>
        </div>

        <div className="space-y-8">
          {forms.map((form, sectionIndex) => (
            <div
              key={form.head}
              className="rounded-lg shadow-md border border-gray-200 bg-white p-4"
            >
              {/* Section Header */}
              <div className="relative group">
                {/* <p className="mb-4 text-lg font-semibold text-gray-700">
                  {form.displayName}
                </p> */}
                <div className="mb-4">
                  {isEditing[sectionIndex] ? (
                    <input
                      type="text"
                      value={form.displayName}
                      className="border border-gray-300 rounded-md p-2 w-full focus:ring-2 focus:ring-brand-500"
                      onChange={(e) =>
                        handleDisplayNameChange(e.target.value, sectionIndex)
                      }
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') e.preventDefault()
                      }}
                      onBlur={() => validateSectionName(sectionIndex)} // Handle blur event
                      placeholder="Enter Section Name"
                    />
                  ) : (
                    <p
                      className="mb-4 text-lg font-semibold text-gray-700 cursor-pointer"
                      onClick={() =>
                        setIsEditing({ ...isEditing, [sectionIndex]: true })
                      }
                    >
                      {form.displayName || 'Enter Section name'}
                    </p>
                  )}

                  {validationErrors.sections?.[sectionIndex] && (
                    <p className="text-red-500 text-sm mt-1 text-end">
                      {validationErrors.sections[sectionIndex]}
                    </p>
                  )}
                </div>
                <div className="absolute top-0 right-0 flex space-x-2 opacity-0 group-hover:opacity-100">
                  {/* Add/Minus Icons on Header Hover */}
                  <FaCirclePlus
                    size={22}
                    color="green"
                    onClick={() => addSection()}
                  />
                  <FaCircleMinus
                    size={22}
                    color="red"
                    onClick={() => removeSection(sectionIndex)}
                  />
                </div>
              </div>
              {/* Table */}
              <table className="w-full table-auto border-collapse">
                <tbody>
                  {form.items.map((item, idx) => (
                    <tr
                      key={idx}
                      className={`${
                        idx % 2 === 0 ? 'bg-gray-50' : 'bg-white'
                      } group relative`}
                    >
                      {/* First Column */}
                      <td
                        className="px-4 py-2 text-sm text-gray-800 border-r border-gray-300"
                        style={{ width: '33%' }}
                      >
                        {editingItem.sectionIndex === sectionIndex &&
                        editingItem.itemIndex === idx ? (
                          <input
                            type="text"
                            value={item.name}
                            className={`border rounded-md p-2 w-full focus:ring-2 border-gray-300 focus:ring-brand-500`}
                            onChange={(e) =>
                              handleItemNameChange(
                                e.target.value,
                                sectionIndex,
                                idx
                              )
                            }
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') e.preventDefault()
                            }}
                            onBlur={() =>
                              setEditingItem({
                                sectionIndex: null,
                                itemIndex: null,
                              })
                            } // Revert to normal text on blur
                            placeholder="Enter Item Name"
                          />
                        ) : (
                          <p
                            className="text-sm cursor-pointer"
                            onClick={() =>
                              setEditingItem({ sectionIndex, itemIndex: idx })
                            }
                          >
                            {item.name || 'New Section'}
                          </p>
                        )}
                      </td>
                      {/* Second Column */}
                      <td
                        className="px-4 py-2 text-sm text-gray-800 border-r border-gray-300"
                        style={{ width: '33%' }}
                      >
                        {editingInputType.sectionIndex === sectionIndex &&
                        editingInputType.itemIndex === idx ? (
                          <select
                            value={item.type}
                            className={`border rounded-md p-2 w-full focus:ring-2 border-gray-300 focus:ring-brand-500`}
                            onChange={(e) =>
                              handleInputTypeChange(
                                e.target.value,
                                sectionIndex,
                                idx
                              )
                            }
                            onBlur={
                              () =>
                                setEditingInputType({
                                  sectionIndex: null,
                                  itemIndex: null,
                                }) // Revert to normal text on blur
                            }
                          >
                            <option value="">Select Input Type</option>
                            {inputTypeOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <p
                            className="text-sm cursor-pointer"
                            onClick={() =>
                              setEditingInputType({
                                sectionIndex,
                                itemIndex: idx,
                              })
                            }
                          >
                            {item.type || 'Select Input Type'}
                          </p>
                        )}
                      </td>
                      {/* Third Column */}
                      <td
                        className="px-4 py-2 text-sm text-gray-800"
                        style={{ width: '34%' }}
                      >
                        {/* {item.value} */}
                        {editingThirdCol.sectionIndex === sectionIndex &&
                        editingThirdCol.itemIndex === idx ? (
                          // Custom Input Field
                          item.type === 'Custom Input' ? (
                            <div>
                              <select
                                value={item.value}
                                className={`border rounded-md p-2 w-full focus:ring-2 border-gray-300 focus:ring-brand-500 `}
                                onChange={(e) => {
                                  handleValueChange(
                                    e.target.value,
                                    sectionIndex,
                                    idx
                                  )
                                  handleDropdownChange(e)
                                }}
                                onBlur={(e) => {
                                  if (!isPopupOpen) {
                                    setEditingThirdCol({
                                      sectionIndex: null,
                                      itemIndex: null,
                                    }) // Revert to default view if popup is not open
                                  }
                                }}
                              >
                                <option value="">Select Value Type</option>
                                {[
                                  'Text',
                                  'Date',
                                  'Textarea',
                                  'Time',
                                  'Number',
                                ].map((option) => (
                                  <option key={option} value={option}>
                                    {option.charAt(0).toUpperCase() +
                                      option.slice(1)}
                                  </option>
                                ))}
                              </select>

                              {isPopupOpen && (
                                <PopupInput
                                  onClose={() => setIsPopupOpen(false)}
                                  type={inputType}
                                  onSubmit={(value) => {
                                    handleInputChangeForPopup(
                                      value,
                                      sectionIndex,
                                      idx
                                    )
                                  }}
                                />
                              )}
                            </div>
                          ) : (
                            // Regular Input Field
                            <input
                              type="text"
                              value={item.value}
                              className={`border rounded-md p-2 w-full focus:ring-2 border-gray-300 focus:ring-brand-500`}
                              onChange={(e) =>
                                handleValueChange(
                                  e.target.value,
                                  sectionIndex,
                                  idx
                                )
                              }
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') e.preventDefault()
                              }}
                              onBlur={(e) => {
                                setEditingThirdCol({
                                  sectionIndex: null,
                                  itemIndex: null,
                                }) // Revert to default view if popup is not open
                              }}
                            />
                          )
                        ) : (
                          // Default value display when not in editing mode
                          <p
                            className="text-sm cursor-pointer"
                            onClick={() => {
                              setEditingThirdCol({
                                sectionIndex,
                                itemIndex: idx,
                              })
                              handleItemClick(sectionIndex, idx)
                            }}
                          >
                            {item.value || 'Enter Value'}
                          </p>
                        )}
                      </td>
                      {/* Add/Minus Icons on Row Hover (Horizontal) */}
                      <td className="absolute top-1/2 right-4 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 flex space-x-2">
                        <FaCirclePlus
                          size={22}
                          color="green"
                          onClick={() => addColumn(sectionIndex)}
                        />
                        <FaCircleMinus
                          size={22}
                          color="red"
                          onClick={() => removeColumn(sectionIndex, idx)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>

        {/* Button aligned to the right */}
        <div className="mt-4 flex w-full justify-end">
          <button
            className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
            type="submit"
          >
            <span>{id ? 'Update' : 'Save'}</span>
          </button>

          <button
            className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base focus:outline-none focus:ring-2 focus:ring-gray-700 active:ring-2 active:ring-gray-500`}
            type="button"
            onClick={handleCancel}
          >
            <span>Cancel</span>
          </button>
        </div>
      </form>
    </Card>
  )
}

export default AddFSR
