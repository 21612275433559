import React, { useState, useEffect } from 'react'
import CrawfordConstants from 'common/config/crawfordConstants'
import TooltipHorizon from '../../../../components/tooltip'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import { useToaster } from 'common/Toaster'
import { useSpinner } from 'common/SpinnerLoader'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import InputField from 'components/fields/InputField'
import Select from 'react-select'
import { FaCircleMinus, FaCirclePlus } from 'react-icons/fa6'
import { dropDownStyleWithErrorAndFocus } from 'common/commonFunction'
import moment from 'moment'

const CreateInvoice = () => {
  const apiService = ApiCaller()
  //   const navigate = useNavigate()
  const { assignmentId } = useParams()
  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()
  const [assignmentInvoiceData, setAssignmentInvoiceData] = useState()
  const [policyData, setPolicyData] = useState()
  const [options, setOptions] = useState([])
  const [customerOptions, setCustomerOptions] = useState([])
  const [shippedToOptions, setShippedToOptions] = useState([])
  const [officerOptions, setOfficerOptions] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [creditLimit, setCreditLimit] = useState(0)
  // const [invoiceTemplateData, setInvoiceTemplateData] = useState()
  const [rows, setRows] = useState([])
  const [isTableLoad, setIsTableLoad] = useState(false)
  const [dropdownOptions, setDropdownOptions] = useState([])
  const [editingCell, setEditingCell] = useState({
    rowIdx: null,
    colName: null,
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm({
    defaultValues: {},
  })

  // Function to add a new row
  const addColumn = () => {
    setRows([
      ...rows,
      {
        name: '',
        description: '',
        unit: 'Per',
        rate: '',
        quantity: '',
        amount: '',
      },
    ])
  }

  const selectedBilledTo = watch('billedTo')
  const selectedShippedTo = watch('shippedTo')
  const selectedInvoiceTemplate = watch('invoiceTemplate')

  // Function to remove a row by index
  const removeColumn = (index) => {
    if (rows.length > 1) {
      setRows(rows.filter((_, idx) => idx !== index))
    }
  }

  // Fetch data from API
  const fetchOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.INVOICE_TEMPLATE_LIST
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: { status: true },
        fields: [],
        page: inputValue ? 1 : 0,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  // Handle input change and trigger API call
  const handleInvoiceInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchOptions(inputValue)
    }
  }

  const handleSelectionChange = (invoice) => {
    if (!invoice) {
      fetchOptions() // Call the function to perform API request
      setOptions([])
    }
  }

  const fetchCustomerOptions = async (inputValue, addressName) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.CUSTOMER_LIST
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: { status: true },
        fields: [],
        page: inputValue ? 1 : 0,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        if (addressName === 'billedTo') {
          setCustomerOptions(newOptions)
        } else {
          setShippedToOptions(newOptions)
        }
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  // Handle input change and trigger API call
  const handleCustomerInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchCustomerOptions(inputValue, 'billedTo')
    }
  }

  const handleCustomerSelectionChange = (customer) => {
    if (!customer) {
      fetchCustomerOptions(null, 'billedTo') // Call the function to perform API request
      setCustomerOptions([])
      setIsTableLoad(false)
    }
  }

  // Handle input change and trigger API call
  const handleShippedToInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchCustomerOptions(inputValue, 'shippedTo')
    }
  }

  const handleShippedToSelectionChange = (customer) => {
    if (!customer) {
      fetchCustomerOptions(null, 'shippedTo') // Call the function to perform API request
      setShippedToOptions([])
    } else {
      fetchOfficerOptions()
    }
  }

  // Fetch data from API
  const fetchOfficerOptions = async () => {
    if (isChecked && selectedShippedTo?.value) {
      try {
        setIsLoading(true)
        const apiUrl = apiConfig.CUSTOMER_DETAIL
        const payload = {
          _id: selectedShippedTo?.value,
        }
        const response = await apiService.apiCall('post', apiUrl, payload)
        if (response?.data?.value) {
          const customerDetail = response?.data?.data
          setCreditLimit(
            customerDetail.creditLimitAlloted -
              customerDetail.creditLimitExhausted
          )
          const newOptions = customerDetail?.officers.map((item) => ({
            label: item.name,
            value: item._id,
          }))
          setOfficerOptions(newOptions)
        }
      } catch (error) {
        console.error('Error fetching options:', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleOfficerSelectionChange = (Officer) => {
    if (!Officer) {
      setOfficerOptions([])
    }
  }

  const policyNumber = async (policyDoc) => {
    if (policyDoc) {
      var data = {
        _id: policyDoc,
      }
      const apiUrl = apiConfig.GET_POLICY_DOC_NUMBER
      const response = await apiService.apiCall('post', apiUrl, data)
      if (response?.data?.value) {
        setPolicyData(
          response?.data?.data?.listOfDocuments[
            response?.data?.data?.listOfDocuments.length - 1
          ]
        )
      }
    }
  }

  const getOneAssignmentInvoice = async () => {
    try {
      showSpinner()
      const data = {
        _id: assignmentId,
      }
      const apiUrl = apiConfig.ASSIGNMENT_INVOICE_DETAIL
      const response = await apiService.apiCall('post', apiUrl, data)
      if (response?.data?.value) {
        setAssignmentInvoiceData(response?.data?.data)
        policyNumber(response?.data?.data?.policyDoc)
      }
    } catch (error) {
      console.error('Error uploading files:', error)
    } finally {
      hideSpinner()
    }
  }

  const getInvoiceTemplate = async () => {
    try {
      showSpinner()
      const data = {
        _id: selectedInvoiceTemplate?.value,
      }
      const apiUrl = apiConfig.GET_INVOICE_TEMPLATE
      const response = await apiService.apiCall('post', apiUrl, data)
      if (response?.data?.value) {
        // setInvoiceTemplateData(response?.data?.data)
        const formattedData = response?.data?.data?.map((item, idx) => {
          const rate = parseFloat(item.rate) || 1
          const quantity = parseFloat(item.quantity) || 1 // Default quantity to 1
          const amount = (rate * quantity).toFixed(2)

          return {
            name: item.name,
            expenditureName: setValue(`expenditureName-${idx}`, {
              label: item.name,
              value: item.name,
            }),
            description: item.description,
            unit: item.unit,
            rate: item.rate,
            quantity: quantity,
            amount: amount,
          }
        })
        setRows(formattedData)
        setIsTableLoad(true)
      }
    } catch (error) {
      console.error('Error uploading files:', error)
    } finally {
      hideSpinner()
    }
  }

  const onFormSubmit = async (formData) => {}

  useEffect(() => {
    getOneAssignmentInvoice()
  }, [])

  useEffect(() => {
    fetchOfficerOptions()
  }, [selectedShippedTo])

  // table states
  const handleInputChange = (value, rowIdx, colName) => {
    const updatedRows = rows.map((row, idx) => {
      if (idx === rowIdx) {
        const updatedRow = { ...row, [colName]: value }
        if (colName === 'rate' || colName === 'quantity') {
          const rate = parseFloat(updatedRow.rate) || 0
          const quantity = parseFloat(updatedRow.quantity) || 0
          updatedRow.amount = (rate * quantity).toFixed(2)
        }
        return updatedRow
      }
      return row
    })
    setRows(updatedRows)
  }

  const customerSameAsBilledTo = (checked) => {
    if (selectedBilledTo) {
      setIsChecked(checked)
      if (checked) {
        setValue('shippedTo', selectedBilledTo)
      } else {
        setValue('shippedTo', null)
      }
    }
  }

  const fetchDropdownData = async (inputValue) => {
    try {
      setIsLoading(true)
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: { status: true },
        fields: [],
        page: 1,
      }
      const apiUrl = apiConfig.INVOICE_EXPENDITURE_LIST
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setDropdownOptions(newOptions)
      }
    } catch (error) {
      console.error('Failed to fetch dropdown data', error)
    } finally {
      setIsLoading(false)
    }
  }
  // useEffect(() => {
  //     fetchDropdownData()
  // },[])

  const handleDropDownInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchDropdownData(inputValue)
    }
  }

  const handleDropDownSelectionChange = (invoice) => {
    if (!invoice) {
      fetchDropdownData() // Call the function to perform API request
    }
  }

  const handleDropDownChange = (selectedOption, rowIdx) => {
    const updatedRows = rows.map((row, idx) => {
      if (idx === rowIdx) {
        return {
          ...row,
          name: selectedOption ? selectedOption.label : '', // Update the 'name' field or reset if cleared
        }
      }
      return row
    })
    setRows(updatedRows)
  }

  return (
    <div className="mt-5 bg-white  rounded-lg">
      <div className="grid grid-cols-12 rounded-lg">
        <div className="p-4 col-span-12 lg:col-span-4 xl:col-span-4 rounded-lg">
          <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
            <div className="flex w-full justify-between">
              <p className="flex block text-gray-700 text-sm font-bold">
                {assignmentInvoiceData?.name}
              </p>
            </div>
          </div>
          <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
            <div className="flex w-full justify-between">
              <p className="text-sm text-gray-700 flex-1">Appointed By:</p>
              <p className="text-sm text-gray-800 flex-1 text-right">
                {assignmentInvoiceData?.customer?.name}
              </p>
            </div>
          </div>
          <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
            <div className="flex w-full justify-between">
              <p className="text-sm text-gray-700 flex-1">Insurer:</p>
              <p className="text-sm text-gray-800 flex-1 text-right">
                {assignmentInvoiceData?.insurerOffice?.name}
              </p>
            </div>
          </div>
          <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
            <div className="flex w-full justify-between">
              <p className="text-sm text-gray-700 flex-1">Department:</p>
              <p className="text-sm text-gray-800 flex-1 text-right">
                {assignmentInvoiceData?.department?.name || '-'}
              </p>
            </div>
          </div>
          <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
            <div className="flex w-full justify-between">
              <p className="text-sm text-gray-700 flex-1">Insured:</p>
              <p className="text-sm text-gray-800 flex-1 text-right">
                {assignmentInvoiceData?.insuredOffice?.name}
              </p>
            </div>
          </div>
          <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
            <div className="flex w-full justify-between">
              <p className="text-sm text-gray-700 flex-1">Cause of Loss:</p>
              <p className="text-sm text-gray-800 flex-1 text-right">
                {assignmentInvoiceData?.causeOfLoss?.name}
              </p>
            </div>
          </div>
          <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
            <div className="flex w-full justify-between">
              <p className="text-sm text-gray-700 flex-1">Nature of Loss:</p>
              {assignmentInvoiceData?.natureOfLoss.map((nature, index) => (
                <p key={index} className="text-sm text-gray-800 text-right">
                  {nature.name}
                  {assignmentInvoiceData?.natureOfLoss.length - 1 !== index ? (
                    <b>,</b>
                  ) : (
                    ''
                  )}
                </p>
              ))}
            </div>
          </div>
          {(assignmentInvoiceData?.insurerClaimId ||
            assignmentInvoiceData?.brokerClaimId ||
            assignmentInvoiceData?.insuredClaimId) && (
            <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
              <div className="flex w-full justify-between">
                <p className="text-sm text-gray-700 flex-1">Claim Number:</p>
                {assignmentInvoiceData?.insurerClaimId && (
                  <p className="text-sm text-gray-800 flex-1 text-right">
                    Insurer: {assignmentInvoiceData?.insurerClaimId}
                  </p>
                )}
                {assignmentInvoiceData.brokerClaimId && (
                  <p className="text-sm text-gray-800 flex-1 text-right">
                    Broker: {assignmentInvoiceData?.brokerClaimId}
                  </p>
                )}
                {assignmentInvoiceData.insuredClaimId && (
                  <p className="text-sm text-gray-800 flex-1 text-right">
                    Insurer: {assignmentInvoiceData?.insuredClaimId}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="p-4 col-span-12 lg:col-span-4 xl:col-span-4 rounded-lg"></div>
        <div className="p-4 col-span-12 lg:col-span-4 xl:col-span-4 rounded-lg mr-4">
          <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
            <div className="flex w-full justify-between">
              <p className="flex block text-sm font-bold">&nbsp;</p>
            </div>
          </div>
          <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
            <div className="flex w-full justify-between">
              <p className="text-sm text-gray-700 flex-1">Policy Name:</p>
              <p className="text-sm text-gray-800 flex-1 text-right">
                {policyData?.name || '-'}
              </p>
            </div>
          </div>
          <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
            <div className="flex w-full justify-between">
              <p className="text-sm text-gray-700 flex-1">Policy Number:</p>
              <p className="text-sm text-gray-800 flex-1 text-right">
                {policyData?.policyNo || '-'}
              </p>
            </div>
          </div>
          <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
            <div className="flex w-full justify-between">
              <p className="text-sm text-gray-700 flex-1">Policy Type:</p>
              <p className="text-sm text-gray-800 flex-1 text-right">
                {assignmentInvoiceData?.policyType?.name || '-'}
              </p>
            </div>
          </div>
          <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
            <div className="flex w-full justify-between">
              <p className="text-sm text-gray-700 flex-1">Policy Period:</p>
              <p className="text-sm text-gray-800 flex-1 text-right">
                {moment(new Date(policyData?.from)).format('DD MMM, YYYY')} - to
                -{moment(new Date(policyData?.to)).format('DD MMM, YYYY')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 !pt-0">
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="grid grid-cols-2 gap-3">
            <div className="col-span-2 lg:col-span-1">
              <label className="mb-4 block text-sm font-bold">
                Select Invoice Template <span className="text-red-500">*</span>
              </label>
              <Controller
                name="invoiceTemplate"
                control={control}
                rules={{ required: 'Invoice template is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    options={options}
                    placeholder="Search and select..."
                    classNamePrefix="select"
                    isClearable
                    isLoading={isLoading}
                    onInputChange={handleInvoiceInputChange}
                    onFocus={() => fetchOptions()}
                    noOptionsMessage={() => 'Type to search...'}
                    onChange={(invoiceTemplate) => {
                      field.onChange(invoiceTemplate)
                      handleSelectionChange(invoiceTemplate) // Call a function to handle API call
                    }}
                    styles={dropDownStyleWithErrorAndFocus(error)}
                  />
                )}
              />
              {errors.segment && (
                <div className="error-message text-right text-red-500">
                  {errors.segment.message}
                </div>
              )}
            </div>

            <div className="col-span-2 lg:col-span-1">
              <div className="flex w-full items-center justify-between">
                {selectedBilledTo && (
                  <button
                    className={`mt-8 w-14 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
                    type="button"
                    onClick={() => getInvoiceTemplate()}
                  >
                    <span>GO</span>
                  </button>
                )}
                {selectedShippedTo && (
                  <span className="text-md mb-2 font-bold text-gray-700">
                    Credit Limit Pending: {creditLimit || 0}
                  </span>
                )}
              </div>
            </div>

            <div className="col-span-2 lg:col-span-1">
              <label className="mb-4 block text-sm font-bold">
                Billed To <span className="text-red-500">*</span>
              </label>
              <Controller
                name="billedTo"
                control={control}
                rules={{ required: 'Billed To is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    options={customerOptions}
                    placeholder="Search and select..."
                    classNamePrefix="select"
                    isClearable
                    isLoading={isLoading}
                    onInputChange={handleCustomerInputChange}
                    onFocus={() => fetchCustomerOptions(null, 'billedTo')}
                    noOptionsMessage={() => 'Type to search...'}
                    onChange={(billedTo) => {
                      field.onChange(billedTo)
                      handleCustomerSelectionChange(billedTo) // Call a function to handle API call
                    }}
                    styles={dropDownStyleWithErrorAndFocus(error)}
                  />
                )}
              />
              {errors.billedTo && (
                <div className="error-message text-right text-red-500">
                  {errors.billedTo.message}
                </div>
              )}
            </div>
            <div className="col-span-2 lg:col-span-1">
              <label className="flex mb-4 block text-sm font-bold">
                Shipped To <span className="text-red-500">*</span>
                {selectedBilledTo && (
                  <label className="ml-4 text-sm font-bold flex items-center space-x-2">
                    <span>Same as Billed To</span>
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={isChecked}
                      onChange={(event) =>
                        customerSameAsBilledTo(event.target.checked)
                      }
                    />
                  </label>
                )}
              </label>

              <Controller
                name="shippedTo"
                control={control}
                rules={{ required: 'Shipped To is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    options={shippedToOptions}
                    placeholder="Search and select..."
                    classNamePrefix="select"
                    isClearable
                    isLoading={isLoading}
                    onInputChange={handleShippedToInputChange}
                    onFocus={() => fetchCustomerOptions(null, 'shippedTo')}
                    noOptionsMessage={() => 'Type to search...'}
                    onChange={(shippedTo) => {
                      field.onChange(shippedTo)
                      handleShippedToSelectionChange(shippedTo) // Call a function to handle API call
                      if (!shippedTo) {
                        setValue('officer', null)
                        setIsChecked(false)
                      }
                    }}
                    styles={dropDownStyleWithErrorAndFocus(error)}
                  />
                )}
              />
              {errors.shippedTo && (
                <div className="error-message text-right text-red-500">
                  {errors.shippedTo.message}
                </div>
              )}
            </div>
            <div className="col-span-2 lg:col-span-1">
              <label className="mb-4 block text-sm font-bold">
                Officer <span className="text-red-500">*</span>
              </label>
              <Controller
                name="officer"
                control={control}
                rules={{ required: 'Officer is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    options={officerOptions}
                    placeholder="Search and select..."
                    classNamePrefix="select"
                    isClearable
                    isLoading={isLoading}
                    noOptionsMessage={() => 'Type to search...'}
                    onChange={(officer) => {
                      field.onChange(officer)
                      handleOfficerSelectionChange(officer) // Call a function to handle API call
                    }}
                    styles={dropDownStyleWithErrorAndFocus(error)}
                  />
                )}
              />
              {errors.officer && (
                <div className="error-message text-right text-red-500">
                  {errors.officer.message}
                </div>
              )}
            </div>
            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="Fee Narration"
                id="FeeNarration"
                type="text"
                placeholder="Enter Fee Narration"
                registrationProps={register('FeeNarration')}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="Gross Assessed Loss"
                id="GrossAssessedLoss"
                type="text"
                placeholder="Enter Gross Assessed Loss"
                registrationProps={register('GrossAssessedLoss')}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="Gross Salvage"
                id="GrossSalvage"
                type="text"
                placeholder="Enter Gross Salvage"
                registrationProps={register('GrossSalvage')}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="Taxes"
                id="Taxes"
                type="text"
                placeholder="Enter Taxes"
                registrationProps={register('Taxes')}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="Excess Franchise"
                id="ExcessFranchise"
                type="text"
                placeholder="Enter Excess Franchise"
                registrationProps={register('ExcessFranchise')}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="Gross Depreciation"
                id="GrossDepreciation"
                type="text"
                placeholder="Enter Gross Depreciation"
                registrationProps={register('GrossDepreciation')}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="Gross Under Insurance"
                id="GrossUnderInsurance"
                type="text"
                placeholder="Enter Gross Under Insurance"
                registrationProps={register('GrossUnderInsurance')}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="mb-2 w-full"
                label="Net Payable"
                id="NetPayable"
                type="text"
                placeholder="Enter Net Payable"
                registrationProps={register('NetPayable')}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <label className="mt-1 block text-sm font-bold">
                Description
              </label>
              <textarea
                className="mt-2 w-full m-h-10 border rounded-xl p-2 resize-none"
                id="Description"
                placeholder="Enter Description"
                {...register('Description')}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="w-full bg-white gap-3 rounded-lg shadow-md p-4 !pt-0">
        {isTableLoad && (
          <>
            <div className="overflow-x-auto">
              <div className="max-h-[400px] overflow-y-auto rounded-md border-b border-b-gray-200">
                <table className="w-full min-w-[800px] text-sm text-gray-800 border border-gray-400  rounded-md overflow-hidden">
                  <thead>
                    <tr className="bg-gray-200 text-gray-800 uppercase text-sm text-center">
                      <th className="px-6 py-3 border-b">Name</th>
                      <th className="px-6 py-3 border-b">Description</th>
                      <th className="px-6 py-3 border-b">Unit</th>
                      <th className="px-6 py-3 border-b">Rate</th>
                      <th className="px-6 py-3 border-b">Quantity</th>
                      <th className="px-6 py-3 border-b">Amount</th>
                      <th className="px-4"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, rowIdx) => (
                      <tr
                        key={rowIdx}
                        className={`${
                          rowIdx % 2 === 0 ? 'bg-gray-50' : 'bg-white'
                        }  group`}
                      >
                        {/* Name Column */}
                        <td className="px-4 py-2 border border-gray-300">
                          {editingCell.rowIdx === rowIdx &&
                          editingCell.colName === 'name' ? (
                            // <select
                            //   value={row.name}
                            //   className="border border-gray-300 rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
                            //   onChange={(e) =>
                            //     handleInputChange(
                            //       e.target.value,
                            //       rowIdx,
                            //       'name'
                            //     )
                            //   }
                            //   onBlur={() =>
                            //     setEditingCell({ rowIdx: null, colName: null })
                            //   }
                            // >
                            //   <option value="">Select</option>
                            //   {dropdownOptions.map((option) => (
                            //     <option key={option.value} value={option.label}>
                            //       {option.label}
                            //     </option>
                            //   ))}
                            // </select>
                            <Controller
                              name={`expenditureName-${rowIdx}`}
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <Select
                                  {...field}
                                  options={dropdownOptions}
                                  placeholder="Search and select..."
                                  classNamePrefix="select"
                                  isClearable
                                  isLoading={isLoading}
                                  onInputChange={handleDropDownInputChange}
                                  onFocus={() => fetchDropdownData()}
                                  noOptionsMessage={() => 'Type to search...'}
                                  onChange={(name) => {
                                    field.onChange(name)
                                    handleDropDownChange(name, rowIdx)
                                    handleDropDownSelectionChange(name) // Call a function to handle API call
                                  }}
                                  onBlur={() =>
                                    setEditingCell({
                                      rowIdx: null,
                                      colName: null,
                                    })
                                  }
                                  styles={{
                                    ...dropDownStyleWithErrorAndFocus(error),
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999, // Ensure the dropdown is above other elements
                                    }),
                                  }}
                                  menuPortalTarget={document.body}
                                />
                              )}
                            />
                          ) : (
                            <p
                              className="cursor-pointer"
                              onClick={() =>
                                setEditingCell({ rowIdx, colName: 'name' })
                              }
                            >
                              {row.name || 'Select Name'}
                            </p>
                          )}
                        </td>

                        {/* Editable Columns */}
                        {['description', 'unit', 'rate', 'quantity'].map(
                          (col) => (
                            <td
                              key={col}
                              className={`px-6 py-3 border border-gray-300 ${
                                ['rate', 'quantity'].includes(col)
                                  ? 'text-right'
                                  : 'text-left'
                              }`}
                            >
                              {col === 'unit' ? (
                                // Non-editable unit column
                                <p>{row[col] || 'Per'}</p>
                              ) : editingCell.rowIdx === rowIdx &&
                                editingCell.colName === col ? (
                                <input
                                  type="text"
                                  value={row[col]}
                                  className="border border-gray-300 rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      rowIdx,
                                      col
                                    )
                                  }
                                  onBlur={() =>
                                    setEditingCell({
                                      rowIdx: null,
                                      colName: null,
                                    })
                                  }
                                />
                              ) : (
                                <p
                                  className="cursor-pointer"
                                  onClick={() =>
                                    setEditingCell({ rowIdx, colName: col })
                                  }
                                >
                                  {row[col] || `Enter ${col}`}
                                </p>
                              )}
                            </td>
                          )
                        )}

                        {/* Amount Column */}
                        <td className="px-6 py-3 border-b text-right">
                          <p>{row.amount || '0.00'}</p>
                        </td>
                        {/* Add/Minus Icons on Row Hover (Horizontal) */}
                        <td className="relative border-b">
                          <div className="absolute top-1/2 right-4 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 flex space-x-2 py-2 px-2 md:!px-0 md:right-2">
                            {rows.length > 1 ? (
                              <>
                                <FaCirclePlus
                                  size={22}
                                  color="green"
                                  onClick={() => addColumn(rowIdx)}
                                />
                                <FaCircleMinus
                                  size={22}
                                  color="red"
                                  onClick={() => removeColumn(rowIdx)}
                                />
                              </>
                            ) : (
                              <FaCirclePlus
                                size={22}
                                color="green"
                                onClick={() => addColumn(rowIdx)}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* Summary Section */}
            <div className="mt-4 pt-4 flex justify-end items-end gap-3 mx-2">
              <div className="text-right space-y-2">
                <p className="font-medium text-gray-700">Sub Total:</p>
                <p className="font-medium text-gray-700">CGST@9%:</p>
                <p className="font-medium text-gray-700">SGST@9%:</p>
                <p className="font-medium text-gray-700">IGST@18%:</p>
                <p className="font-medium text-gray-700">Rounding Off:</p>
                <p className="font-bold text-gray-900">Grand Total:</p>
              </div>
              <div className="text-right space-y-2">
                <p className="text-gray-600">0.00</p>
                <p className="text-gray-600">0.00</p>
                <p className="text-gray-600">0.00</p>
                <p className="text-gray-600">0.00</p>
                <p className="text-gray-600">0.00</p>
                <p className="font-bold text-gray-900">0.00</p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default CreateInvoice
