import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import Card from 'components/card'
import { useParams, useNavigate } from 'react-router-dom'
import { useSpinner } from 'common/SpinnerLoader'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import { useToaster } from 'common/Toaster'
import InputField from 'components/fields/InputField'
import { dropDownStyleWithErrorAndFocus } from 'common/commonFunction'

const apiService = ApiCaller()
const AddInvoiceExpenditure = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { showSpinner, hideSpinner } = useSpinner()
  const { addToast } = useToaster()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      expenditureTypeOptions: '',
      name: '',
      unit: '',
      rate: '',
      description: null,
    },
  })

  const expenditureTypeOptions = [
    { value: 'Fees', label: 'Fees' },
    { value: 'Travel & Conveyance', label: 'Travel & Conveyance' },
    { value: 'Photo', label: 'Photo' },
    { value: 'Music', label: 'Music' },
  ]

  const handleCancel = () => {
    navigate(`/finance/invoice-expenditure-list`)
  }

  useEffect(() => {
    setValue('rate', 1)
    if (id) {
      getInvoiceExpenditureDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.INVOICE_EXPENDITURE_CREATE
      const payload = {
        _id: id ? id : undefined,
        expenditureType: formData.expenditureType.value,
        name: formData.name,
        unit: formData.unit,
        rate: formData.rate,
        description: formData.description,
      }

      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        addToast({
          title: `Invoice Expenditure "${formData.name}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        navigate(`/finance/invoice-expenditure-list`)
      } else {
        const errorMessage = response?.data?.error?.message || 'Unknown error'
        addToast({
          title: `Invoice Expenditure creation failed: ${errorMessage}`,
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error creating officer:', error)
      addToast({
        title: 'Error creating officer',
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  const getInvoiceExpenditureDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.INVOICE_EXPENDITURE_DETAIL
      const payload = {
        _id: id,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data === 'Access Denied') {
        addToast({
          title: 'Access Denied',
          type: 'error',
        })
        navigate(`/finance/invoice-expenditure-list`)
      } else if (response?.data?.value) {
        const expenditureData = response?.data?.data
        setValue('expenditureType', {
          label: expenditureData?.expenditureType || '',
          value: expenditureData?.expenditureType || '',
        })
        setValue('name', expenditureData?.name || '')
        setValue('unit', expenditureData?.unit || '')
        setValue('rate', expenditureData?.rate || '')
        setValue('description', expenditureData?.description || '')
      } else {
        addToast({
          title: 'Some Error Occured',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error fetching officer details:', error)
    } finally {
      hideSpinner()
    }
  }

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="mt-7 grid grid-cols-2 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <p className="text-xl font-bold text-navy-700">
              {id ? 'Edit' : 'Add'} Invoice Expenditure
            </p>
          </div>
        </div>

        <div className="mt-7 grid grid-cols-2 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold text-navy-700">
              Expenditure Type
              <span className="text-red-500"> * </span>
            </label>
            <Controller
              name="expenditureType"
              control={control}
              rules={{ required: 'Expenditure Type is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={expenditureTypeOptions}
                  placeholder="Select expenditure type"
                  isClearable
                  classNamePrefix="select"
                  styles={dropDownStyleWithErrorAndFocus(error)}
                />
              )}
            />
            {errors.expenditureType && (
              <div className="error-message text-right text-red-500">
                {errors.expenditureType.message}
              </div>
            )}
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full "
              label="Document Name"
              id="DocumentName"
              type="text"
              placeholder="Enter Document Name"
              registrationProps={register('name', {
                required: 'Document name is required',
              })}
              isFieldRequired={true}
              state={errors.name && 'error'}
            />
            <div className="error-message text-right">
              {errors.name?.message}
            </div>
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="Unit"
              id="Unit"
              type="text"
              placeholder="Enter Unit"
              registrationProps={register('unit', {
                required: 'Unit is required',
              })}
              isFieldRequired={true}
              state={errors.unit && 'error'}
            />
            <div className="error-message text-right">
              {errors.unit?.message}
            </div>
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="Rate"
              id="Rate"
              type="text"
              readOnly
              placeholder="Enter Rate"
              registrationProps={register('rate', {
                required: 'Rate is required',
              })}
              isFieldRequired={true}
              state={errors.rate && 'error'}
            />
            <div className="error-message text-right">
              {errors.rate?.message}
            </div>
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="Description"
              id="Description"
              type="text"
              placeholder="Enter Description"
              registrationProps={register('description', {
                required: 'Description is required',
              })}
              isFieldRequired={true}
              state={errors.description && 'error'}
            />
            <div className="error-message text-right">
              {errors.description?.message}
            </div>
          </div>
        </div>
        <div className="mt-4 flex w-full justify-end">
          <button
            className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
            type="submit"
          >
            <span>{id ? 'Update' : 'Save'}</span>
          </button>

          <button
            className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base focus:outline-none focus:ring-2 focus:ring-gray-700 active:ring-2 active:ring-gray-500`}
            type="button"
            onClick={handleCancel}
          >
            <span>Cancel</span>
          </button>
        </div>
      </form>
    </Card>
  )
}

export default AddInvoiceExpenditure
