import axios from 'axios'

const handle401Error = () => {
  localStorage.clear()
  window.location.href = '/sign-in'
}

const ApiCaller = () => {
  const apiCall = async (httpType, url, data, options) => {
    let headers
    if (localStorage.getItem('token')) {
      // headers = {
      //   Authorization: localStorage.getItem('token'),
      // }
      data.accessToken = localStorage.getItem('token')
    }

    // Set a default timeout (e.g., 5 seconds) or use a custom one from options
    const timeout = (options && options.timeout) || 5000

    try {
      let response
      switch (httpType.trim().toLowerCase()) {
        case 'get':
          response = await axios.get(url, { ...options, headers, timeout })
          break
        case 'post':
          response = await axios.post(url, data, {
            ...options,
            headers,
            timeout,
          })
          break
        case 'put':
          response = await axios.put(url, data, {
            ...options,
            headers,
            timeout,
          })
          break
        case 'delete':
          response = await axios.delete(url, {
            ...options,
            headers,
            data,
            timeout,
          })
          break
        default:
          throw new Error('Invalid HTTP method provided')
      }
      if (response?.data?.code === 401) {
        handle401Error()
      }
      return response
    } catch (error) {
      if (error?.response?.status === 401) {
        handle401Error()
      }
      return error.response
    }
  }

  return { apiCall }
}

export default ApiCaller
