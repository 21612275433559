import { assignmentColumnsDataOrders } from './variables/ColumnDataOrders'
import AssignmentTableList from './components/List'
import Card from 'components/card'
import { useState, useEffect } from 'react'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
const apiService = ApiCaller()

/**
 * Renders the Zone List component with the fetched zone data.
 *
 * @return {JSX.Element} The rendered Zone List component
 */
const AssignmentList = () => {
  const [allAssignmentData, setAssignmentData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [isAssignmentLoading, setAssignmentLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setAssignmentLoading(true)
        const apiUrl = apiConfig.ASSIGNMENT_LIST
        const payload = {
          sorting: ['name', 1],
          pagenumber: currentPage,
          pagelimit: itemsPerPage,
          timelineStatus: [
            'Unassigned',
            'Survey Pending',
            'ILA Pending',
            'LOR Pending',
            'Dox Pending',
            'Assessment Pending',
            'Consent Pending',
            'FSR Pending',
            'TBB',
            'ReOpened',
          ],
          ownerStatus: 'Global',
          name: searchValue,
          owner: [],
          city: [],
          insurer: [],
          insured: [],
          broker: [],
          from: '',
          to: '',
          branch: [],
          department: [],
          fromDate: '',
          toDate: '',
          reserveAmountFrom: '',
          reserveAmountTo: '',
        }
        const response = await apiService.apiCall('post', apiUrl, payload)
        if (response?.data?.value) {
          setAssignmentData(response?.data?.data?.results)
          setTotalCount(response?.data?.data?.total)
        } else {
          setAssignmentData([])
          setTotalCount(0)
        }
      } catch (error) {
        console.error('Error during API call:', error)
      } finally {
        setAssignmentLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchValue, itemsPerPage]) // Fetch data whenever currentPage changes

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const setGlobalFilter = (value) => {
    try {
      setAssignmentLoading(true)
      setCurrentPage(0)
      setItemsPerPage(itemsPerPage)
      setSearchValue(value)
    } catch (err) {
      console.log('err', err)
    } finally {
      setAssignmentLoading(false)
    }
  }

  const perPageChange = (perPage) => {
    setItemsPerPage(perPage)
  }

  return (
    <Card extra={'w-full h-full bg-white mt-3'}>
      <AssignmentTableList
        tableData={allAssignmentData}
        columnsData={assignmentColumnsDataOrders}
        totalCount={totalCount}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        onPerPageLimit={perPageChange}
        onPageChange={handlePageChange}
        isAssignmentLoading={isAssignmentLoading}
        setAssignmentLoading={setAssignmentLoading}
        setGlobalFilter={setGlobalFilter}
      />
    </Card>
  )
}

export default AssignmentList
