export const assignmentColumnsDataOrders = [
  {
    Header: 'S.No',
    accessor: 'index',
    disableSortBy: true,
  },
  {
    Header: 'Ageing',
    accessor: 'ageing',
    disableSortBy: true,
  },
  {
    Header: 'GR Number',
    accessor: 'name',
    disableSortBy: true,
  },
  {
    Header: 'Owner',
    accessor: 'owner',
    disableSortBy: true,
  },
  {
    Header: 'Insurer',
    accessor: 'insurerName',
    disableSortBy: true,
  },
  {
    Header: 'Insured',
    accessor: 'insuredName',
    disableSortBy: true,
  },
  {
    Header: 'Site City',
    accessor: 'city',
    disableSortBy: true,
  },
  {
    Header: 'Nature Of Survey',
    accessor: 'department',
    disableSortBy: true,
  },
  {
    Header: 'Estimated Loss',
    accessor: 'intimatedLoss',
    disableSortBy: true,
  },
  {
    Header: 'Reserve Amount',
    accessor: 'reserveAmount',
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'timelineStatus',
    disableSortBy: true,
  },
  {
    Header: 'ACTIONS',
    accessor: 'COMPANY ACTIONS',
    disableSortBy: true,
  },
]
