import React, { useState, useEffect, useCallback } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import Card from 'components/card'
import { useSpinner } from 'common/SpinnerLoader'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import { useToaster } from 'common/Toaster'
import SwitchWithText from 'components/switch-with-text'
import InputField from 'components/fields/InputField'
import CrawfordConstants from 'common/config/crawfordConstants'
import Select from 'react-select'
import { dropDownStyleWithErrorAndFocus } from 'common/commonFunction'
import UnsavedChangesHandler from 'components/reloadDialog'
const AddCOL = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const apiService = ApiCaller()
  const { TOAST } = CrawfordConstants
  const [natureOfLossOptions, setNatureOfLossOptions] = useState([])

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: {
      name: '',
      NatureOfLoss: [],
      department: [],
    },
  })
  const [isCOLActive, setCOLStatus] = useState(true)
  const [setFormSubmitted] = useState(false)
  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedDepartment, setSelectedDepartment] = useState([])
  const [selectedNOL, setSelectedNOL] = useState([])
  const [departmentOptions, setDepartmentOptions] = useState([])

  const hasUnsavedChanges = Object.keys(dirtyFields).length > 0

  const changeCOLStatus = () => {
    setCOLStatus(!isCOLActive)
  }

  const fetchNatureOfLossOptions = async (inputValue) => {
    setIsLoading(true)
    try {
      const payload = {
        nol: selectedNOL.map((nol) => nol.fullObject),
      }
      const apiUrl = apiConfig.NOL_LIST
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
          fullObject: item,
        }))
        setNatureOfLossOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleNatureOfLossInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchNatureOfLossOptions(inputValue)
    }
  }

  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.COL_CREATE
      const payload = {
        _id: id ? id : undefined,
        name: formData.name,
        status: isCOLActive,
        department: selectedDepartment.map(
          (department) => department.fullObject
        ),
        natureOfLoss: selectedNOL.map((nol) => nol.fullObject),
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        addToast({
          title: `Cause of Loss "${formData.name}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        setFormSubmitted(true)
        navigate(`/insurance/causeloss-list`)
      } else {
        addToast({ title: 'Cause of Loss creation failed.', type: 'error' })
      }
    } catch (error) {
      addToast({ title: TOAST.MESSAGES.ERROR.swwError, type: 'error' })
    } finally {
      hideSpinner()
    }
  }

  const getCOLDetails = useCallback(async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.COL_DETAIL
      const response = await apiService.apiCall('post', apiUrl, { _id: id })
      if (response?.data?.value) {
        setValue('name', response?.data?.data?.name || '')
        setCOLStatus(response?.data?.data?.status)
        const departmentObject = response?.data?.data?.department.map(
          (department) => ({
            label: department.name,
            value: department._id,
            fullObject: department,
          })
        )
        setSelectedDepartment(departmentObject)
        const nolObject = response?.data?.data?.natureOfLoss.map((nol) => ({
          label: nol.name,
          value: nol._id,
          fullObject: nol,
        }))
        setSelectedNOL(nolObject)
      } else {
        addToast({ title: 'Some Error Occurred', type: 'error' })
      }
    } catch (error) {
      console.error('Error:', error)
    } finally {
      hideSpinner()
    }
  }, [id])
  const fetchDepartmentOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.GET_DEPARTMENT
      const payload = {
        department: selectedDepartment.map(
          (department) => department.fullObject
        ),
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((department) => ({
          label: department.name,
          value: department._id,
          fullObject: department,
        }))
        setDepartmentOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleDepartmentInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchDepartmentOptions(inputValue)
    }
  }

  useEffect(() => {
    if (id) getCOLDetails()
  }, [id, getCOLDetails])

  return (
    <>
      <UnsavedChangesHandler isFormDirty={hasUnsavedChanges} />
      <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
        <div className="flex justify-between mt-[25px]">
          <div>
            <p className="flex text-xl font-bold text-navy-700 dark:text-white">
              {id ? 'Edit' : 'Add'} Cause of Loss
            </p>
          </div>
          <div className="flex justify-end">
            <SwitchWithText
              textLeft="OFF"
              textRight="ON"
              checked={isCOLActive}
              onChange={changeCOLStatus}
            />
          </div>
        </div>

        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="mt-7 grid grid-cols-2 gap-3">
            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="w-full"
                label="Cause of Loss"
                id="name"
                type="text"
                placeholder="Enter cause loss name"
                registrationProps={register('name', {
                  required: 'Cause Loss  is required',
                })}
                isFieldRequired={true}
                state={errors.name && 'error'}
              />
            </div>

            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold text-navy-700">
                Department
                <span className="text-red-500"> * </span>
              </label>
              <Controller
                name="department"
                control={control}
                rules={{ required: !id && 'Department is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    id="department"
                    options={departmentOptions}
                    placeholder="Search and select..."
                    classNamePrefix="select"
                    isClearable
                    isMulti
                    isFieldRequired={true}
                    isLoading={isLoading}
                    value={selectedDepartment}
                    onFocus={() => fetchDepartmentOptions()}
                    onInputChange={handleDepartmentInputChange}
                    noOptionsMessage={() => 'Type to search...'}
                    onChange={(department) => {
                      field.onChange(department)
                      setSelectedDepartment(department || [])
                    }}
                    styles={dropDownStyleWithErrorAndFocus(error)}
                  />
                )}
              />
              {errors.department && (
                <div className="error-message text-right text-red-500">
                  {errors.department.message}
                </div>
              )}
            </div>

            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 mt-1 block text-sm font-bold">
                Nature Of Loss <span className="text-red-500">*</span>
              </label>
              <Controller
                name="NatureOfLoss"
                control={control}
                rules={{ required: !id && 'Nature of Loss is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    options={natureOfLossOptions}
                    placeholder="Search and select..."
                    disabled={true}
                    classNamePrefix="select"
                    isClearable
                    isFieldRequired
                    isMulti
                    isLoading={isLoading}
                    value={selectedNOL}
                    onInputChange={handleNatureOfLossInputChange}
                    onFocus={() => fetchNatureOfLossOptions()}
                    noOptionsMessage={() => 'Type to search...'}
                    onChange={(NatureOfLoss) => {
                      field.onChange(NatureOfLoss)
                      setSelectedNOL(NatureOfLoss || [])
                    }}
                    styles={dropDownStyleWithErrorAndFocus(error)}
                  />
                )}
              />
              {errors.NatureOfLoss && (
                <div className="error-message text-right text-red-500">
                  {errors.NatureOfLoss.message}
                </div>
              )}
            </div>
          </div>

          <div className="mt-4 flex w-full justify-end">
            <button
              className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
              type="submit"
            >
              <span>{id ? 'Update' : 'Save'}</span>
            </button>

            <button
              className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base focus:outline-none focus:ring-2 focus:ring-gray-700 active:ring-2 active:ring-gray-500`}
              type="button"
              onClick={() => navigate(`/insurance/causeloss-list`)}
            >
              <span>Cancel</span>
            </button>
          </div>
        </form>
      </Card>
    </>
  )
}

export default AddCOL
