import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Card from 'components/card'
import { useForm, Controller } from 'react-hook-form'
import { FaCircleMinus, FaCirclePlus } from 'react-icons/fa6'
import InputField from 'components/fields/InputField'
import Select from 'react-select'
import { useSpinner } from 'common/SpinnerLoader'
import { useToaster } from 'common/Toaster'
import CrawfordConstants from 'common/config/crawfordConstants'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import { dropDownStyleWithErrorAndFocus } from 'common/commonFunction'

const apiService = ApiCaller()
const { TOAST } = CrawfordConstants

const AddInvoiceTemplate = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { showSpinner, hideSpinner } = useSpinner()
  const { addToast } = useToaster()
  const {
    control,
    register,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
  } = useForm({
    defaultValues: {},
  })

  const initialForms = [
    {
      invoiceExpenditure: '',
      order: '',
    },
  ]

  // Function to add a new row
  const addColumn = () => {
    setInvoiceData([
      ...invoiceData,
      {
        invoiceExpenditure: '',
        order: '',
      },
    ])
  }

  // Function to remove a row by index
  const removeColumn = (index) => {
    if (invoiceData.length > 1) {
      setInvoiceData(invoiceData.filter((_, idx) => idx !== index))
    }
  }

  const [isLoading, setIsLoading] = useState(false)
  const [invoiceData, setInvoiceData] = useState(initialForms)
  const [invoiceExpenditure, setInvoiceExpenditure] = useState([])

  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      const payload = {
        _id: id ? id : undefined,
        invoiceExpenditure: invoiceData.map((item, idx) => {
          return {
            invoiceExpenditure: formData[`invoiceExpenditure-${idx}`].value, // Use dynamic field names
            order: formData[`order-${idx}`], // Use dynamic field names
            _id: item._id,
          }
        }),
        name: getValues('invoiceName'),
        status: true,
      }
      const apiUrl = apiConfig.INVOICE_TEMPLATE_SAVE
      const response = await apiService.apiCall('post', apiUrl, payload)
      // Check if the response is successfull
      if (response?.data?.data) {
        addToast({
          title: `Invoice Template "${formData.invoiceName}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        navigate(`/templates/invoice-templates-list`) // Navigate to the FSR list page after successful save
      } else {
        // If the response indicates failure
        addToast({
          title: `Invoice Template ${
            id ? 'edit' : 'save'
          } failed. Please try again.`,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  const getInvoiceTemplateDetails = async () => {
    try {
      showSpinner()
      const payload = {
        _id: id,
      }
      const apiUrl = apiConfig.INVOICE_TEMPLATE_DETAIL
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data === 'Access Denied') {
        addToast({
          title: 'Access Denied',
          type: 'error',
        })
        navigate(`/templates/invoice-templates-list`)
      } else if (response?.data?.value) {
        const invoiceTemplateDetail = response?.data?.data
        setValue('invoiceName', invoiceTemplateDetail?.name)
        const formattedInvoiceData =
          invoiceTemplateDetail?.invoiceExpenditure.map((item, idx) => ({
            invoiceExpenditure:
              setValue(`invoiceExpenditure-${idx}`, {
                value: item?.invoiceExpenditure._id,
                label: item?.invoiceExpenditure.name,
              }) || null, // Adjust based on API structure
            order: setValue(`order-${idx}`, item?.order) || '',
            _id: item?._id,
          }))

        setInvoiceData(formattedInvoiceData)
      } else {
        addToast({
          title: 'Some Error Occured',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error:', error)
    } finally {
      hideSpinner()
    }
  }

  useEffect(() => {
    if (id) getInvoiceTemplateDetails()
  }, [])

  const handleCancel = () => {
    navigate(`/templates/invoice-templates-list`)
  }

  const fetchInvoiceExpenditure = async () => {
    try {
      setIsLoading(true)
      const payload = {
        keyword: '',
        filter: {},
        fields: [],
        page: 1,
      }
      const apiUrl = apiConfig.INVOICE_EXPENDITURE_LIST
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.data?.results) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setInvoiceExpenditure(newOptions)
      }
    } catch (error) {
      console.error('Error loading Invoice Template', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleInvoiceExpenditureInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchInvoiceExpenditure(inputValue)
    }
  }

  const handleInvoiceExpenditureSelectionChange = (invoice) => {
    if (!invoice) {
      fetchInvoiceExpenditure() // Call the function to perform API request
    }
  }

  const handleDropdownChange = (idx, invoice) => {
    const updatedData = [...invoiceData]
    updatedData[idx].invoiceExpenditure = invoice ? invoice.value : null
    setInvoiceData(updatedData)
  }

  return (
    <Card extra={'w-full h-full pb-6 pt-4 px-6'}>
      <div className="mt-4 mb-5">
        <p className="text-xl font-bold text-gray-800">
          {id ? 'Edit' : 'Add'} Invoice Template
        </p>
      </div>
      {/* form */}
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="grid grid-cols-1 gap-3 mb-8">
          <div className="mt-2 grid grid-cols-1 gap-3">
            <div className="col-span-2 lg:col-span-1">
              <>
                <InputField
                  extra="mt-2 w-full"
                  label="Invoice Name"
                  id="invoiceName"
                  type="text"
                  placeholder="Enter Invoice Name"
                  registrationProps={register('invoiceName', {
                    required: 'Invoice name is required',
                  })}
                  isFieldRequired={true}
                  state={errors.invoiceName && 'error'}
                />
                <div className="error-message text-right">
                  {errors.invoiceName?.message}
                </div>
              </>
            </div>
          </div>
        </div>

        {/* Static Table */}
        <div className="space-y-8">
          <div className="rounded-lg shadow-md border border-gray-200 bg-white p-2">
            <table className="w-full table-auto border-collapse">
              <tbody>
                {invoiceData.map((invoice, idx) => (
                  <tr
                    key={idx}
                    className={`relative ${idx % 2 === 0 ? 'bg-gray-50' : 'bg-white'} group rounded-lg`}
                  >
                    {/* Skeleton for Dropdown */}
                    <td
                      className="px-2 py-2 text-sm text-gray-800 border-r border-gray-300"
                      style={{ width: '50%' }}
                    >
                      <div className="w-full">
                        <Controller
                          name={`invoiceExpenditure-${idx}`}
                          control={control}
                          defaultValue={invoice.invoiceExpenditure}
                          render={({ field, fieldState: { error } }) => (
                            <Select
                              {...field}
                              options={invoiceExpenditure}
                              placeholder="Search and select..."
                              classNamePrefix="select"
                              isClearable
                              isLoading={isLoading}
                              noOptionsMessage={() => 'Type to search...'}
                              onInputChange={
                                handleInvoiceExpenditureInputChange
                              }
                              onFocus={() => fetchInvoiceExpenditure()}
                              onChange={(invoiceExpenditure) => {
                                field.onChange(invoiceExpenditure)
                                handleDropdownChange(idx, invoiceExpenditure)
                                handleInvoiceExpenditureSelectionChange(
                                  invoiceExpenditure
                                )
                              }}
                              styles={dropDownStyleWithErrorAndFocus(error)}
                            />
                          )}
                        />
                      </div>
                    </td>
                    {/* Skeleton for Text Input */}
                    <td
                      className="px-2 py-2 text-sm text-gray-800"
                      style={{ width: '50%' }}
                    >
                      <div className="w-full -mt-2">
                        <InputField
                          extra="mt-2 w-full"
                          id={`order-${idx}`}
                          type="text"
                          // value={invoice.order || ""}
                          placeholder="Enter Sequence Number"
                          registrationProps={register(`order-${idx}`)}
                          state={errors.invoiceName && 'error'}
                        />
                      </div>
                    </td>
                    {/* Add/Minus Icons on Row Hover (Horizontal) */}
                    <td className="relative">
                      <div className="absolute top-1/2 right-4 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 flex space-x-2 py-2 px-2">
                        <FaCirclePlus
                          size={22}
                          color="green"
                          onClick={() => addColumn(idx)}
                        />
                        <FaCircleMinus
                          size={22}
                          color="red"
                          onClick={() => removeColumn(idx)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Button */}
        <div className="mt-4 flex w-full justify-end">
          <button
            className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
            type="submit"
          >
            <span>{id ? 'Update' : 'Save'}</span>
          </button>

          <button
            className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base focus:outline-none focus:ring-2 focus:ring-gray-700 active:ring-2 active:ring-gray-500`}
            type="button"
            onClick={handleCancel}
          >
            <span>Cancel</span>
          </button>
        </div>
      </form>
    </Card>
  )
}
export default AddInvoiceTemplate
