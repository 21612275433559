import React from 'react'
import { FaBuilding, FaFileInvoice } from 'react-icons/fa'
import { IoEarthSharp } from 'react-icons/io5'
import AddFSRTemplate from 'views/Templates/FsrTemplate/components/AddFSRTemplate'
import AddInvoiceTemplate from 'views/Templates/InvoiceTemplate/components/AddInvoiceTemplate'
import ViewFSRTemplate from 'views/Templates/FsrTemplate/components/ViewFSRTemplate'
import FSRTemplatesList from 'views/Templates/FsrTemplate'
import InvoiceTemplatesList from 'views/Templates/InvoiceTemplate'

const templatesRoutes = [
  // Templates List Page
  {
    name: 'FSR Templates',
    layout: '/afterLogin',
    path: '/templates/fsr-templates-list',
    icon: <FaBuilding className="text-inherit h-6 w-6" />,
    component: <FSRTemplatesList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Templates',
    breadcrum: [
      { label: 'Templates', link: '', breadCrumDisabled: true },
      { label: 'List', link: '', breadCrumDisabled: true },
    ],
  },

  // Templates Create Page
  {
    name: 'FSR Templates',
    layout: '/afterLogin',
    path: '/templates/fsr-templates-create',
    icon: <IoEarthSharp className="text-inherit h-6 w-6" />,
    component: <AddFSRTemplate />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Templates',
    breadcrum: [
      { label: 'Templates', link: '', breadCrumDisabled: true },
      { label: 'FSR Templates', link: '/templates/fsr-templates-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },

  // Templates Edit Page
  {
    name: 'FSR Templates',
    layout: '/afterLogin',
    path: '/templates/fsr-templates-edit/:id',
    icon: <IoEarthSharp className="text-inherit h-6 w-6" />,
    component: <AddFSRTemplate />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Templates',
    breadcrum: [
      { label: 'Templates', link: '', breadCrumDisabled: true },
      { label: 'FSR Templates', link: '/templates/fsr-templates-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  // Templates View Page
  {
    name: 'FSR Templates',
    layout: '/afterLogin',
    path: '/templates/fsr-templates-view/:assignmentId/:id',
    icon: <IoEarthSharp className="text-inherit h-6 w-6" />,
    component: <ViewFSRTemplate />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Templates',
    // breadcrum: [
    //   { label: 'Templates', link: '', breadCrumDisabled: true },
    //   { label: 'FSR Templates', link: '' },
    //   { label: 'Edit', link: '', breadCrumDisabled: true },
    // ],
  },

  // Templates Create Page
  {
    name: 'Invoice Templates',
    layout: '/afterLogin',
    path: '/templates/invoice-templates-create',
    icon: <IoEarthSharp className="text-inherit h-6 w-6" />,
    component: <AddInvoiceTemplate />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Templates',
    breadcrum: [
      { label: 'Templates', link: '', breadCrumDisabled: true },
      { label: 'Invoice Templates', link: '/templates/invoice-templates-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },

  // Templates Edit Page
  {
    name: 'Invoice Templates',
    layout: '/afterLogin',
    path: '/templates/invoice-templates-edit/:id',
    icon: <IoEarthSharp className="text-inherit h-6 w-6" />,
    component: <AddInvoiceTemplate />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Templates',
    breadcrum: [
      { label: 'Templates', link: '', breadCrumDisabled: true },
      { label: 'Invoice Templates', link: '/templates/invoice-templates-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  // Templates List Page
  {
    name: 'Invoice Templates',
    layout: '/afterLogin',
    path: '/templates/invoice-templates-list',
    icon: <FaFileInvoice className="text-inherit h-6 w-6" />,
    component: <InvoiceTemplatesList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Templates',
    breadcrum: [
      { label: 'Templates', link: '', breadCrumDisabled: true },
      { label: 'List', link: '', breadCrumDisabled: true },
    ],
  },
]

export default templatesRoutes
