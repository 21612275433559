import React from 'react'

import { FaBuilding } from 'react-icons/fa'
import { IoEarthSharp } from 'react-icons/io5'
import AssignmentList from './Assignment'
import TOCList from './TypeOfClaims'
import AddTOC from './TypeOfClaims/components/AddTOC'
import AddAssignment from './Assignment/components/AddAssignment'
import AssignmentTimeline from './Assignment/components/AssignmentTimeline'
import { MdAssignment, MdNoteAdd } from 'react-icons/md'
import CreateInvoice from './Assignment/components/CreateInvoice'
const assignmentsRoutes = [
  // {
  //   name: 'Type of Claims',
  //   layout: '/afterLogin',
  //   path: '/assignments/claims-list',
  //   icon: <FaBuilding className="text-inherit h-6 w-6" />,
  //   component: <TOCList />,
  //   isComing: false,
  //   isVisible: true,
  //   parentTitle: 'Assignments',
  //   breadcrum: [
  //     { label: 'Assignments', link: '', breadCrumDisabled: true },
  //     { label: 'Type Of Claim', link: '', breadCrumDisabled: true },
  //   ],
  // },
  // {
  //   name: 'Type of Claims',
  //   layout: '/afterLogin',
  //   path: '/assignments/claims-create',
  //   icon: <IoEarthSharp className="text-inherit h-6 w-6" />,
  //   component: <AddTOC />,
  //   isComing: false,
  //   isVisible: false,
  //   parentTitle: 'Assignments',
  //   breadcrum: [
  //     { label: 'Assignments', link: '', breadCrumDisabled: true },
  //     { label: 'Type of Claims', link: '/assignments/claims-list' },
  //     { label: 'Create', link: '', breadCrumDisabled: true },
  //   ],
  // },
  // {
  //   name: 'Type of Claims',
  //   layout: '/afterLogin',
  //   path: '/assignments/claims-edit/:id',
  //   icon: <IoEarthSharp className="text-inherit h-6 w-6" />,
  //   component: <AddTOC />,
  //   isComing: false,
  //   isVisible: false,
  //   parentTitle: 'Assignments',
  //   breadcrum: [
  //     { label: 'Assignments', link: '', breadCrumDisabled: true },
  //     { label: 'Type of Claims', link: '/assignments/claims-list' },
  //     { label: 'Edit', link: '', breadCrumDisabled: true },
  //   ],
  // },
  {
    name: 'Assignment',
    layout: '/afterLogin',
    path: '/assignments/assignment-list',
    icon: <MdAssignment className="text-inherit h-6 w-6" />,
    component: <AssignmentList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Assignments',
    breadcrum: [
      { label: 'Assignments', link: '', breadCrumDisabled: true },
      { label: 'Assignment', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Assignment',
    layout: '/afterLogin',
    path: '/assignments/assignment-create',
    icon: <MdAssignment className="text-inherit h-6 w-6" />,
    component: <AddAssignment />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Assignments',
    breadcrum: [
      { label: 'Assignments', link: '', breadCrumDisabled: true },
      { label: 'Assignment', link: '/assignments/assignment-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Assignment',
    layout: '/afterLogin',
    path: '/assignments/assignment-edit/:id',
    icon: <MdAssignment className="text-inherit h-6 w-6" />,
    component: <AddAssignment />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Assignments',
    breadcrum: [
      { label: 'Assignments', link: '', breadCrumDisabled: true },
      { label: 'Assignment', link: '/assignments/assignment-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Timeline',
    path: '/assignments/timeline/:id',
    layout: '/afterLogin',
    icon: <MdNoteAdd className="text-inherit h-6 w-6" />,
    component: <AssignmentTimeline />,
    isComing: false,
    isVisible: false,
    breadcrum: [
      { label: 'Assignments', link: '/assignments' },
      { label: 'Timeline', link: '' },
    ],
  },
  {
    name: 'Create Assignment',
    path: '/assignments/assignment-create-from-email/:emailId',
    layout: '/afterLogin',
    icon: <MdNoteAdd className="text-inherit h-6 w-6" />,
    component: <AddAssignment />,
    isComing: false,
    isVisible: false,
    breadcrum: [
      { label: 'Email / Inbox', link: '/email/inbox' },
      {
        label: 'Create Assignment',
        link: '/assignments/assignment-create-from-email/:emailId',
      },
    ],
  },
  {
    name: 'Create Invoice',
    path: '/assignments/create-invoice/:assignmentId',
    layout: '/afterLogin',
    component: <CreateInvoice />,
    isComing: false,
    isVisible: false,
  },
]

export default assignmentsRoutes
