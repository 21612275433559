import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Card from 'components/card'
import { useForm, Controller } from 'react-hook-form'
import { useSpinner } from 'common/SpinnerLoader'
import Select from 'react-select'
import InputField from 'components/fields/InputField'
import { useToaster } from 'common/Toaster'
import CrawfordConstants from 'common/config/crawfordConstants'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import {
  readFileBasedOnId,
  dropDownStyleWithErrorAndFocus,
} from 'common/commonFunction'

const apiService = ApiCaller()
const { TOAST } = CrawfordConstants

const ViewFSRTemplate = () => {
  const { assignmentId, id } = useParams()
  const navigate = useNavigate()
  const { showSpinner, hideSpinner } = useSpinner()
  const { addToast } = useToaster()
  const {
    control,
    register,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
  } = useForm({
    defaultValues: {},
  })

  // useState
  const [isLoading, setIsLoading] = useState(false)
  const [isSupervisor, setIsSupervisor] = useState(false)
  const [supervisorOptions, setSupervisorOptions] = useState([])
  const [fsrData, setFsrData] = useState([])
  const [loginEmployee, setLoginEmployee] = useState(null)
  const [pdfPreviewInput, setPDFPreviewInput] = useState({})

  useEffect(() => {
    const key = localStorage.getItem('getLoginEmployee')
    if (key) {
      setLoginEmployee(JSON.parse(key))
    } else {
      console.error('Key not found in localStorage')
    }
  }, [])

  //Supervisor
  const fetchSupervisor = async () => {
    try {
      setIsLoading(true)
      const payload = {
        _id: loginEmployee._id,
        accessToken: process.env.REACT_APP_GOOGLE_KEY,
      }
      const apiUrl = apiConfig.SUPERVISOR_LIST
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.data.results) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setSupervisorOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching Supervisor: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSupervisorInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchSupervisor(inputValue)
    } else {
      fetchSupervisor()
    }
  }

  const handleSupervisorSelectionChange = (supervisor) => {
    if (!supervisor) {
      fetchSupervisor()
      setIsSupervisor(false)
    } else {
      setIsSupervisor(true)
    }
  }

  const getPdfPreview = async () => {
    try {
      showSpinner()
      const payload = {
        ...pdfPreviewInput,
        forms: fsrData,
        accessToken: process.env.REACT_APP_GOOGLE_KEY,
        users: {
          email: loginEmployee.officeEmail,
          name: loginEmployee.name,
        },
      }
      const apiUrl = apiConfig.GET_PDF_PREVIEW
      const response = await apiService.apiCall('post', apiUrl, payload)
      const fsrId = response?.data?.data
      const pdfPreviewUrl = readFileBasedOnId(fsrId)
      // Create a temporary <a> element
      const link = document.createElement('a')

      // Set the href to the file URL and specify the download attribute with the filename
      link.href = pdfPreviewUrl
      link.download = 'fsrTemplate' // Suggest the file name when downloading

      // Programmatically trigger the click event
      link.click()
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  const onFormSubmit = async (formatData) => {
    try {
      showSpinner()
      const payload = {
        ...pdfPreviewInput,
        forms: fsrData,
        timelineData: {
          employee: loginEmployee._id,
          title: '',
          type: 'Normal',
        },
        accessToken: process.env.REACT_APP_GOOGLE_KEY,
      }
      const apiUrl = apiConfig.FSR_SAVE_DRAFT
      const response = await apiService.apiCall('post', apiUrl, payload)
      // Check if the response is successful
      if (response?.data?.data) {
        addToast({
          title: `FSR "${getValues('fsrName')}" saved as Draft successfully.`,
          type: 'success',
        })
        navigate(`/assignments/timeline/${assignmentId}?step=fsr`) // Navigate to the FSR list page after successful save
      } else {
        // If the response indicates failure
        addToast({
          title: 'FSR save failed. Please try again.',
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  const getAssignmentTemplate = async () => {
    try {
      showSpinner()
      const payload = {
        _id: id,
        assignmentId: assignmentId,
        type: 'fsrs',
        accessToken: process.env.REACT_APP_GOOGLE_KEY,
      }
      const apiUrl = apiConfig.ASSIGNMENT_TEMPLATE_DETAIL
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const fsrDetail = response?.data?.data
        setPDFPreviewInput(fsrDetail)
        setValue('fsrName', fsrDetail?.name)
        // setFsrName(fsrDetail?.name)
        setFsrData(fsrDetail?.forms || []) // Assuming 'forms' is an array of data to display
      } else {
        addToast({
          title: 'Error fetching FSR details',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error:', error)
    } finally {
      hideSpinner()
    }
  }
  useEffect(() => {
    if (id && assignmentId) getAssignmentTemplate()
  }, [])

  const handleEditFieldChange = (sectionIndex, rowIndex, value) => {
    // Create a deep copy of the current fsrData state
    const updatedData = [...fsrData]

    // Update the specific value in the nested structure
    updatedData[sectionIndex].items[rowIndex].field = value

    // Update the state with the modified data
    setFsrData(updatedData)
  }

  const handleCancel = () => {
    navigate(`/assignments/timeline/${id}`)
  }

  return (
    <Card extra={'w-full h-full pb-6 pt-4 px-6'}>
      <div className="mt-4 mb-5">
        <p className="text-xl font-bold text-gray-800">
          {getValues('fsrName') || 'FSR Name'}
        </p>
      </div>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="grid grid-cols-1 gap-3 mb-8">
          <div className="mt-2 grid grid-cols-1 gap-3">
            <div className="col-span-2 lg:col-span-1">
              <>
                <InputField
                  extra="mt-2 w-full"
                  label="FSR Name"
                  id="fsrName"
                  type="text"
                  placeholder="Enter FSR Name"
                  registrationProps={register('fsrName')}
                  disabled={true}
                  state={errors.fsrName && 'error'}
                />
                <div className="error-message text-right">
                  {errors.fsrName?.message}
                </div>
              </>
            </div>
          </div>
        </div>
        {fsrData.length > 0 && (
          <div className="space-y-8">
            {fsrData.map((form, sectionIndex) => (
              <div
                key={form.head}
                className="rounded-lg shadow-md border border-gray-200 bg-white p-4"
              >
                {/* Section Header */}
                <div className="relative group">
                  <p className="mb-4 text-lg font-semibold text-gray-700">
                    {form.displayName || 'Section Name'}
                  </p>
                </div>
                {/* Table */}
                <table className="w-full table-auto border-collapse">
                  <tbody>
                    {form.items.map((item, idx) => (
                      <tr
                        key={idx}
                        className={`${idx % 2 === 0 ? 'bg-gray-50' : 'bg-white'} group`}
                      >
                        {/* Static First Column */}
                        <td
                          className="px-4 py-2 text-sm text-gray-800 border-r border-gray-300"
                          style={{ width: '50%' }}
                        >
                          {item.name || 'New Section'}
                        </td>
                        {/* Editable Second Column */}
                        <td
                          className="px-4 py-2 text-sm text-gray-800"
                          style={{ width: '50%' }}
                        >
                          {item.value.toLowerCase() === 'textarea' ? (
                            <textarea
                              className="w-full border border-gray-300 rounded-md px-2 py-1 text-sm focus:outline-none focus:ring focus:ring-brand-300 placeholder:text-gray-650"
                              defaultValue={item.field || ''}
                              placeholder={`Enter ${item.name}`}
                              onChange={(e) =>
                                handleEditFieldChange(
                                  sectionIndex,
                                  idx,
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <input
                              type={item.value.toLowerCase() || 'text'}
                              defaultValue={item.field || ''}
                              placeholder={`Enter ${item.name}`}
                              className="w-full border border-gray-300 rounded-md px-2 py-1 text-sm focus:outline-none focus:ring focus:ring-brand-300 placeholder:text-gray-600"
                              onChange={(e) =>
                                handleEditFieldChange(
                                  sectionIndex,
                                  idx,
                                  e.target.value
                                )
                              }
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        )}

        {/* Supervisor */}
        <div className="grid grid-cols-1 gap-3 mb-8 mt-4">
          <div className="mt-2 grid grid-cols-1 gap-3">
            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold">
                Supervisor <span className="text-red-500">*</span>
              </label>
              <Controller
                name="supervisor"
                control={control}
                // rules={{ required: 'Supervisor is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    options={supervisorOptions}
                    placeholder="Search and select..."
                    classNamePrefix="select"
                    isClearable
                    isLoading={isLoading}
                    onInputChange={handleSupervisorInputChange}
                    onFocus={() => fetchSupervisor()}
                    noOptionsMessage={() => 'Type to search...'}
                    onChange={(supervisor) => {
                      field.onChange(supervisor)
                      handleSupervisorSelectionChange(supervisor) // Call a function to handle API call
                    }}
                    styles={dropDownStyleWithErrorAndFocus(error)}
                  />
                )}
              />
              {errors.supervisor && (
                <div className="error-message text-right text-red-500">
                  {errors.supervisor.message}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Buttons */}
        <div className="mt-4 flex w-full justify-end">
          <button
            className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
        hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
            type="button"
            onClick={getPdfPreview}
          >
            <span>Pdf Preview</span>
          </button>
          <button
            className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
        hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
            type="submit"
          >
            <span>Save as Draft</span>
          </button>
          <button
            className={`ml-2 mt-2 w-30 cursor-pointer items-center justify-center rounded-2xl bg-brand-300 p-3 text-sm text-white transition duration-200
        hover:cursor-pointer hover:bg-brand-600 active:bg-brand-500 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-500 active:ring-2 active:ring-brand-100`}
            type="button"
            disabled={!isSupervisor}
          >
            <span>Send for Authorization</span>
          </button>
          <button
            className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
        hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base focus:outline-none focus:ring-2 focus:ring-gray-700 active:ring-2 active:ring-gray-500`}
            type="button"
            onClick={handleCancel}
          >
            <span>Cancel</span>
          </button>
        </div>
      </form>
    </Card>
  )
}
export default ViewFSRTemplate
