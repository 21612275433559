import React, { useState } from 'react'

const Popup = ({ onClose, type, onSubmit }) => {
  console.log('Popup', onClose, type, onSubmit)
  const [inputValue, setInputValue] = useState('')

  // Handler to prevent closing popup when clicking inside
  const handlePopupClick = (e) => {
    e.stopPropagation()
  }

  // Handler for input change
  const handleInputChange = (e) => {
    e.preventDefault()
    setInputValue(e.target.value)
  }

  // Handler for submission
  const handleSubmission = () => {
    if (onSubmit) {
      onSubmit(inputValue)
    }
    onClose()
  }

  return (
    <div className="fixed inset-0 z-[99] flex items-center justify-center bg-black bg-opacity-50">
      <div
        className="bg-white rounded-lg shadow-xl p-6 w-96 relative z-60"
        onClick={handlePopupClick}
      >
        <button
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-800 text-2xl"
          onClick={onClose}
        >
          &times;
        </button>

        <h2 className="text-xl font-semibold mb-4 text-center">
          Enter Information {type.toLowerCase()}
        </h2>

        <div className="space-y-4">
          {type === 'Textarea' || type === 'textarea' ? (
            <textarea
              value={inputValue}
              onChange={handleInputChange}
              placeholder={`Enter ${type} information`}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 min-h-[100px]"
            />
          ) : (
            <input
              type={type.toLowerCase()}
              value={inputValue}
              onChange={handleInputChange}
              placeholder={`Enter ${type} information`}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          )}
          <button
            onClick={handleSubmission}
            className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-colors"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )
}

export default Popup
