export const currencyColumnsDataOrders = [
  {
    Header: 'S.No',
    accessor: 'index',
    disableSortBy: true,
  },
  {
    Header: 'Currency Name',
    accessor: 'name',
    disableSortBy: true,
  },
  {
    Header: 'Currency Short Form',
    accessor: 'shortForm',
    disableSortBy: true,
  },
  {
    Header: 'Currency Conversion in INR',
    accessor: 'conversionRate',
    disableSortBy: true,
  },
  {
    Header: 'ACTIONS',
    accessor: 'CURRENCY ACTIONS',
    disableSortBy: true,
  },
]
