import React, { useState, useEffect } from 'react'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import Card from 'components/card'
import Select from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import { useToaster } from 'common/Toaster'
import CrawfordConstants from 'common/config/crawfordConstants'
import { useSpinner } from 'common/SpinnerLoader'
import { useParams, useNavigate } from 'react-router-dom'
import InputField from 'components/fields/InputField'
import { currency } from '../data'
import SwitchWithText from 'components/switch-with-text'
import { dropDownStyleWithErrorAndFocus } from 'common/commonFunction'
const apiService = ApiCaller()
const { TOAST } = CrawfordConstants

const AddCurrency = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      CURRENCY_NAME: null,
      CURRENCY_SYMBOL: '',
      CURRENCY_SHORTFORM: '',
      CONVERSION_RATE: '',
    },
  })

  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()
  const [isLoading] = useState(false)
  const [isCurrencyActive, setCurrencyStatus] = useState(true)
  const changeCurrencyStatus = () => {
    setCurrencyStatus(!isCurrencyActive)
  }
  const selectedCurrency = watch('CURRENCY_NAME')

  const currencyOptions = currency.map((currency) => ({
    value: currency.Currency,
    label: currency.Currency,
    code: currency.code,
    symbol: currency.symbol,
  }))

  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      const userData = localStorage.getItem('userData')
      const employeeData = userData ? JSON.parse(userData).employee : null
      const apiUrl = apiConfig.CURRENCY_CREATE
      const payload = {
        name: formData.CURRENCY_NAME?.value || formData.CURRENCY_NAME,
        symbol: formData.CURRENCY_SYMBOL,
        shortForm: formData.CURRENCY_SHORTFORM,
        conversionRate: formData.CONVERSION_RATE,
        _id: id ? id : undefined,
        status: isCurrencyActive,
        updatedBy: employeeData,
      }

      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        addToast({
          title: `Currency "${formData.CURRENCY_NAME?.value}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        navigate(`/finance/currency-list`)
      } else {
        addToast({
          title: `Currency creation failed.`,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  const getCurrencyDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.CURRENCY_DETAIL
      const payload = {
        _id: id,
        checkRoles: false,
        accessOf: ['currencyEdit'],
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data === 'Access Denied') {
        addToast({
          title: 'Access Denied',
          type: 'error',
        })
        navigate(`/finance/currency-list`)
      } else if (response?.data?.value) {
        const currencyData = response?.data?.data
        const currencyOption = currencyOptions.find(
          (currency) => currency.value === currencyData?.name
        )
        setValue('CURRENCY_NAME', currencyOption || { value: '', label: '' })
        setValue('CURRENCY_SYMBOL', currencyData?.symbol || '')
        setValue('CURRENCY_SHORTFORM', currencyData?.shortForm || '')
        setValue('CONVERSION_RATE', currencyData?.conversionRate || '')
        setCurrencyStatus(currencyData?.status)
      } else {
        addToast({
          title: 'Some Error Occurred',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error fetching currency details:', error)
    } finally {
      hideSpinner()
    }
  }

  const handleCancel = () => {
    navigate(`/finance/currency-list`)
  }

  useEffect(() => {
    if (id) {
      getCurrencyDetails()
    }
  }, [id])

  useEffect(() => {
    if (selectedCurrency) {
      const currencyData = currencyOptions.find(
        (currency) => currency.label === selectedCurrency?.label
      )
      setValue('CURRENCY_SYMBOL', currencyData?.symbol || '')
      setValue('CURRENCY_SHORTFORM', currencyData?.code || '')
    } else {
      setValue('CURRENCY_SYMBOL', '')
      setValue('CURRENCY_SHORTFORM', '')
      setValue('CONVERSION_RATE', '')
    }
  })

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
      <div className="flex justify-between mt-[25px]">
        <div className="flex">
          <p className="text-xl font-bold text-navy-700 dark:text-white">
            {id ? 'Edit' : 'Add'} Currency
          </p>
        </div>
        <div className="flex">
          <SwitchWithText
            textLeft="OFF"
            textRight="ON"
            checked={isCurrencyActive}
            onChange={changeCurrencyStatus}
          />
        </div>
      </div>

      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="mt-7 grid grid-cols-1 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold text-navy-700">
              Currency Name <span className="text-red-500"> * </span>
            </label>
            <Controller
              name="CURRENCY_NAME"
              control={control}
              rules={{ required: 'Currency Name is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={currencyOptions}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  noOptionsMessage={() => 'Type to search...'}
                  styles={dropDownStyleWithErrorAndFocus(error)}
                />
              )}
            />
            {errors.CURRENCY_NAME && (
              <div className="mt-1 text-sm text-right text-red-500">
                {errors.CURRENCY_NAME.message}
              </div>
            )}
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Currency Symbol"
              id="CURRENCY_SYMBOL"
              disabled
              placeholder="Currency Symbol"
              type="text"
              registrationProps={register('CURRENCY_SYMBOL')}
              state={errors.CURRENCY_SYMBOL && 'error'}
            />
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Currency ShortForm"
              id="CURRENCY_SHORTFORM"
              type="text"
              placeholder="Currency ShortForm"
              disabled
              registrationProps={register('CURRENCY_SHORTFORM')}
              state={errors.CURRENCY_SHORTFORM && 'error'}
            />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Currency Conversion Rate in INR"
              id="CONVERSION_RATE"
              type="text"
              isFieldRequired={true}
              placeholder="Enter Conversion Rate"
              registrationProps={register('CONVERSION_RATE', {
                required: 'Conversion Rate is required',
              })}
              state={errors.CONVERSION_RATE && 'error'}
            />
            {errors.CONVERSION_RATE && (
              <div className="mt-1 text-right text-sm text-red-500">
                {errors.CONVERSION_RATE.message}
              </div>
            )}
          </div>

          <div className="flex w-full justify-end">
            <button
              className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
              type="submit"
            >
              {id ? 'Update' : 'Save'}
            </button>
            <button
              className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base focus:outline-none focus:ring-2 focus:ring-gray-700 active:ring-2 active:ring-gray-500`}
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </Card>
  )
}

export default AddCurrency
