import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Card from 'components/card'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import { useSpinner } from 'common/SpinnerLoader'
import { useToaster } from 'common/Toaster'
import CrawfordConstants from 'common/config/crawfordConstants'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import { dropDownStyleWithErrorAndFocus } from 'common/commonFunction'

const apiService = ApiCaller()
const { TOAST } = CrawfordConstants

const AddFSRTemplate = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { showSpinner, hideSpinner } = useSpinner()
  const { addToast } = useToaster()
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {},
  })

  // useState
  const [isLoading, setIsLoading] = useState(false)
  const [fsrOptions, setfsrOptions] = useState([])
  const [fsrData, setFsrData] = useState([])
  const [fsrName, setFsrName] = useState('')

  useEffect(() => {
    fetchfsrOptions()
  }, [])

  const fetchfsrOptions = async (inputValue) => {
    setIsLoading(true)
    try {
      const payload = {
        keyword: inputValue ? inputValue : '',
        page: 1,
      }
      const apiUrl = apiConfig.FSR_LIST
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.data?.results) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
        }))
        setfsrOptions(newOptions)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleFSRTemplateInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchfsrOptions(inputValue)
    }
  }

  const handleFSRTemplateySelectionChange = (FSRName) => {
    if (!FSRName) {
      setFsrData([])
      fetchfsrOptions() // Call the function to perform API request
    } else {
      const selectedFSRTemplate = FSRName.label
      getFSRDetails(selectedFSRTemplate)
    }
  }

  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      // Process the `fsrData` to exclude the `field` property from `items`
      const processedFsrData = fsrData.map((form) => ({
        ...form,
        items: form.items.map(({ field, ...rest }) => rest), // Exclude `field`
      }))

      const payload = {
        // _id: id,
        name: fsrName,
        status: true,
        forms: processedFsrData,
        accessToken: process.env.REACT_APP_GOOGLE_KEY,
      }

      console.log('payload', payload)

      const apiUrl = apiConfig.FSR_TEMPLATE_SAVE
      const response = await apiService.apiCall('post', apiUrl, payload)
      // Check if the response is successful
      if (response?.data?.data) {
        addToast({
          title: `FSR "${fsrName}" ${id ? 'edited' : 'created'} successfully.`,
          type: 'success',
        })
        navigate(`/templates/fsr-templates-list`) // Navigate to the FSR list page after successful save
      } else {
        // If the response indicates failure
        addToast({
          title: `FSR ${id ? 'edit' : 'save'} failed. Please try again.`,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  const getFSRDetails = async (fsrName) => {
    try {
      showSpinner() // Show loading spinner while fetching
      const apiUrl = apiConfig.FSR_DETAIL
      const payload = {
        name: fsrName,
        accessToken: process.env.REACT_APP_GOOGLE_KEY,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const fsrDetail = response?.data?.data
        // setValue('fsrName', fsrDetail?.name)
        setFsrName(fsrDetail?.name)
        setFsrData(fsrDetail?.forms || []) // Assuming 'forms' is an array of data to display
      } else {
        addToast({
          title: 'Error fetching FSR details',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error:', error)
    } finally {
      hideSpinner() // Hide spinner once done
    }
  }

  const getFSRTemplateDetails = async () => {
    try {
      showSpinner() // Show loading spinner while fetching
      const apiUrl = apiConfig.FSR_TEMPLATE_DETAIL
      const payload = {
        _id: id,
        accessToken: process.env.REACT_APP_GOOGLE_KEY,
        checkRoles: true,
        accessOf: ['TemplateFsrEdit'],
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      console.log('response', response)
      if (response?.data?.data === 'Access Denied') {
        addToast({
          title: 'Access Denied',
          type: 'error',
        })
        navigate(`/templates/fsr-templates-list`)
      } else if (response?.data?.value) {
        const fsrTemplateDetail = response?.data?.data
      }
    } catch (error) {
      console.error('Error:', error)
    } finally {
      hideSpinner() // Hide spinner once done
    }
  }

  useEffect(() => {
    if (id) getFSRTemplateDetails()
  }, [id])

  const handleCancel = () => {
    navigate(`/masters/fsr-list`)
  }

  return (
    <Card extra={'w-full h-full pb-6 pt-4 px-6'}>
      <div className="mt-4 mb-5">
        <p className="text-xl font-bold text-gray-800">
          {id ? 'Edit' : 'Add'} FSR Template
        </p>
      </div>

      {/* Form */}
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="grid grid-cols-1 gap-3 mb-8">
          <div className="mt-2 grid grid-cols-1 gap-3">
            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold">
                FSR Name <span className="text-red-500">*</span>
              </label>
              <Controller
                name="fsrName"
                control={control}
                rules={{ required: 'FSR name is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    options={fsrOptions}
                    placeholder="Search and select..."
                    classNamePrefix="select"
                    isClearable
                    isLoading={isLoading}
                    onInputChange={handleFSRTemplateInputChange}
                    onFocus={() => fetchfsrOptions()}
                    noOptionsMessage={() => 'Type to search...'}
                    onChange={(fsrName) => {
                      field.onChange(fsrName)
                      handleFSRTemplateySelectionChange(fsrName) // Call a function to handle API call
                    }}
                    styles={dropDownStyleWithErrorAndFocus(error)}
                  />
                )}
              />
              {errors.fsrName && (
                <div className="error-message text-right text-red-500">
                  {errors.fsrName.message}
                </div>
              )}
            </div>
          </div>
        </div>
        {fsrData.length > 0 && (
          <div className="space-y-8">
            {fsrData.map((form, sectionIndex) => (
              <div
                key={form.head}
                className="rounded-lg shadow-md border border-gray-200 bg-white p-4"
              >
                {/* Section Header */}
                <div className="relative group">
                  <p className="mb-4 text-lg font-semibold text-gray-700">
                    {form.displayName || 'Section Name'}
                  </p>
                </div>
                {/* Table */}
                <table className="w-full table-auto border-collapse">
                  <tbody>
                    {form.items.map((item, idx) => (
                      <tr
                        key={idx}
                        className={`${idx % 2 === 0 ? 'bg-gray-50' : 'bg-white'} group`}
                      >
                        {/* First Column */}
                        <td
                          className="px-4 py-2 text-sm text-gray-800 border-r border-gray-300"
                          style={{ width: '33%' }}
                        >
                          {item.name || 'New Section'}
                        </td>
                        {/* Second Column */}
                        <td
                          className="px-4 py-2 text-sm text-gray-800 border-r border-gray-300"
                          style={{ width: '33%' }}
                        >
                          {item.type || 'User Input'}
                        </td>
                        {/* Third Column */}
                        <td
                          className="px-4 py-2 text-sm text-gray-800"
                          style={{ width: '34%' }}
                        >
                          {item.value || 'Value'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        )}

        {/* Button */}
        <div className="mt-4 flex w-full justify-end">
          <button
            className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
            type="submit"
          >
            <span>{id ? 'Update' : 'Save'}</span>
          </button>

          <button
            className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base focus:outline-none focus:ring-2 focus:ring-gray-700 active:ring-2 active:ring-gray-500`}
            type="button"
            onClick={handleCancel}
          >
            <span>Cancel</span>
          </button>
        </div>
      </form>
    </Card>
  )
}

export default AddFSRTemplate
