import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Dialog = ({ isVisible, onConfirm, onCancel }) => {
  if (!isVisible) return null

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[99]">
      <div className="bg-white p-6 rounded-md shadow-md text-center w-[400px]">
        <div className="text-lg font-semibold">You have unsaved changes.</div>
        <p className="text-lg font-semibold mb-4">
          Do you want to leave without saving?
        </p>
        <div className="flex justify-center space-x-4">
          <button
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            onClick={onConfirm}
          >
            Leave
          </button>
          <button
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
            onClick={onCancel}
          >
            Stay
          </button>
        </div>
      </div>
    </div>
  )
}

const UnsavedChangesHandler = ({ isFormDirty, onNavigateAway }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [nextAction, setNextAction] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        event.preventDefault()
        event.returnValue = ''
      }
    }

    const handlePopState = (event) => {
      if (isFormDirty) {
        event.preventDefault()
        setNextAction('back')
        setShowDialog(true)
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      window.removeEventListener('popstate', handlePopState)
    }
  }, [isFormDirty])

  const confirmNavigation = () => {
    setShowDialog(false)

    switch (nextAction) {
      case 'back':
        window.history.go(-1)
        break
      default:
        if (nextAction) {
          navigate(nextAction)
        }
    }
  }

  const cancelNavigation = () => {
    setShowDialog(false)
    setNextAction(null)
  }

  const handleBlockedNavigation = (event) => {
    if (isFormDirty) {
      event.preventDefault()

      const destination =
        event.target.getAttribute('href') ||
        (event.target.closest('a')
          ? event.target.closest('a').getAttribute('href')
          : null)

      setNextAction(destination)
      setShowDialog(true)
    } else if (onNavigateAway) {
      onNavigateAway()
    }
  }

  useEffect(() => {
    const links = document.querySelectorAll('a')
    links.forEach((link) =>
      link.addEventListener('click', handleBlockedNavigation)
    )

    return () => {
      links.forEach((link) =>
        link.removeEventListener('click', handleBlockedNavigation)
      )
    }
  }, [isFormDirty])

  return (
    <Dialog
      isVisible={showDialog}
      onConfirm={confirmNavigation}
      onCancel={cancelNavigation}
    />
  )
}

export default UnsavedChangesHandler
