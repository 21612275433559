import React from 'react'

const CurrencyFormatter = ({ value, currency = 'INR', locale = 'en-IN' }) => {
  const formattedValue = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).format(value)

  return <span>{formattedValue}</span>
}

export default CurrencyFormatter
