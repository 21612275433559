import ApiCaller from 'common/services/apiServices'
import apiConfig from 'common/config/apiConfig'
import CryptoJS from 'crypto-js'
const apiService = ApiCaller()

const getLoginEmployee = async (email) => {
  try {
    const apiUrl = apiConfig.GET_LOGIN_EMPLOYEE
    const payload = { email }
    const response = await apiService.apiCall('post', apiUrl, payload)
    return response
  } catch (error) {
    console.log(error)
  }
}

const getUserInformations = async () => {
  try {
    const apiUrl = apiConfig.GET_USER_INFO
    const payload = {}
    const response = await apiService.apiCall('post', apiUrl, payload)
    return response
  } catch (error) {
    console.log(error)
  }
}

/**
 * Stores the user information in the local storage.
 *
 * @return {Promise<void>} A promise that resolves when the user information is successfully stored.
 */
const storeUserInfo = async () => {
  try {
    const response = await getUserInformations()
    if (response.status === 200) {
      localStorage.setItem('userData', JSON.stringify(response?.data?.data))
      if (response?.data?.data?.email) {
        const loginEmployee = await getLoginEmployee(
          response?.data?.data?.email
        )
        localStorage.setItem(
          'getLoginEmployee',
          JSON.stringify(loginEmployee?.data?.data)
        )
      }
    }
  } catch (error) {
    console.log('getUserInfo Error', error)
  }
}

/**
 * Retrieves user profile information from local storage.
 *
 * @returns {Promise<string|null>} - A promise that resolves to the user profile data stored in local storage as a string,
 * or `null` if no data is found.
 */
const getUserInfo = () => {
  return JSON.parse(localStorage.getItem('userData'))
}

/**
 * Retrieves login employee information from local storage.
 *
 * @returns {Promise<string|null>} - A promise that resolves to the employee data stored in local storage as a string,
 * or `null` if no data is found.
 */
const getLoginEmployeeInfo = () => {
  return JSON.parse(localStorage.getItem('getLoginEmployee'))
}

/**
 * Encrypts the given word using AES algorithm with an optional key.
 *
 * @param {string} word - The word to be encrypted.
 * @param {string} [key='secret'] - The key used for encryption.
 * @return {string} The encrypted data in Base64 format.
 */
const encryptId = (word, key = 'secret') => {
  let encJson = CryptoJS.AES.encrypt(JSON.stringify(word), key).toString()
  let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))
  return encData
}

/**
 * Converts an array of objects into an array of options objects.
 *
 * @param {Array<Object>} arrayOfObjects - The array of objects to be converted.
 * @return {Array<Object>} The array of options objects.
 */
const convertToOptions = (arrayOfObjects) => {
  const options = arrayOfObjects.map((obj) => ({
    value: obj.full_name,
    label: obj.full_name,
  }))

  return options
}

/**
 * Retrieves project data by getting the project ID from localStorage.
 *
 * @return {string} The project ID retrieved from localStorage.
 */
const getProjectData = () => {
  const projectId = localStorage.getItem('projectId')
  return projectId
}

/**
 * Sets the repository name in the local storage by converting the provided repository data to a JSON string
 * and removing any double quotes.
 *
 * @param {Object} repoData - The repository data to be stored.
 * @return {void} This function does not return anything.
 */
const setRepoName = (repoData) => {
  localStorage.setItem(
    'repoFullName',
    JSON.stringify(repoData).replace(/"/g, '')
  )
}

/**
 * Retrieves the repository name from local storage.
 *
 * @return {string} The repository name retrieved from local storage.
 */
const getRepoName = () => {
  return localStorage.getItem('repoFullName')
}

const handleLogout = async () => {
  try {
    const apiUrl = apiConfig.LOGOUT_USER
    const payload = {}
    const response = await apiService.apiCall('post', apiUrl, payload)
    if (response.status === 200) {
      localStorage.clear()
      return
    }
  } catch (error) {
    localStorage.clear()
    console.error('Error during API call:', error)
  }
}

const toKebabCase = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1-$2') // Handle camelCase
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/_/g, '-') // Replace underscores with -
    .toLowerCase() // Convert to lower case
}

const getCountry = async (payload) => {
  const apiUrl = apiConfig.COUNTRY_LIST
  const response = await apiService.apiCall('post', apiUrl, payload)
  return response
}

const getZone = async (payload) => {
  const apiUrl = apiConfig.ZONE_LIST
  const response = await apiService.apiCall('post', apiUrl, payload)
  return response
}
const getRole = async (payload) => {
  const apiUrl = apiConfig.ROLE_LIST
  const response = await apiService.apiCall('post', apiUrl, payload)
  return response
}

const getState = async (payload) => {
  const apiUrl = apiConfig.STATE_LIST
  const response = await apiService.apiCall('post', apiUrl, payload)
  return response
}

const getDistrict = async (payload) => {
  const apiUrl = apiConfig.DISTRICT_LIST
  const response = await apiService.apiCall('post', apiUrl, payload)
  return response
}

const getCity = async (payload) => {
  const apiUrl = apiConfig.CITY_LIST
  const response = await apiService.apiCall('post', apiUrl, payload)
  return response
}

const getCurrency = async (payload) => {
  const apiUrl = apiConfig.CURRENCY_LIST
  const response = await apiService.apiCall('post', apiUrl, payload)
  return response
}

const getEmail = async (payload) => {
  const apiUrl = apiConfig.EMAIL_LIST
  const response = await apiService.apiCall('post', apiUrl, payload)
  return response
}

const getEmailPdf = async (payload) => {
  const apiUrl = apiConfig.EMAIL_PDF
  const response = await apiService.apiCall('post', apiUrl, payload)
  return response
}

const getEmailAttachments = async (payload) => {
  const apiUrl = apiConfig.EMAIL_ATTACHMENTS
  const response = await apiService.apiCall('post', apiUrl, payload)
  return response
}

const readFileBasedOnId = (id) => {
  return `${apiConfig.READ_FILE_BASED_ON_ID}${id}`
}

const pdfGoogleViewer = async (readUrl, pdfName) => {
  if (readUrl) {
    const response = await apiService.apiCall(
      'get',
      `${readUrl}api/upload/readFile?file=${pdfName}&iframe=true`,
      {}
    )
    return response
    // if (response?.data?.s3Url) {
    //   return response?.data?.s3Url
    //   // return `https://docs.google.com/viewerng/viewer?url=${response?.data?.s3Url}`
    // }
  } else {
    return 'img/placeholder.png'
  }
}

const setFileUploadActiveClass = () => {
  return 'border-transparent block w-80 cursor-pointer rounded-3xl border text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 active:border-blue-400'
}

const dropDownStyleWithErrorAndFocus = (error) => {
  return {
    control: (base, state) => ({
      ...base,
      minHeight: '44px',
      borderRadius: '0.75rem',
      borderWidth: '1px',
      borderColor: error
        ? 'red'
        : state.isFocused
          ? 'rgb(59, 130, 246)' // Tailwind blue-500 for focus
          : 'rgba(218, 222, 236, 1)', // Default gray color

      // Blue outline (ring) on focus, red if error
      boxShadow: state.isFocused
        ? `0 0 0 1px ${error ? 'red' : 'rgb(59, 130, 246)'}`
        : '',

      '&:hover': {
        borderColor: error ? 'red' : 'rgb(59, 130, 246)', // Hover blue if no error
      },
    }),
    placeholder: (base) => ({
      ...base,
      fontWeight: 'normal',
      fontSize: '14px',
      color: error ? 'red' : '#999', // Red placeholder on error
    }),
    menu: (base) => ({
      ...base,
      width: '100%', // Width of the dropdown menu
      maxHeight: '250px', // Max height for the dropdown menu
      borderRadius: '8px',
      zIndex: 49, // Set a higher zIndex to ensure the menu appears above other elements
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: '250px', // Max height of the list container for scrolling
      padding: 0, // Optional padding for the menu list
      zIndex: 49, // Set zIndex for the menu list as well if needed
    }),
    singleValue: (base) => ({
      ...base,
      color: '#333', // Color for selected text
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: state.isFocused ? 'rgb(59, 130, 246)' : 'rgba(156, 163, 175, 1)', // Blue dropdown indicator on focus
      '&:hover': {
        color: 'rgb(59, 130, 246)',
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none', // Remove separator
    }),
  }
}

const dropDownStyleWithoutErrorAndFocus = () => {
  return {
    control: (base, state) => ({
      ...base,
      minHeight: '44px',
      borderRadius: '0.75rem',
      borderWidth: '1px',
      borderColor: state.isFocused
        ? 'rgb(59, 130, 246)'
        : 'rgba(218, 222, 236, 1)', // Blue border on focus, light gray otherwise
      boxShadow: state.isFocused ? '0 0 0 1px rgba(59, 130, 246, 1)' : '', // Blue outline on focus
      '&:hover': {
        borderColor: 'rgb(59, 130, 246)', // Blue on hover
      },
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: state.isFocused ? 'rgb(59, 130, 246)' : 'rgb(156, 163, 175)', // Blue indicator on focus, gray otherwise
      '&:hover': {
        color: 'rgb(59, 130, 246)', // Blue on hover
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none', // Remove separator for cleaner design
    }),
    placeholder: (base) => ({
      ...base,
      fontWeight: 'normal',
      color: '#999', // Placeholder color
      fontSize: '14px',
    }),
    singleValue: (base) => ({
      ...base,
      color: '#333', // Color of selected text
    }),
  }
}

export {
  encryptId,
  getUserInformations,
  convertToOptions,
  getProjectData,
  storeUserInfo,
  setRepoName,
  getRepoName,
  toKebabCase,
  handleLogout,
  getCountry,
  getZone,
  getRole,
  getState,
  getDistrict,
  getCity,
  getCurrency,
  getEmail,
  getEmailPdf,
  getEmailAttachments,
  readFileBasedOnId,
  pdfGoogleViewer,
  getUserInfo,
  setFileUploadActiveClass,
  dropDownStyleWithErrorAndFocus,
  dropDownStyleWithoutErrorAndFocus,
  getLoginEmployee,
  getLoginEmployeeInfo,
}
