import React, { useState, useEffect } from 'react'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import Card from 'components/card'
import Select from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import { useToaster } from 'common/Toaster'
import CrawfordConstants from 'common/config/crawfordConstants'
import { useSpinner } from 'common/SpinnerLoader'
import { useParams, useNavigate } from 'react-router-dom'
import InputField from 'components/fields/InputField'
import { countries } from '../data'
import { dropDownStyleWithErrorAndFocus } from 'common/commonFunction'
const apiService = ApiCaller()
const { TOAST } = CrawfordConstants

const AddCountry = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      COUNTRY_NAME: null,
      COUNTRY_CODE: '',
      ISD_CODE: '',
    },
  })

  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()
  const [isLoading] = useState(false)
  const selectedCountry = watch('COUNTRY_NAME')
  const countryOptions = countries.map((country) => ({
    value: country.name,
    label: country.name,
    code: country.code,
    dial_code: country.dial_code,
  }))
  /**
   * Handles the form submission for creating a new country.
   *
   * @async
   * @function onFormSubmit
   * @param {Object} formData - The form data containing the country details.
   * @param {string} formData.COUNTRY_NAME - The name of the country.
   * @param {string} formData.COUNTRY_CODE - The code of the country.
   * @param {string} formData.ISD_CODE - The ISD code of the country.
   * @returns {Promise<void>} A promise that resolves when the form submission is complete.
   */
  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.COUNTRY_CREATE
      const payload = {
        name: formData.COUNTRY_NAME?.value || formData.COUNTRY_NAME,
        countryCode: formData.COUNTRY_CODE,
        ISDCodes: formData.ISD_CODE,
        _id: id ? id : undefined,
      }

      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        addToast({
          title: `Country "${formData.COUNTRY_NAME?.value}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        navigate(`/locations/country-list`)
      } else {
        addToast({
          title: `Country creation failed.`,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  const getCountryDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.COUNTRY_DETAIL.replace(':id', id)
      const payload = {
        _id: id,
        checkRoles: false,
        accessOf: ['countryEdit'],
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data === 'Access Denied') {
        addToast({
          title: 'Access Denied',
          type: 'error',
        })
        navigate(`/locations/country-list`)
      } else if (response?.data?.value) {
        const countryData = response?.data?.data
        const countryOption = countryOptions.find(
          (country) => country.value === countryData?.name
        )
        setValue('COUNTRY_NAME', countryOption || { value: '', label: '' })
        setValue('COUNTRY_CODE', countryData?.code || '')
        setValue('ISD_CODE', countryData?.ISDCodes || '')
      } else {
        addToast({
          title: 'Some Error Occurred',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error fetching country details:', error)
    } finally {
      hideSpinner()
    }
  }

  const handleCancel = () => {
    navigate(`/locations/country-list`)
  }

  useEffect(() => {
    if (id) {
      getCountryDetails()
    }
  }, [id])

  useEffect(() => {
    if (selectedCountry) {
      const countryData = countryOptions.find(
        (country) => country.label === selectedCountry?.label
      )
      setValue('COUNTRY_CODE', countryData?.code || '')
      setValue('ISD_CODE', countryData?.dial_code || '')
    }
  })

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
      <div className="mt-[25px]">
        <p className="text-xl font-bold text-navy-700 dark:text-white">
          {id ? 'Edit' : 'Add'} Country
        </p>
      </div>

      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="mt-7 grid grid-cols-1 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold text-navy-700">
              Country <span className="text-red-500"> * </span>
            </label>
            <Controller
              name="COUNTRY_NAME"
              control={control}
              rules={{ required: 'Country is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={countryOptions}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  noOptionsMessage={() => 'Type to search...'}
                  styles={dropDownStyleWithErrorAndFocus(error)}
                />
              )}
            />
            {errors.COUNTRY_NAME && (
              <div className="error-message text-right text-red-500">
                {errors.COUNTRY_NAME.message}
              </div>
            )}
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Country Code"
              id="COUNTRY_CODE"
              disabled
              type="text"
              placeholder="Enter country code"
              registrationProps={register('COUNTRY_CODE')}
            />
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="ISD Code"
              id="ISD_CODE"
              type="text"
              disabled
              placeholder="Enter ISD code"
              registrationProps={register('ISD_CODE')}
            />
          </div>

          <div className="flex w-full justify-end">
            <button
              className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
              type="submit"
            >
              {id ? 'Update' : 'Save'}
            </button>
            <button
              className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base focus:outline-none focus:ring-2 focus:ring-gray-700 active:ring-2 active:ring-gray-500`}
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </Card>
  )
}

export default AddCountry
