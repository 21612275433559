import React, { useState, useEffect } from 'react'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import Card from 'components/card'
import { useForm, Controller } from 'react-hook-form'
import { useToaster } from 'common/Toaster'
import CrawfordConstants from 'common/config/crawfordConstants'
import { useSpinner } from 'common/SpinnerLoader'
import { useParams, useNavigate } from 'react-router-dom'
import InputField from 'components/fields/InputField'
import Select from 'react-select'
import SwitchWithText from 'components/switch-with-text'
const apiService = ApiCaller()
const { TOAST } = CrawfordConstants

/**
 * Renders a form to add or edit a zone.
 *
 * @return {JSX.Element} The rendered form component.
 */
const AddBranch = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      OfficeName: '',
      BranchCode: '',
      Email: '',
      SeriesFormat: '',
      BranchName: '',
      ShortName: '',
      Password: '',
    },
  })

  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()

  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [isBranchActive, setBranchStatus] = useState(true)
  const [isBranchBillable, setBranchBillable] = useState(true)

  const CompanyId = '66fa73575663624f2fde4eb1'

  const seriesFormatOption = [
    { label: 'Monthly', value: 'monthly' },
    { label: 'Yearly', value: 'yearly' },
  ]

  const changeBranchStatus = () => {
    setBranchStatus(!isBranchActive)
  }

  const changeBranchBillable = () => {
    setBranchBillable(!isBranchBillable)
  }

  // Fetch data from API
  const fetchOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.OFFICE_LIST
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: {
          company: CompanyId,
        },
        fields: [],
        page: inputValue ? 1 : 0,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  // Handle input change and trigger API call
  const handleInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchOptions(inputValue)
    }
  }

  const handleSelectionChange = (OfficeName) => {
    if (!OfficeName) {
      fetchOptions() // Call the function to perform API request
    } else {
      console.log('Selected option:', OfficeName)
    }
  }

  /**
   * Handles form submission to create or edit a branch.
   *
   * @async
   * @function onFormSubmit
   * @param {Object} formData - The form data submitted by the user.
   * @param {Object} formData.OfficeName - The selected office object with a label and value.
   * @param {string} formData.BranchName - The name of the branch.
   * @param {string} formData.ShortName - The short code for the branch.
   * @param {string} formData.Email - The email address of the branch.
   * @param {Object} formData.SeriesFormat - The series format object with label and value.
   * @param {boolean} isBranchBillable - Indicates whether the branch is billable.
   * @param {boolean} isBranchActive - Indicates whether the branch is active.
   * @param {string} [id] - The branch ID, used for editing an existing branch.
   * @returns {Promise<void>} A promise that resolves when the form is submitted and the API call completes.
   * @throws Will throw an error if the API call fails.
   */
  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.BRANCH_CREATE
      const payload = {
        company: CompanyId,
        office: formData.OfficeName.value,
        name: formData.BranchName,
        code: formData.BranchCode,
        email: formData.Email,
        seriesFormat: formData.SeriesFormat.value,
        shortName: formData.ShortName,
        isBillable: isBranchBillable,
        status: isBranchActive,
        _id: id ? id : undefined,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        addToast({
          title: `Branch "${formData.BranchName}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        navigate(`/company-setup/branch-list`)
      } else {
        addToast({
          title: `Branch creation failed.`,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  /**
   * Fetches the details of a specific zone and sets the form values.
   *
   * @async
   * @function getBranchDetails
   * @returns {Promise<void>} A promise that resolves when the zone details are fetched and the form values are set.
   */
  const getBranchDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.BRANCH_DETAIL.replace(':id', id)
      const payload = {
        _id: id,
        checkRoles: false,
        accessOf: ['branchEdit'],
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data === 'Access Denied') {
        addToast({
          title: 'Access Denied',
          type: 'error',
        })
        navigate(`/company-setup/branch-list`)
      } else if (response?.data?.value) {
        setValue(
          'OfficeName',
          {
            label: response?.data?.data?.office?.name,
            value: response?.data?.data?.office?._id,
          } || {}
        )
        setValue('BranchName', response?.data?.data?.name || '')
        setValue('Email', response?.data?.data?.email || '')
        setValue('ShortName', response?.data?.data?.shortName || '')
        setValue('BranchCode', response?.data?.data?.code || '')
        setValue(
          'SeriesFormat',
          {
            label:
              response?.data?.data?.seriesFormat.charAt(0).toUpperCase() +
              response?.data?.data?.seriesFormat.slice(1),
            value: response?.data?.data?.seriesFormat,
          } || {}
        )
        setBranchStatus(response?.data?.data?.status)
        setBranchBillable(response?.data?.data?.isBillable)
      } else {
        addToast({
          title: 'Some Error Occured',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error verifying token:', error)
    } finally {
      hideSpinner()
    }
  }

  const handleCancel = () => {
    navigate(`/company-setup/branch-list`)
  }

  useEffect(() => {
    if (id) {
      getBranchDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="mt-7 grid grid-cols-2 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <p className="text-xl font-bold text-navy-700">
              {id ? 'Edit' : 'Add'} Branch
            </p>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <div className="flex w-full items-center justify-end">
              <label className="mr-4 block text-sm font-bold">Active</label>
              <SwitchWithText
                textLeft="OFF"
                textRight="ON"
                checked={isBranchActive}
                onChange={changeBranchStatus}
              />
            </div>
          </div>
        </div>

        <div className="mt-7 grid grid-cols-2 gap-3">
          <div className="col-span-2 mt-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold">
              Office Name <span className="text-red-500">*</span>
            </label>
            <Controller
              name="OfficeName"
              control={control}
              rules={{ required: 'Office name is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={options}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handleInputChange}
                  onFocus={() => fetchOptions()}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(OfficeName) => {
                    field.onChange(OfficeName)
                    handleSelectionChange(OfficeName) // Call a function to handle API call
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      minHeight: '44px',
                      borderRadius: '0.75rem',
                      borderWidth: '1px',
                      borderColor: error
                        ? 'red'
                        : state.isFocused
                          ? 'rgb(59, 130, 246)' // Tailwind blue-500 for focus
                          : 'rgba(218, 222, 236, 1)', // Default gray color

                      // Blue outline (ring) on focus, red if error
                      boxShadow: state.isFocused
                        ? `0 0 0 1px ${error ? 'red' : 'rgb(59, 130, 246)'}`
                        : '',

                      '&:hover': {
                        borderColor: error ? 'red' : 'rgb(59, 130, 246)', // Hover blue if no error
                      },
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      fontSize: '14px',
                      color: error ? 'red' : '#999', // Red placeholder on error
                    }),
                    singleValue: (base) => ({
                      ...base,
                      color: '#333', // Color for selected text
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      color: state.isFocused
                        ? 'rgb(59, 130, 246)'
                        : 'rgba(156, 163, 175, 1)', // Blue dropdown indicator on focus
                      '&:hover': {
                        color: 'rgb(59, 130, 246)',
                      },
                    }),
                    indicatorSeparator: (base) => ({
                      ...base,
                      display: 'none', // Remove separator
                    }),
                  }}
                />
              )}
            />
            {errors.OfficeName && (
              <div className="error-message text-right text-red-500">
                {errors.OfficeName.message}
              </div>
            )}
          </div>
          <div className="col-span-2 mt-1 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Branch Code"
              id="BranchCode"
              type="text"
              placeholder="Enter branch code"
              registrationProps={register('BranchCode', {
                required: 'Branch code is required',
              })}
              isFieldRequired={true}
              state={errors.BranchCode && 'error'}
            />
            <div className="error-message text-right">
              {errors.BranchCode?.message}
            </div>
          </div>
          <div className="col-span-2 mt-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Email"
              id="Email"
              type="text"
              placeholder="Enter email"
              registrationProps={register('Email')}
            />
          </div>
          <div className="col-span-2 mt-3 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold">
              Series Format
            </label>
            <Controller
              name="SeriesFormat"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={seriesFormatOption}
                  placeholder="Select series format"
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      minHeight: '44px',
                      borderRadius: '0.75rem',
                      borderWidth: '1px',
                      borderColor: state.isFocused
                        ? 'rgb(59, 130, 246)'
                        : 'rgba(218, 222, 236, 1)', // Blue border on focus, light gray otherwise
                      boxShadow: state.isFocused
                        ? '0 0 0 1px rgba(59, 130, 246, 1)'
                        : '', // Blue outline on focus
                      '&:hover': {
                        borderColor: 'rgb(59, 130, 246)', // Blue on hover
                      },
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      color: state.isFocused
                        ? 'rgb(59, 130, 246)'
                        : 'rgb(156, 163, 175)', // Blue indicator on focus, gray otherwise
                      '&:hover': {
                        color: 'rgb(59, 130, 246)', // Blue on hover
                      },
                    }),
                    indicatorSeparator: (base) => ({
                      ...base,
                      display: 'none', // Remove separator for cleaner design
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      color: '#999', // Placeholder color
                      fontSize: '14px',
                    }),
                    singleValue: (base) => ({
                      ...base,
                      color: '#333', // Color of selected text
                    }),
                  }}
                />
              )}
            />
          </div>

          <div className="col-span-2 mt-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Branch Name"
              id="BranchName"
              type="text"
              placeholder="Enter branch name"
              registrationProps={register('BranchName', {
                required: 'Branch name is required',
              })}
              isFieldRequired={true}
              state={errors.BranchName && 'error'}
            />
            <div className="error-message text-right">
              {errors.BranchName?.message}
            </div>
          </div>
          <div className="col-span-2 mt-2 lg:col-span-1">
            <InputField
              extra=" w-full"
              label="Short Name"
              id="ShortName"
              type="text"
              placeholder="Enter short name"
              registrationProps={register('ShortName', {
                required: 'Short name is required',
              })}
              isFieldRequired={true}
              state={errors.ShortName && 'error'}
            />
            <div className="error-message text-right">
              {errors.ShortName?.message}
            </div>
          </div>

          <div className="col-span-2 mt-1 lg:col-span-1">
            <InputField
              extra="mt-2 w-full"
              label="Password"
              id="Password"
              type="password"
              placeholder="Enter password"
              registrationProps={register('Password')}
            />
          </div>
          <div className="col-span-2 mt-1 lg:col-span-1">
            <label className="mb-4 mt-2 block text-sm font-bold">
              Billable Branch
            </label>
            <SwitchWithText
              textLeft="NO"
              textRight="YES"
              checked={isBranchBillable}
              onChange={changeBranchBillable}
            />
          </div>
        </div>
        {/* Button aligned to the right */}
        <div className="flex w-full justify-end">
          <button
            className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
            type="submit"
          >
            <span>{id ? 'Update' : 'Save'}</span>
          </button>

          <button
            className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base focus:outline-none focus:ring-2 focus:ring-gray-700 active:ring-2 active:ring-gray-500`}
            type="button"
            onClick={handleCancel}
          >
            <span>Cancel</span>
          </button>
        </div>
      </form>
    </Card>
  )
}

export default AddBranch
