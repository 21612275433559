import React from 'react'
import CurrencyList from 'views/Finance/Currency'
import CurrencyAdd from 'views/Finance/Currency/components/AddCurrency'
import InvoiceExpenditureList from 'views/Finance/InvoiceExpenditure'
import InvoiceExpenditureAdd from 'views/Finance/InvoiceExpenditure/components/AddInvoiceExpenditure'
import { TbCurrencyDollar } from 'react-icons/tb'
import { FaFileInvoiceDollar } from 'react-icons/fa'

const financeRoutes = [
  {
    name: 'Currency',
    layout: '/afterLogin',
    path: '/finance/currency-list',
    icon: <TbCurrencyDollar className="text-inherit h-6 w-6" />,
    component: <CurrencyList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Finance',
    breadcrum: [
      { label: 'Finance', link: '', breadCrumDisabled: true },
      { label: 'Currency', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Currency',
    layout: '/afterLogin',
    path: '/finance/currency-create',
    icon: <TbCurrencyDollar className="text-inherit h-6 w-6" />,
    component: <CurrencyAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Finance',
    breadcrum: [
      { label: 'Finance', link: '', breadCrumDisabled: true },
      { label: 'Currency', link: '/finance/currency-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Currency',
    layout: '/afterLogin',
    path: '/finance/currency-edit/:id',
    icon: <TbCurrencyDollar className="text-inherit h-6 w-6" />,
    component: <CurrencyAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Finance',
    breadcrum: [
      { label: 'Finance', link: '', breadCrumDisabled: true },
      { label: 'Currency', link: '/finance/currency-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Invoice Expenditure',
    layout: '/afterLogin',
    path: '/finance/invoice-expenditure-list',
    icon: <FaFileInvoiceDollar className="text-inherit h-6 w-6" />,
    component: <InvoiceExpenditureList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Finance',
    breadcrum: [
      { label: 'Finance', link: '', breadCrumDisabled: true },
      { label: 'Invoice Expenditure', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Invoice Expenditure',
    layout: '/afterLogin',
    path: '/finance/invoice-expenditure-create',
    icon: <TbCurrencyDollar className="text-inherit h-6 w-6" />,
    component: <InvoiceExpenditureAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Finance',
    breadcrum: [
      { label: 'Finance', link: '', breadCrumDisabled: true },
      {
        label: 'Invoice Expenditure',
        link: '/finance/invoice-expenditure-list',
      },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Invoice Expenditure',
    layout: '/afterLogin',
    path: '/finance/invoice-expenditure-edit/:id',
    icon: <TbCurrencyDollar className="text-inherit h-6 w-6" />,
    component: <InvoiceExpenditureAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Finance',
    breadcrum: [
      { label: 'Finance', link: '', breadCrumDisabled: true },
      {
        label: 'Invoice Expenditure',
        link: '/finance/invoice-expenditure-list',
      },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
]

export default financeRoutes
