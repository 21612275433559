import React, { useState, useRef, useEffect } from 'react'
import Investigator from 'views/Investigator'
import DocumentsComponent from 'views/Documents'
import SwitchWithText from 'components/switch-with-text'
import { FaPhoneAlt } from 'react-icons/fa'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  getUserInfo,
  readFileBasedOnId,
  getLoginEmployeeInfo,
} from 'common/commonFunction'
import ApiCaller from 'common/services/apiServices'
import apiConfig from 'common/config/apiConfig'
import { Chrono } from 'react-chrono'
import ImageGallery from './ImageGallery'
import { useSpinner } from 'common/SpinnerLoader'
import noImg from 'assets/img/layers/no-image.png'
import CrawfordConstants from 'common/config/crawfordConstants'
import { useForm } from 'react-hook-form'
import InputField from 'components/fields/InputField'
import { useToaster } from 'common/Toaster'
import Invoice from './Invoice'
import FSR from 'views/FSR'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { MdKeyboardArrowUp } from 'react-icons/md'
import 'react-accessible-accordion/dist/fancy-example.css'

const moment = require('moment')

const AssignmentTimeline = () => {
  const steps = [
    { label: 'Unassigned', value: 'unassigned' },
    { label: 'Investigation Pending', value: 'investigation-pending' },
    { label: 'Documents', value: 'documents' },
    { label: 'FSR', value: 'fsr' },
    { label: 'TBB', value: 'tbb' },
    { label: 'BBND', value: 'bbnd' },
    { label: 'DBND', value: 'dbnd' },
    { label: 'Deliverd', value: 'deliverd' },
    { label: 'ReOpen', value: 're-open' },
  ]
  const navigate = useNavigate()
  const { addToast } = useToaster()
  const { NO_DATA } = CrawfordConstants
  const apiService = ApiCaller()
  const [searchParams] = useSearchParams()
  const step = searchParams.get('step')
  const [currentStep, setCurrentStep] = useState(0)
  const [currentStepName, setCurrentStepName] = useState(steps[0].value)
  const [showLeftButton, setShowLeftButton] = useState(false)
  const [showRightButton, setShowRightButton] = useState(false)
  const [isTimeline, setIsTimeline] = useState(false)
  const [assignmentTimeline, setAssignmentTimeline] = useState()
  const [photos, setPhotos] = useState([])
  const { showSpinner, hideSpinner } = useSpinner()
  const stepperRef = useRef(null)
  const { id } = useParams()
  const userInfo = getUserInfo()
  const [timeLineItem, setTimeLineItem] = useState([])
  const [isContactPersonOpen, setContactPersonOpen] = useState(false)
  const [isNotesOpen, setNoteOpen] = useState(false)
  const [investigatorOpen, setInvestigatorOpen] = useState(false)
  const [notesOpen, setNotesOpen] = useState(false)

  const loginEmployeeInfo = getLoginEmployeeInfo()
  // Contact Person Form
  const {
    handleSubmit: handleContactPersonSubmit,
    reset: resetContactPersonForm,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      mobile: '',
    },
  })
  // Note Form
  const {
    handleSubmit: handleNoteSubmit,
    reset: resetNoteForm,
    register: noteRegister,
    formState: { errors: noteError },
  } = useForm({
    defaultValues: {
      description: '',
    },
  })

  // const goToPreviousStep = () => setCurrentStep((prev) => Math.max(prev - 1, 0))
  // const goToNextStep = () =>
  //   setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1))

  const createObjectForPhotosUrl = (inputArray) => {
    return inputArray.map((item) => ({
      ...item,
      url: readFileBasedOnId(item.file), // Construct URL based on the file ID
    }))
  }

  const scrollStepper = (direction) => {
    if (stepperRef.current) {
      stepperRef.current.scrollBy({
        left: direction === 'left' ? -150 : 150,
        behavior: 'smooth',
      })
    }
  }

  const checkScrollButtons = () => {
    if (stepperRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = stepperRef.current
      setShowLeftButton(scrollLeft > 0)
      setShowRightButton(scrollLeft + clientWidth < scrollWidth)
    }
  }

  const changeOverviewAndTimeline = () => {
    setIsTimeline(!isTimeline)
  }

  const timeLineObjectCreate = (chat) => {
    const items = chat.map((item) => ({
      title: moment(item.time).format('DD-MMM-YYYY dddd HH:mm'),
      cardTitle: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <img
            src={readFileBasedOnId(item?.employee?.photo) || noImg}
            alt={item?.employee?.name}
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
          />
          <span>{item.employee.name}</span>
        </div>
      ),
      cardSubtitle: item.title,
    }))
    setTimeLineItem(items.reverse())
  }

  const fetchAssignmentAndTimeLineDetails = async () => {
    showSpinner()
    try {
      const payload = {
        _id: id,
        employee: { _id: userInfo.employee, name: userInfo.name },
      }
      const apiUrl = apiConfig.ASSIGNMENT_TIME_LINE
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        setContactPersonOpen(false)
        setNoteOpen(false)
        setInvestigatorOpen(false)
        const assignmentTimeline = response?.data?.data
        setAssignmentTimeline(assignmentTimeline)
        const photosResult = await createObjectForPhotosUrl(
          assignmentTimeline?.assignment?.photos
        )
        setPhotos(photosResult)
        if (assignmentTimeline?.timeline?.chat?.length > 0) {
          timeLineObjectCreate(assignmentTimeline?.timeline?.chat)
        }
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      hideSpinner()
    }
  }

  const saveAssignmentTimeLine = async (operationName, actionName) => {
    try {
      showSpinner()
      const data = {
        timelineId: assignmentTimeline?.timeline?._id,
        lastTimelineObj: {
          employee: userInfo.employee,
          type: 'Normal',
          title: `${operationName} ${actionName}`,
        },
        employeeId: userInfo.employee,
      }
      const apiUrl = apiConfig.SAVE_ASSIGNMENT_TIME_LINE
      const response = await apiService.apiCall('post', apiUrl, data)
      if (response?.data?.value) {
        fetchAssignmentAndTimeLineDetails()
      }
    } catch (error) {
      console.error('Error uploading files:', error)
    } finally {
      hideSpinner()
    }
  }

  const saveDataForAssignment = async (data, operationName, actionName) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.SAVE_ASSIGNMENT
      const response = await apiService.apiCall('post', apiUrl, data)
      if (response?.data?.value) {
        addToast({
          title: `${operationName} ${actionName} successfully.`,
          type: 'success',
        })
        saveAssignmentTimeLine(operationName, actionName)
      }
    } catch (error) {
      console.error('Error uploading files:', error)
    } finally {
      hideSpinner()
    }
  }

  useEffect(() => {
    fetchAssignmentAndTimeLineDetails()
    const stepperEl = stepperRef.current
    checkScrollButtons()
    if (stepperEl) {
      stepperEl.addEventListener('scroll', checkScrollButtons)
      window.addEventListener('resize', checkScrollButtons)
    }
    if (step === 'fsr') {
      setCurrentStepName('fsr')
      const index = steps.findIndex((s) => s.value === step)
      setCurrentStep(index)
      navigate(window.location.pathname, { replace: true })
    }
    return () => {
      if (stepperEl) {
        stepperEl.removeEventListener('scroll', checkScrollButtons)
        window.removeEventListener('resize', checkScrollButtons)
      }
    }
  }, [])

  const onContactPersonSubmit = (formData) => {
    const contact = assignmentTimeline?.assignment?.contactPerson || []
    const newContactPersonObject = {
      name: formData.name,
      email: formData.email,
      mobile: formData.mobile,
    }
    // Ensure notes array is not directly mutated
    const updatedContactPerson = [...contact, newContactPersonObject]
    const data = {
      _id: assignmentTimeline?.assignment?._id,
      contactPerson: updatedContactPerson,
      users: {
        email: userInfo.email,
        name: userInfo.name,
      },
    }
    saveDataForAssignment(data, `${formData.name}'s contact`, 'added')
  }

  const onNoteSubmit = (formData) => {
    const notes = assignmentTimeline?.assignment?.notes || []
    const newNoteObject = {
      email: loginEmployeeInfo.officeEmail,
      name: loginEmployeeInfo.name,
      description: formData.description,
      time: new Date().toISOString(),
    }
    // Ensure notes array is not directly mutated
    const updatedNotes = [...notes, newNoteObject]
    const data = {
      _id: assignmentTimeline?.assignment?._id,
      notes: updatedNotes,
      users: {
        email: loginEmployeeInfo.officeEmail,
        name: loginEmployeeInfo.name,
      },
    }
    saveDataForAssignment(data, `Note`, 'added')
  }

  const onInvestigatorSubmit = (investigator) => {
    const investigators = assignmentTimeline?.assignment?.investigators || []
    const newInvestigatorObject = {
      email: investigator.email,
      name: investigator.name,
      distance: investigator.distance,
      _id: investigator._id,
      time: new Date().toISOString(),
    }
    // Ensure investigators array is not directly mutated
    const updatedInvestigators = [...investigators, newInvestigatorObject]
    const data = {
      _id: assignmentTimeline?.assignment?._id,
      investigators: updatedInvestigators,
      users: {
        email: loginEmployeeInfo.officeEmail,
        name: loginEmployeeInfo.name,
      },
    }
    saveDataForAssignment(data, `Investigator`, 'added')
  }

  useEffect(() => {
    if (investigatorOpen || isContactPersonOpen || isNotesOpen) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
    // Clean up on unmount
    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [investigatorOpen, isContactPersonOpen, isNotesOpen])

  return (
    <div className="AssignmentTimeline">
      <div className="grid grid-cols-2 gap-3 mb-4">
        <div className="col-span-2 lg:col-span-1">
          <div className="flex w-full justify-start">
            <SwitchWithText
              textLeft="Overview"
              textRight="Timeline"
              checked={isTimeline}
              onChange={changeOverviewAndTimeline}
              afterWidth="after:w-[80px]"
            />
          </div>
        </div>
      </div>
      {/* Stepper with Scroll Buttons on Hover */}
      <div className="group relative flex items-center justify-center">
        {/* Left Scroll Button (visible on hover) */}
        {showLeftButton && (
          <button
            onClick={() => scrollStepper('left')}
            className="absolute left-0 z-[9999] h-full items-center justify-center rounded-md bg-blue-500 px-2 py-2 text-white shadow-md group-hover:flex"
            aria-label="Scroll Left"
          >
            &#9664;
          </button>
        )}

        <div
          ref={stepperRef}
          className="scrollbar-hide mx-12 flex w-full overflow-x-auto scroll-smooth whitespace-nowrap"
          style={{
            msOverflowStyle: 'none', // For IE and Edge
            scrollbarWidth: 'none', // For Firefox
          }}
        >
          <style>
            {`
              .scrollbar-hide::-webkit-scrollbar {
                display: none;
              }
            `}
          </style>
          {steps.map(({ label, value }, index) => (
            <li
              key={index}
              className={`relative inline-flex min-w-[150px] cursor-pointer items-center justify-center ${
                index < steps.length - 1
                  ? "after:absolute after:left-16 after:top-3 after:inline-block after:h-0.5 after:w-full after:bg-blue-500 after:content-[''] lg:after:top-6"
                  : ''
              } ${index < currentStep ? 'after:h-1' : ''}`}
              onClick={() => {
                setCurrentStep(index)
                setCurrentStepName(value)
              }}
            >
              <div className="z-10 block">
                <span
                  className={`mx-auto mb-3 flex h-6 w-6 items-center justify-center rounded-full text-sm lg:h-12 lg:w-12 ${
                    index < currentStep
                      ? 'bg-blue-500 text-white'
                      : index === currentStep
                        ? 'border-2 border-dashed border-blue-500'
                        : 'border-2 border-blue-500 bg-white'
                  }`}
                >
                  {index === currentStep && (
                    <span className="h-10 w-10 rounded-full bg-blue-500"></span>
                  )}
                </span>
                <div className="h-[30px] max-w-[150px] px-2 text-center">
                  {label}
                </div>
              </div>
            </li>
          ))}
        </div>

        {/* Right Scroll Button (visible on hover) */}
        {showRightButton && (
          <button
            onClick={() => scrollStepper('right')}
            className="absolute right-0 z-[9999] h-full items-center justify-center rounded-md bg-blue-500 px-2 py-2 text-white shadow-md group-hover:flex"
            aria-label="Scroll Right"
          >
            &#9654;
          </button>
        )}
      </div>

      {/* Navigation Buttons */}
      {/* <div className="mt-8 flex justify-center space-x-4">
        <button
          onClick={goToPreviousStep}
          className={`rounded px-4 py-2 text-white ${
            currentStep === 0 ? 'cursor-not-allowed bg-gray-400' : 'bg-blue-500'
          }`}
          disabled={currentStep === 0}
        >
          Previous
        </button>
        <button
          onClick={goToNextStep}
          className={`rounded px-4 py-2 text-white ${
            currentStep === steps.length - 1
              ? 'cursor-not-allowed bg-gray-400'
              : 'bg-blue-500'
          }`}
          disabled={currentStep === steps.length - 1}
        >
          Next
        </button>
      </div> */}

      {currentStepName === 'unassigned' && (
        <>
          {!isTimeline && (
            <>
              <div className="mt-5 grid w-full grid-cols-12 gap-3">
                <div className="bg-white p-4 rounded-2xl col-span-12 lg:col-span-4 xl:col-span-4">
                  <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
                    <div className="flex w-full justify-between">
                      <p className="text-sm text-gray-600 flex-1">Jobs Owner</p>
                      <p className="text-sm text-gray-800 flex-1 text-right">
                        {assignmentTimeline?.assignment?.owner?.name || '-'}
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
                    <div className="flex w-full justify-between">
                      <p className="text-sm text-gray-600 flex-1">Stage</p>
                      <p className="text-sm text-gray-800 flex-1 text-right">
                        <span className="text-sm font-medium text-white bg-blue-500 py-1 px-2 rounded-full flex-1">
                          {assignmentTimeline?.assignment?.timelineStatus}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
                    <div className="flex w-full justify-between">
                      <p className="text-sm text-gray-600 flex-1">
                        Expected Revenue
                      </p>
                      <p className="text-sm text-gray-800 flex-1 text-right">
                        {assignmentTimeline?.assignment?.expectedRevenue || '-'}
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
                    <div className="flex w-full justify-between">
                      <p className="text-sm text-gray-600 flex-1">
                        Closing Date
                      </p>
                      <p className="text-sm text-gray-800 flex-1 text-right">
                        {assignmentTimeline?.assignment?.closingDate
                          ? moment(
                              assignmentTimeline?.assignment?.closingDate
                            ).format('MMM DD, YYYY')
                          : '-'}
                      </p>
                    </div>
                  </div>
                </div>
                {/* contactPerson  replace with products */}
                <div className="bg-white p-4 rounded-2xl col-span-12 lg:col-span-4 xl:col-span-4">
                  <div className="grid grid-cols-2 gap-3">
                    <div className="flex block text-sm font-bold">
                      Contact Person
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                      <div className="flex w-full justify-end">
                        <button
                          className={`w-28 h-8 p-1 cursor-pointer items-center justify-center rounded-xl bg-brand-500 text-xs text-white transition duration-200
              hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
                          type="button"
                          onClick={() => {
                            setContactPersonOpen(true) // Separate statements with a semicolon
                            resetContactPersonForm()
                          }}
                        >
                          <span>Add Contact</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="min-h-[50px] max-h-[100px] overflow-y-auto overflow-x-hidden">
                    {assignmentTimeline?.assignment?.contactPerson?.map(
                      (contactPerson, index) => (
                        <div
                          key={index}
                          className="flex w-[450px] flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2  border border-gray-300 rounded-lg p-2"
                        >
                          <div className="flex gap-3">
                            <div>
                              <p className="text-sm text-blue-500">
                                {contactPerson.name}
                              </p>
                              <p className="mt-1 text-sm text-gray-800">
                                {contactPerson.email}
                              </p>
                              <p className="mt-2 flex text-sm text-gray-800">
                                <FaPhoneAlt />
                                <span className="ml-2">
                                  {contactPerson.mobile}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                    {(!assignmentTimeline?.assignment?.contactPerson ||
                      assignmentTimeline.assignment.contactPerson.length ===
                        0) && (
                      <div className="flex justify-between items-center mt-4">
                        <div className="text-md flex w-full justify-center text-center text-gray-500 dark:text-gray-400">
                          {NO_DATA}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="bg-white p-4 rounded-2xl col-span-12 lg:col-span-4 xl:col-span-4">
                  <div className="grid grid-cols-2 gap-3">
                    <div className="flex block text-sm font-bold">
                      Next Action
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                      <div className="flex w-full justify-end">
                        <button
                          className={`w-40 h-8 p-1 cursor-pointer items-center justify-center rounded-xl bg-brand-500 text-xs text-white transition duration-200
              hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
                          type="button"
                          onClick={() => {
                            setInvestigatorOpen(true) // Separate statements with a semicolon
                          }}
                        >
                          <span>Assign Investigator</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {assignmentTimeline?.assignment?.investigators?.map(
                    (investigator, index) => (
                      <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden mt-2">
                        <div className="flex">
                          <div className="flex items-center justify-center rounded-md px-2 py-1 bg-green-500 text-white">
                            <p className="text-xs font-bold">
                              {' '}
                              {moment(investigator.time).format(
                                'MMM DD, YYYY'
                              )}{' '}
                            </p>
                          </div>
                          <p className="ml-2 text-sm text-gray-800">
                            {investigator.name}
                          </p>
                        </div>
                      </div>
                    )
                  )}
                  {(!assignmentTimeline?.assignment?.investigators ||
                    assignmentTimeline.assignment.investigators.length ===
                      0) && (
                    <div className="flex justify-between items-center mt-4">
                      <div className="text-md flex w-full justify-center text-center text-gray-500 dark:text-gray-400">
                        {NO_DATA}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-2 grid w-full grid-cols-12 gap-3">
                <div className="bg-white p-4 rounded-2xl col-span-12 lg:col-span-4 xl:col-span-4">
                  <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
                    <div className="flex w-full justify-between">
                      <p className="text-sm text-gray-600 flex-1">GR #</p>
                      <p className="text-sm text-gray-800 flex-1 text-right">
                        {assignmentTimeline?.assignment?.name || '-'}
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
                    <div className="flex w-full justify-between">
                      <p className="text-sm text-gray-600 flex-1">DOL</p>
                      <p className="text-sm text-gray-800 flex-1 text-right">
                        {moment(
                          assignmentTimeline?.assignment?.dateOfLoss
                        ).format('MMM DD, YYYY') || '-'}
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
                    <div className="flex w-full justify-between">
                      <p className="text-sm text-gray-600 flex-1">DOA</p>
                      <p className="text-sm text-gray-800 flex-1 text-right">
                        {moment(
                          assignmentTimeline?.assignment?.dateOfAppointment
                        ).format('MMM DD, YYYY') || '-'}
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
                    <div className="flex w-full justify-between">
                      <p className="text-sm text-gray-600 flex-1">COL</p>
                      <p className="text-sm text-gray-800 flex-1 text-right">
                        {assignmentTimeline?.assignment?.causeOfLoss?.name ||
                          '-'}
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-2">
                    <div className="flex w-full justify-between">
                      <p className="text-sm text-gray-600 flex-1">NOL</p>
                      <p className="text-sm text-gray-800 flex-1 text-right">
                        {assignmentTimeline?.assignment?.natureOfLoss
                          .map((item) => item.name)
                          .join(', ') || '-'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2 h-fit w-full border-0 rounded-2xl">
                <Accordion
                  className="bg-white border-0 rounded-2xl"
                  defaultIndex={[0]}
                  allowMultipleExpanded={true}
                  allowZeroExpanded={true}
                >
                  <AccordionItem className="bg-white border-0 p-2 ml-2 rounded-2xl">
                    <AccordionItemHeading
                      className="pr-6"
                      onClick={() => {
                        setNotesOpen(!notesOpen)
                      }}
                    >
                      <AccordionItemButton className="flex items-center justify-between py-2">
                        <p className="flex block text-sm font-bold">Notes</p>
                        <div className="flex items-center gap-2">
                          {/* New Button */}
                          <button
                            className={`w-28 h-8 p-1 cursor-pointer items-center justify-center rounded-xl bg-brand-500 text-xs text-white transition duration-200
                              hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation()
                              setNoteOpen(true) // Separate statements with a semicolon
                              resetNoteForm()
                            }}
                          >
                            <span>Add Note</span>
                          </button>
                          {/* Arrow Icon */}
                          {notesOpen ? (
                            <MdKeyboardArrowDown className="text-lg text-gray-600" />
                          ) : (
                            <MdKeyboardArrowUp className="text-lg text-gray-600" />
                          )}
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel className="py-0">
                      {assignmentTimeline?.assignment?.notes?.map(
                        (note, index) => (
                          <div
                            key={index}
                            className={`flex w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden m-1 border border-gray-300 rounded-lg p-2 ${
                              index === 0 ? 'mt-2' : ''
                            }`}
                          >
                            <div className="flex gap-3">
                              <div>
                                <p className="text-sm font-semibold text-gray-800">
                                  {note.name}
                                </p>
                                <p className="mt-1 text-sm text-gray-800">
                                  {note.description}
                                </p>
                                <p className="mt-2 flex text-xs font-semibold text-gray-500">
                                  {moment(note.time).format(
                                    'DD-MMM-YYYY dddd HH:mm'
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                      {(!assignmentTimeline?.assignment?.notes ||
                        assignmentTimeline.assignment.notes.length === 0) && (
                        <div className="flex justify-between items-center">
                          <div className="text-md flex w-full justify-center text-center text-gray-500 dark:text-gray-400">
                            {NO_DATA}
                          </div>
                        </div>
                      )}
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
              {/* Invoice component load */}
              <Invoice />
              {/* Attachement component load */}
              <ImageGallery
                images={photos}
                assignmentTimeline={assignmentTimeline}
                userInfo={userInfo}
                fetchAssignmentAndTimeLineDetails={
                  fetchAssignmentAndTimeLineDetails
                }
              />
            </>
          )}

          {isTimeline && (
            <>
              {timeLineItem && timeLineItem?.length > 0 ? (
                <div className="mt-4 bg-white w-full timeline-unlist">
                  <Chrono
                    items={timeLineItem}
                    timelinePointShape="diamond"
                    mode="VERTICAL"
                  />
                </div>
              ) : (
                <div className="flex items-center justify-center min-h-[500px] bg-white text-gray-500 text-lg mt-5">
                  {NO_DATA}
                </div>
              )}
            </>
          )}
        </>
      )}

      {currentStepName === 'fsr' && <FSR />}
      {currentStepName === 'documents' && <DocumentsComponent />}

      {isContactPersonOpen && (
        <div
          className="relative z-[99]"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          {/* <!-- Overlay background --> */}
          <div
            className="fixed inset-0 bg-gray-500/75 transition-opacity"
            aria-hidden="true"
          ></div>
          {/* <!-- Modal container --> */}
          <div className="fixed inset-0 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              {/* <!-- Modal content --> */}
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                {/* <!-- Title section --> */}
                <div className="bg-brand-500 px-2 pb-4 sm:p-2 sm:pb-2">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-2 text-center sm:ml-2 sm:mt-0 sm:text-left">
                      <h2
                        className="text-lg font-semibold text-white"
                        id="modal-title"
                      >
                        {/* {getValues('documentId') ? 'Edit' : 'Create'} */}
                        Add Contact Person
                      </h2>
                    </div>
                  </div>
                </div>
                <form
                  onSubmit={handleContactPersonSubmit(onContactPersonSubmit)}
                >
                  {/* <!-- Body section --> */}
                  <div className="mt-2 px-4 pb-4 sm:px-6 sm:pb-4 overflow-y-auto">
                    <div className="grid grid-cols-2 gap-3">
                      {/* <input
                        type="hidden"
                        id="hiddenField"
                        {...register('documentId')}
                      /> */}
                      <div className="col-span-2 lg:col-span-1">
                        <InputField
                          extra="w-full"
                          label="Name"
                          id="Name"
                          type="text"
                          placeholder="Enter name"
                          registrationProps={register('name', {
                            required: 'Name is required',
                          })}
                          isFieldRequired={true}
                          state={errors.name && 'error'}
                        />
                        <div className="error-message text-right">
                          {errors.name?.message}
                        </div>
                      </div>
                      <div className="col-span-2 lg:col-span-1">
                        <InputField
                          extra="w-full"
                          label="Email"
                          id="Email"
                          type="text"
                          placeholder="Enter email"
                          registrationProps={register('email', {
                            required: 'Email is required',
                          })}
                          isFieldRequired={true}
                          state={errors.email && 'error'}
                        />
                        <div className="error-message text-right">
                          {errors.email?.message}
                        </div>
                      </div>
                      <div className="col-span-2 lg:col-span-1">
                        <InputField
                          extra="w-full"
                          label="Mobile"
                          id="Mobile"
                          type="text"
                          placeholder="Enter phone"
                          registrationProps={register('mobile', {
                            required: 'Mobile is required',
                          })}
                          isFieldRequired={true}
                          state={errors.mobile && 'error'}
                        />
                        <div className="error-message text-right">
                          {errors.mobile?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Footer section --> */}
                  <div className="bg-gray-50 px-2 py-2 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      onClick={() => setContactPersonOpen(false)}
                      className="inline-flex w-full justify-center rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-400 sm:ml-3 sm:w-auto"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-brand-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-indigo-200 hover:bg-brand-300 focus:outline-none focus:ring focus:ring-indigo-200 sm:mt-0 sm:w-auto"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {isNotesOpen && (
        <div
          className="relative z-50"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>

          {/* Modal container */}
          <div className="fixed inset-0 flex items-center justify-center overflow-y-auto p-4 sm:p-6">
            {/* Modal content */}
            <div className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white shadow-xl sm:my-8">
              {/* Header */}
              <div className="bg-brand-500 px-4 py-3 sm:px-6">
                <h2
                  className="text-lg font-semibold text-white"
                  id="modal-title"
                >
                  Add Note
                </h2>
              </div>

              {/* Form */}
              <form onSubmit={handleNoteSubmit(onNoteSubmit)}>
                {/* Body */}
                <div className="px-2 py-2 sm:p-4">
                  <div className="grid gap-4">
                    <div>
                      <InputField
                        extra="w-full"
                        label="Description"
                        id="description"
                        type="textarea"
                        placeholder="Enter description"
                        registrationProps={noteRegister('description', {
                          required: 'Description is required',
                        })}
                        isFieldRequired={true}
                        state={noteError.description && 'error'}
                      />
                      {noteError.description && (
                        <div className="text-red-500 text-sm mt-1">
                          {noteError.description.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Footer */}
                <div className="bg-gray-50 px-2 py-2 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    onClick={() => setNoteOpen(false)}
                    className="inline-flex w-full justify-center rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-400 sm:ml-3 sm:w-auto"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-brand-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-indigo-200 hover:bg-brand-300 focus:outline-none focus:ring focus:ring-indigo-200 sm:mt-0 sm:w-auto"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {investigatorOpen && (
        <div
          className="relative z-[9999]"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>

          {/* Modal container */}
          <div className="fixed inset-0 flex items-center justify-center overflow-y-auto p-4 sm:p-6">
            {/* Modal content */}
            <div className="relative w-full max-w-2xl transform overflow-hidden rounded-lg bg-white shadow-xl sm:my-8">
              {/* Header */}
              <div className="flex justify-between items-center bg-brand-500 px-4 py-3 sm:px-6">
                <h2
                  className="text-lg font-semibold text-white"
                  id="modal-title"
                >
                  Investigator
                </h2>
                {/* Close Icon */}
                <button
                  onClick={() => setInvestigatorOpen(false)}
                  className="text-white hover:text-gray-300"
                  aria-label="Close modal"
                >
                  &#10005; {/* Unicode for '×' */}
                </button>
              </div>
              {/* Body */}
              <div className="h-full overflow-y-auto px-2 py-2 sm:p-4 max-h-[450px]">
                <Investigator setSelectedInvestigator={onInvestigatorSubmit} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AssignmentTimeline
