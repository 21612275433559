export const currency = [
  {
    symbol: '€',
    Currency: 'Euro',
    Country: 'Euro area countries',
    code: 'EUR',
  },
  {
    symbol: 'L',
    Currency: 'Albanian lek',
    Country: 'Albania',
    code: 'ALL',
  },
  {
    symbol: 'Br',
    Currency: 'Belarusian ruble',
    Country: 'Belarus',
    code: 'BYN',
  },
  {
    symbol: 'KM',
    Currency: 'Bosnia and Herzegovina convertible mark',
    Country: 'Bosnia and Herzegovina',
    code: 'BAM',
  },
  {
    symbol: 'лв',
    Currency: 'Bulgarian lev',
    Country: 'Bulgaria',
    code: 'BGN',
  },
  {
    symbol: 'kn',
    Currency: 'Croatian kuna',
    Country: 'Croatia',
    code: 'HRK',
  },
  {
    symbol: 'Kč',
    Currency: 'Czech koruna',
    Country: 'Czechia',
    code: 'CZK',
  },
  {
    symbol: 'kr',
    Currency: 'Danish krone',
    Country: 'Denmark',
    code: 'DKK',
  },
  {
    symbol: 'kn',
    Currency: 'Croatian Kuna',
    Country: 'Croatia',
    code: 'HRK',
  },
  {
    symbol: '₾',
    Currency: 'Georgian lari',
    Country: 'Georgia',
    code: 'GEL',
  },
  {
    symbol: 'kr',
    Currency: 'Danish krone',
    Country: 'Greenland',
    code: 'DKK',
  },
  {
    symbol: 'ft',
    Currency: 'Hungarian forint',
    Country: 'Hungary',
    code: 'HUF',
  },
  {
    symbol: 'kr, Íkr',
    Currency: 'Icelandic króna',
    Country: 'Iceland',
    code: 'ISK',
  },
  {
    symbol: 'CHF',
    Currency: 'Swiss franc',
    Country: 'Liechtenstein',
    code: 'CHF',
  },
  {
    symbol: 'L',
    Currency: 'Moldovan leu',
    Country: 'Moldova',
    code: 'MDL',
  },
  {
    symbol: 'ден',
    Currency: 'Second Macedonian denar',
    Country: 'North Macedonia',
    code: 'MKD',
  },
  {
    symbol: 'kr',
    Currency: 'Norwegian krone',
    Country: 'Norway',
    code: 'NOK',
  },
  {
    symbol: 'zł',
    Currency: 'Polish zloty',
    Country: 'Poland',
    code: 'PLN',
  },
  {
    symbol: 'lei',
    Currency: 'Romanian leu',
    Country: 'Romania',
    code: 'RON',
  },
  {
    symbol: '₽',
    Currency: 'Russian ruble',
    Country: 'Russia',
    code: 'RUB',
  },
  {
    symbol: 'RSD',
    Currency: 'Serbian dinar',
    Country: 'Serbia',
    code: 'RSD',
  },
  {
    symbol: 'kr',
    Currency: 'Swedish krona',
    Country: 'Sweden',
    code: 'SEK',
  },
  {
    symbol: 'CHF',
    Currency: 'Swiss franc',
    Country: 'Switzerland',
    code: 'CHF',
  },
  {
    symbol: '₺',
    Currency: 'Turkish lira',
    Country: 'Turkey',
    code: 'TRY',
  },
  {
    symbol: '₴',
    Currency: 'Ukrainian hryvna',
    Country: 'Ukraine',
    code: 'UAH',
  },
  {
    symbol: '£',
    Currency: 'Pounds sterling',
    Country: 'United Kingdom',
    code: 'GBP',
  },

  {
    symbol: '$',
    Currency: 'United States Dollar',
    Country: 'US Dollar countries',
    code: 'USD',
  },
  {
    symbol: '$',
    Currency: 'East Caribbean dollar',
    Country: 'OECS countries',
    code: 'XCD',
  },
  {
    symbol: 'ƒ',
    Currency: 'Aruban florin',
    Country: 'Aruba',
    code: 'AWG',
  },
  {
    symbol: '$',
    Currency: 'Argentine peso',
    Country: 'Argentina',
    code: 'ARS',
  },
  {
    symbol: 'B$',
    Currency: 'Bahamian dollar',
    Country: 'The Bahamas',
    code: 'BSD',
  },
  {
    symbol: '$',
    Currency: 'Barbadian dollar',
    Country: 'Barbados',
    code: 'BBD',
  },
  {
    symbol: '$',
    Currency: 'Bermudian dollar',
    Country: 'Bermuda',
    code: 'BMD',
  },
  {
    symbol: 'BZ$',
    Currency: 'Belize dollar',
    Country: 'Belize',
    code: 'BZD',
  },
  {
    symbol: 'Bs',
    Currency: 'Bolivian boliviano',
    Country: 'Bolivia',
    code: 'BOB',
  },
  {
    symbol: 'R$',
    Currency: 'Brazilian real',
    Country: 'Brazil',
    code: 'BRL',
  },
  {
    symbol: 'CA$',
    Currency: 'Canadian dollar',
    Country: 'Canada',
    code: 'CAD',
  },
  {
    symbol: 'CI$',
    Currency: 'Cayman Islands dollar',
    Country: 'Cayman Islands',
    code: 'KYD',
  },
  {
    symbol: '$',
    Currency: 'Chilean peso',
    Country: 'Chile',
    code: 'CLP',
  },
  {
    symbol: '$',
    Currency: 'Colombian peso',
    Country: 'Colombia',
    code: 'COP',
  },
  {
    symbol: '₡',
    Currency: 'Costa Rican colón',
    Country: 'Costa Rica',
    code: 'CRC',
  },
  {
    symbol: 'CUC$',
    Currency: 'Cuban peso',
    Country: 'Cuba',
    code: 'CUP',
  },
  {
    symbol: 'ƒ',
    Currency: 'Netherlands Antillean guilder',
    Country: 'Curaçao',
    code: 'ANG',
  },
  {
    symbol: 'RD$',
    Currency: 'Dominican peso',
    Country: 'Dominican Republic',
    code: 'DOP',
  },
  {
    symbol: 'FK£',
    Currency: 'Falkland Islands pound',
    Country: 'Falkland Islands',
    code: 'FKP',
  },
  {
    symbol: 'Q',
    Currency: 'Guatemalan quetzal',
    Country: 'Guatemala',
    code: 'GTQ',
  },
  {
    symbol: 'G$',
    Currency: 'Guyanese dollar',
    Country: 'Guyana',
    code: 'GYD',
  },
  {
    symbol: 'G',
    Currency: 'Haitian gourde',
    Country: 'Haiti',
    code: 'HTG',
  },
  {
    symbol: 'L',
    Currency: 'Honduran lempira',
    Country: 'Honduras',
    code: 'HNL',
  },
  {
    symbol: 'J$',
    Currency: 'Jamaican dollar',
    Country: 'Jamaica',
    code: 'JMD',
  },
  {
    symbol: '$',
    Currency: 'Mexican peso',
    Country: 'Mexico',
    code: 'MXN',
  },
  {
    symbol: 'C$',
    Currency: 'Nicaraguan córdoba',
    Country: 'Nicaragua',
    code: 'NIO',
  },
  {
    symbol: 'B/.',
    Currency: 'Panamanian balboa',
    Country: 'Panama',
    code: 'PAB',
  },
  {
    symbol: '₲',
    Currency: 'Paraguayan guaraní',
    Country: 'Paraguay',
    code: 'PYG',
  },
  {
    symbol: 'S/.',
    Currency: 'Peruvian sol',
    Country: 'Peru',
    code: 'PEN',
  },
  {
    symbol: 'ƒ',
    Currency: 'Netherlands Antillean guilder',
    Country: 'Sint Maarten',
    code: 'ANG',
  },
  {
    symbol: 'Sr$',
    Currency: 'Surinamese dollar',
    Country: 'Suriname',
    code: 'SRD',
  },
  {
    symbol: 'TT$',
    Currency: 'Trinidad and Tobago dollar',
    Country: 'Trinidad and Tobago',
    code: 'TTD',
  },
  {
    symbol: '$U',
    Currency: 'Uruguayan peso',
    Country: 'Uruguay',
    code: 'UYU',
  },
  {
    symbol: 'Bs.',
    Currency: 'Venezuelan bolívar',
    Country: 'Venezuela',
    code: 'VED',
  },

  {
    symbol: '؋',
    Currency: 'Afghan afghani',
    Country: 'Afghanistan',
    code: 'AFN',
  },
  {
    symbol: '֏, դր',
    Currency: 'Armenian dram',
    Country: 'Armenia',
    code: 'AMD',
  },
  {
    symbol: '₼',
    Currency: 'Azerbaijani manat',
    Country: 'Azerbaijan',
    code: 'AZN',
  },
  {
    symbol: '.د.ب',
    Currency: 'Bahraini dinar',
    Country: 'Bahrain',
    code: 'BHD',
  },
  {
    symbol: '€',
    Currency: 'Euro',
    Country: 'Cyprus',
    code: 'EUR',
  },
  {
    symbol: 'ლარი',
    Currency: 'Lari',
    Country: 'Georgia',
    code: 'GEL',
  },
  {
    symbol: 'ع.د',
    Currency: 'Iraqi dinar',
    Country: 'Iraq',
    code: 'IQD',
  },
  {
    symbol: '﷼',
    Currency: 'Iranian rial',
    Country: 'Iran',
    code: 'IRR',
  },
  {
    symbol: '₪',
    Currency: 'Israeli new shekel',
    Country: 'Israel',
    code: 'ILS',
  },
  {
    symbol: 'ينار',
    Currency: 'Jordanian dinar',
    Country: 'Jordan',
    code: 'JOD',
  },
  {
    symbol: 'ك',
    Currency: 'Kuwaiti dinar',
    Country: 'Kuwait',
    code: 'KWD',
  },
  {
    symbol: 'ل.ل',
    Currency: 'Lebanese pound',
    Country: 'Lebanon',
    code: 'LBP',
  },
  {
    symbol: '₪',
    Currency: 'Israeli shekel',
    Country: 'Palestine',
    code: 'ILS',
  },
  {
    symbol: '£S',
    Currency: 'Syrian pound',
    Country: 'Syria',
    code: 'SYP',
  },
  {
    symbol: 'AED',
    Currency: 'Emirati dirham',
    Country: 'United Arab Emirates (UAE)',
    code: 'AED',
  },
  {
    symbol: '₪',
    Currency: 'Israeli shekel',
    Country: 'Israel',
    code: 'ILS',
  },
  {
    symbol: 'ر.ع',
    Currency: 'Omani rial',
    Country: 'Oman',
    code: 'OMR',
  },
  {
    symbol: 'ر.ق',
    Currency: 'Qatari riyal',
    Country: 'Qatar',
    code: 'QAR',
  },
  {
    symbol: 'SR',
    Currency: 'Saudi riyal',
    Country: 'Saudi Arabia',
    code: 'SAR',
  },
  {
    symbol: '﷼',
    Currency: 'Yemeni rial',
    Country: 'Yemen',
    code: 'YER',
  },

  {
    symbol: 'FCFA',
    Currency: 'CFA franc',
    Country: 'West Africa',
    code: 'XAF',
  },
  {
    symbol: 'CFA',
    Currency: 'CFA franc',
    Country: 'Central Africa',
    code: 'XOF',
  },
  {
    symbol: 'دج',
    Currency: 'Algerian dinar',
    Country: 'Algeria',
    code: 'DZD',
  },
  {
    symbol: 'Kz',
    Currency: 'Angolan kwanza',
    Country: 'Angola',
    code: 'AOA',
  },
  {
    symbol: 'P',
    Currency: 'Botswana pula',
    Country: 'Botswana',
    code: 'BWP',
  },
  {
    symbol: 'FBu',
    Currency: 'Burundian franc',
    Country: 'Burundi',
    code: 'BIF',
  },
  {
    symbol: 'CVE',
    Currency: 'Cape Verdean escudo',
    Country: 'Cabo Verde',
    code: 'CVE',
  },
  {
    symbol: 'CF',
    Currency: 'Comorian franc',
    Country: 'Comoros',
    code: 'KMF',
  },
  {
    symbol: 'FC',
    Currency: 'Congolese franc',
    Country: 'Democratic Republic of the Congo',
    code: 'CDF',
  },
  {
    symbol: 'Fdj',
    Currency: 'Djiboutian franc',
    Country: 'Djibouti',
    code: 'DJF',
  },
  {
    symbol: 'E£',
    Currency: 'Egyptian pound',
    Country: 'Egypt',
    code: 'EGP',
  },
  {
    symbol: 'Nkf',
    Currency: 'Eritrean nakfa',
    Country: 'Eritrea',
    code: 'ERN',
  },
  {
    symbol: 'Br',
    Currency: 'Ethiopian birr',
    Country: 'Ethiopia',
    code: 'ETB',
  },
  {
    symbol: 'L',
    Currency: 'Lilangeni',
    Country: 'Eswatini',
    code: 'SZL',
  },
  {
    symbol: 'D',
    Currency: 'Dalasi',
    Country: 'Gambia',
    code: 'GMD',
  },
  {
    symbol: 'GH₵',
    Currency: 'Ghanaian cedi',
    Country: 'Ghana',
    code: 'GHS',
  },
  {
    symbol: 'FG',
    Currency: 'Guinean franc',
    Country: 'Guinea',
    code: 'GNF',
  },
  {
    symbol: 'KSh',
    Currency: 'Kenyan shilling',
    Country: 'Kenya',
    code: 'KES',
  },
  {
    symbol: 'L',
    Currency: 'Lesotho loti',
    Country: 'Lesotho',
    code: 'LSL',
  },
  {
    symbol: 'LD$',
    Currency: 'Liberian dollar',
    Country: 'Liberia',
    code: 'LRD',
  },
  {
    symbol: 'LD',
    Currency: 'Libyan dinar',
    Country: 'Libya',
    code: 'LYD',
  },
  {
    symbol: 'Ar',
    Currency: 'Malagasy ariary',
    Country: 'Madagascar',
    code: 'MGA',
  },
  {
    symbol: 'K',
    Currency: 'Malawian kwacha',
    Country: 'Malawi',
    code: 'MWK',
  },
  {
    symbol: '₨',
    Currency: 'Mauritian rupee',
    Country: 'Mauritius',
    code: 'MUR',
  },
  {
    symbol: 'UM',
    Currency: 'Ouguiya',
    Country: 'Mauritania',
    code: 'MRU',
  },
  {
    symbol: 'DH',
    Currency: 'Moroccan dirham',
    Country: 'Morocco',
    code: 'MAD',
  },
  {
    symbol: 'MT',
    Currency: 'Mozambican metical',
    Country: 'Mozambique',
    code: 'MZN',
  },
  {
    symbol: 'N$',
    Currency: 'Namibian dollar',
    Country: 'Namibia',
    code: 'NAD',
  },
  {
    symbol: '₦',
    Currency: 'Nigerian naira',
    Country: 'Nigeria',
    code: 'NGN',
  },
  {
    symbol: 'R₣',
    Currency: 'Rwandan franc',
    Country: 'Rwanda',
    code: 'RWF',
  },
  {
    symbol: 'Db',
    Currency: 'São Tomé and Príncipe dobra',
    Country: 'Sao Tome and Principe',
    code: 'STN',
  },
  {
    symbol: 'SR',
    Currency: 'Seychellois rupee',
    Country: 'Seychelles',
    code: 'SCR',
  },
  {
    symbol: 'Le',
    Currency: 'Sierra Leonean leone',
    Country: 'Sierra Leone',
    code: 'SLL',
  },
  {
    symbol: 'Sh.So.',
    Currency: 'Somali shilling',
    Country: 'Somalia',
    code: 'SOS',
  },
  {
    symbol: 'R',
    Currency: 'South African rand',
    Country: 'South Africa',
    code: 'ZAR',
  },
  {
    symbol: 'SS£',
    Currency: 'South Sudanese pound',
    Country: 'South Sudan',
    code: 'SSP',
  },
  {
    symbol: 'SDG',
    Currency: 'Sudanese pound',
    Country: 'Sudan',
    code: 'SDG',
  },
  {
    symbol: 'TSh',
    Currency: 'Tanzanian shilling',
    Country: 'Tanzania',
    code: 'TZS',
  },
  {
    symbol: 'د.ت',
    Currency: 'Tunisian dinar',
    Country: 'Tunisia',
    code: 'TND',
  },
  {
    symbol: 'USh',
    Currency: 'Ugandan shilling',
    Country: 'Uganda',
    code: 'UGX',
  },
  {
    symbol: '$',
    Currency: 'United States Dollar',
    Country: 'Zimbabwe',
    code: 'USD',
  },

  {
    symbol: 'A$',
    Currency: 'Australian dollar',
    Country: 'Australia',
    code: 'AUD',
  },
  {
    symbol: '৳',
    Currency: 'Bangladeshi taka',
    Country: 'Bangladesh',
    code: 'BDT',
  },
  {
    symbol: 'Nu',
    Currency: 'Bhutanese ngultrum',
    Country: 'Bhutan',
    code: 'BTN',
  },
  {
    symbol: 'B$',
    Currency: 'Brunei dollar',
    Country: 'Brunei',
    code: 'BND',
  },
  {
    symbol: '៛',
    Currency: 'Cambodian riel',
    Country: 'Cambodia',
    code: 'KHR',
  },
  {
    symbol: '¥ /元',
    Currency: 'Chinese yuan',
    Country: 'China',
    code: 'CNY',
  },
  {
    symbol: "$ / HK$ / '元'",
    Currency: 'Hong Kong dollar',
    Country: 'Hong Kong',
    code: 'HKD',
  },
  {
    symbol: 'Rp',
    Currency: 'Indonesian rupiah',
    Country: 'Indonesia',
    code: 'IDR',
  },
  {
    symbol: '₹',
    Currency: 'Indian rupee',
    Country: 'India',
    code: 'INR',
  },
  {
    symbol: '¥',
    Currency: 'Japanese yen',
    Country: 'Japan',
    code: 'JPY',
  },
  {
    symbol: '₸',
    Currency: 'Kazakhstani tenge',
    Country: 'Kazakhstan',
    code: 'KZT',
  },
  {
    symbol: 'som',
    Currency: 'Kyrgyzstani som',
    Country: 'Kyrgyzstan',
    code: 'KGS',
  },
  {
    symbol: '₭',
    Currency: 'Lao kip',
    Country: 'Laos',
    code: 'LAK',
  },
  {
    symbol: 'MOP$',
    Currency: 'Macanese pataca',
    Country: 'Macau',
    code: 'MOP',
  },
  {
    symbol: 'RM',
    Currency: 'Malaysian ringgit',
    Country: 'Malaysia',
    code: 'MYR',
  },
  {
    symbol: 'MRf',
    Currency: 'Maldivian rufiyaa',
    Country: 'Maldives',
    code: 'MVR',
  },
  {
    symbol: '₮',
    Currency: 'Mongolian tögrög',
    Country: 'Mongolia',
    code: 'MNT',
  },
  {
    symbol: 'K',
    Currency: 'Kyat',
    Country: 'Myanmar',
    code: 'MMK',
  },
  {
    symbol: 'Rs',
    Currency: 'Nepalese rupee',
    Country: 'Nepal',
    code: 'NPR',
  },
  {
    symbol: '$',
    Currency: 'New Zealand dollar',
    Country: 'New Zealand',
    code: 'NZD',
  },
  {
    symbol: '₩',
    Currency: 'North Korean won',
    Country: 'North Korea',
    code: 'KPW',
  },
  {
    symbol: 'Rs',
    Currency: 'Pakistani rupee',
    Country: 'Pakistan',
    code: 'PKR',
  },
  {
    symbol: '₱',
    Currency: 'Philippine peso',
    Country: 'Philippines',
    code: 'PHP',
  },
  {
    symbol: 'S$',
    Currency: 'Singapore dollar',
    Country: 'Singapore',
    code: 'SGD',
  },
  {
    symbol: '₩',
    Currency: 'South Korean won',
    Country: 'South Korea',
    code: 'KRW',
  },
  {
    symbol: 'Rs',
    Currency: 'Sri Lankan rupee',
    Country: 'Sri Lanka',
    code: 'LKR',
  },
  {
    symbol: 'NT$',
    Currency: 'New Taiwan dollar',
    Country: 'Taiwan',
    code: 'TWD',
  },
  {
    symbol: 'TJS',
    Currency: 'Somoni',
    Country: 'Tajikistan',
    code: 'TJS',
  },
  {
    symbol: 'US$',
    Currency: 'US Dollar',
    Country: 'Timor-Leste',
    code: 'USD',
  },
  {
    symbol: '฿',
    Currency: 'Thai baht',
    Country: 'Thailand',
    code: 'THB',
  },
  {
    symbol: 'm',
    Currency: 'Turkmen new manat',
    Country: 'Turkmenistan',
    code: 'TMT',
  },
  {
    symbol: 'som',
    Currency: 'Uzbekistan som',
    Country: 'Uzbekistan',
    code: 'UZS',
  },
  {
    symbol: '₫',
    Currency: 'Vietnamese đồng',
    Country: 'Vietnam',
    code: 'VND',
  },
  {
    symbol: '₿',
    Currency: 'Bitcoin',
    code: 'BTC or XBT',
  },
  {
    symbol: 'Ξ',
    Currency: 'Ethereum',
    code: 'ETH',
  },
  {
    symbol: 'Ł',
    Currency: 'Litecoin',
    code: 'LTC',
  },
  {
    symbol: 'ɱ',
    Currency: 'Monero',
    code: 'XMR',
  },
  {
    symbol: 'XRP',
    Currency: 'Ripples',
    code: 'XRP',
  },
  {
    symbol: '₮',
    Currency: 'Tether',
    code: 'USDT',
  },
]
