import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import Card from 'components/card'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { useToaster } from 'common/Toaster'
import CrawfordConstants from 'common/config/crawfordConstants'
import { useSpinner } from 'common/SpinnerLoader'
import { useParams, useNavigate } from 'react-router-dom'
import InputField from 'components/fields/InputField'
import AddressComponent from 'components/addressComponent/addressComponent'
const apiService = ApiCaller()
const { TOAST } = CrawfordConstants

/**
 * Renders a form to add or edit a function.
 *
 * @return {JSX.Element} The rendered form component.
 */
const AddInvestigator = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      InvestigatorName: '',
    },
  })
  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()

  /**
   * Handles the form submission to create or update an investigator.
   *
   * @async
   * @function onFormSubmit
   * @param {Object} formData - The form data object containing investigator details.
   * @param {string} formData.InvestigatorName - The name of the investigator.
   * @param {Object} formData.City - An object containing the city information.
   * @param {string} formData.City.value - The city value associated with the investigator.
   * @param {string} formData.Address - The address of the investigator.
   * @param {string} formData.PostalCode - The postal code for the investigator's location.
   * @param {string} formData.Latitude - The latitude coordinate of the investigator's address.
   * @param {string} formData.Longitude - The longitude coordinate of the investigator's address.
   * @param {string} formData.PhoneNumber1 - The primary phone number of the investigator.
   * @param {string} [formData.PhoneNumber2] - The secondary phone number of the investigator.
   * @param {string} formData.EmailId - The investigator's email address.
   *
   * @returns {Promise<void>} Nothing is returned but navigation or toast notifications may be triggered based on success/failure.
   *
   * @throws Will display an error toast if the API request fails.
   */
  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.INVESTIGATOR_CREATE
      const payload = {
        _id: id ? id : undefined,
        name: formData.InvestigatorName,
        city: formData.City.value,
        address: formData.Address,
        pincode: formData.PostalCode,
        lat: formData.Latitude,
        lng: formData.Longitude,
        location: [formData.Longitude, formData.Latitude],
        phone1: formData.PhoneNumber1,
        phone2: formData.PhoneNumber2,
        email: formData.EmailId,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        addToast({
          title: `Investigator "${formData.InvestigatorName}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        navigate(`/employee/investigator-list`)
      } else {
        addToast({
          title: `Investigator creation failed.`,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  /**
   * Fetches the details of a specific function and sets the form values.
   *
   * @async
   * @function getInvestigatorDetails
   * @returns {Promise<void>} A promise that resolves when the function details are fetched and the form values are set.
   */
  const getInvestigatorDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.INVESTIGATOR_DETAIL.replace(':id', id)
      const payload = {
        _id: id,
        checkRoles: false,
        accessOf: ['InvestigatorEdit'],
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data === 'Access Denied') {
        addToast({
          title: 'Access Denied',
          type: 'error',
        })
        navigate(`/employee/investigator-list`)
      } else if (response?.data?.value) {
        setValue('InvestigatorName', response?.data?.data?.name || '')
        setValue('City', {
          label: response?.data?.data?.city?.name,
          value: response?.data?.data?.city?._id,
        })
        setValue('Address', response?.data?.data?.address || '')
        setValue('PostalCode', response?.data?.data?.pincode || '')
        setValue('Latitude', response?.data?.data?.lat || '')
        setValue('Longitude', response?.data?.data?.lng || '')
        setValue('PhoneNumber1', response?.data?.data?.phone1 || '')
        setValue('PhoneNumber2', response?.data?.data?.phone2 || '')
        setValue('EmailId', response?.data?.data?.email || '')
      } else {
        addToast({
          title: 'Some Error Occured',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error verifying token:', error)
    } finally {
      hideSpinner()
    }
  }

  const handleCancel = () => {
    navigate(`/employee/investigator-list`)
  }

  useEffect(() => {
    if (id) {
      getInvestigatorDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
      <div className="mt-[25px]">
        <p className="text-xl font-bold text-navy-700 dark:text-white">
          {id ? 'Edit' : 'Add'} Investigator
        </p>
      </div>

      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="mt-7 grid grid-cols-1 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Investigator Name"
              id="InvestigatorName"
              type="text"
              placeholder="Enter investigator name"
              registrationProps={register('InvestigatorName', {
                required: 'Investigator name is required',
              })}
              isFieldRequired={true}
              state={errors.InvestigatorName && 'error'}
            />
            <div className="error-message text-right">
              {errors.InvestigatorName?.message}
            </div>
          </div>
        </div>
        <div className="mt-7 grid grid-cols-2 gap-3">
          <AddressComponent
            control={control}
            register={register}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
          />

          <div className="col-span-2 lg:col-span-1">
            <h4 className="text-md mb-2 font-bold text-gray-700">Contact</h4>
            <InputField
              extra="mb-2 w-full"
              label="Phone Number 1"
              id="PhoneNumber1"
              type="text"
              placeholder="Enter phone number"
              registrationProps={register('PhoneNumber1')}
            />

            <InputField
              extra="mb-2 w-full"
              label="Phone Number 2"
              id="PhoneNumber2"
              type="text"
              placeholder="Enter phone number"
              registrationProps={register('PhoneNumber2')}
            />

            <InputField
              extra="mb-2 w-full"
              label="Email Id"
              id="EmailId"
              type="text"
              placeholder="Enter email id"
              registrationProps={register('EmailId')}
            />
          </div>
        </div>
        {/* Button aligned to the right */}
        <div className="flex w-full justify-end">
          <button
            className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
            type="submit"
          >
            <span>{id ? 'Update' : 'Save'}</span>
          </button>

          <button
            className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base focus:outline-none focus:ring-2 focus:ring-gray-700 active:ring-2 active:ring-gray-500`}
            type="button"
            onClick={handleCancel}
          >
            <span>Cancel</span>
          </button>
        </div>
      </form>
    </Card>
  )
}

export default AddInvestigator
