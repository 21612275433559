import React, { useState, useEffect } from 'react'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import Card from 'components/card'
import { useForm, Controller } from 'react-hook-form'
import { useToaster } from 'common/Toaster'
import CrawfordConstants from 'common/config/crawfordConstants'
import { useSpinner } from 'common/SpinnerLoader'
import { useParams, useNavigate } from 'react-router-dom'
import Radio from 'components/radio'
import InputField from 'components/fields/InputField'
import Select from 'react-select'
import SwitchWithText from 'components/switch-with-text'
import {
  getUserInfo,
  dropDownStyleWithErrorAndFocus,
  dropDownStyleWithoutErrorAndFocus,
} from 'common/commonFunction'
import AddressComponent from 'components/addressComponent/addressComponent'
const apiService = ApiCaller()
const { TOAST } = CrawfordConstants
/**
 * Renders a form to add or edit a district.
 *
 * @return {JSX.Element} The rendered form component.
 */
const AddCustomer = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      ReportingTo: '',
    },
  })
  const [allowBilling, setAllowBilling] = useState(true)
  const [showInactive, setShowInactive] = useState(true)

  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()
  const [options, setOptions] = useState([])
  const [typeOfOfficeOptions, setTypeOfOfficeOptions] = useState([])
  const [reportingToOptions, setReportingOptions] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [customerShortNameCopy, setCustomerShortNameCopy] = useState('')

  const [isCustomerActive, setCustomerStatus] = useState(true)
  const [isIndividualCustomer, setIndividualCustomer] = useState(false)

  const [selectedCategory, setSelectedCategory] = useState('') // State to hold the selected category
  const [branchShortName, setBranchShortName] = useState('')
  const [customerSegment, setCustomerSegment] = useState('')

  const [selectedCity, setSelectedCity] = useState('')

  setValue('CreditLimitExhausted', 0)
  setValue('CreditLimitPending', 0)

  // Function to handle category change
  const handleCategoryChange = (category) => {
    setSelectedCategory(category)
  }

  const changeAllowBilling = () => {
    setAllowBilling(!allowBilling)
  }

  const changeShowInactive = () => {
    setShowInactive(!showInactive)
  }

  const changeCustomerStatus = () => {
    setCustomerStatus(!isCustomerActive)
  }

  const changeCustomerType = () => {
    setIndividualCustomer(!isIndividualCustomer)
  }

  const getCustomerCompanyDetails = async (
    companyId,
    typeOfOfficeId = '',
    officeCode = '',
    city = ''
  ) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.CUSTOMER_COMPANY_DETAIL.replace(':id', companyId)
      const payload = {
        _id: companyId,
        checkRoles: false,
        accessOf: [],
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data === 'Access Denied') {
        addToast({
          title: 'Access Denied',
          type: 'error',
        })
        navigate(`/customers/customer-list`)
      } else if (response?.data?.value) {
        const customerCompanyData = response?.data?.data
        setCustomerSegment(customerCompanyData?.customerSegment)
        setCustomerShortNameCopy(customerCompanyData?.shortName)
        setValue('ShortName', customerCompanyData?.shortName)
        if (
          customerCompanyData?.shortName ||
          typeOfOfficeId ||
          getValues('TypeOfOffice')?.value
        ) {
          getOneTypeOfOffice(
            customerCompanyData?.shortName || '',
            typeOfOfficeId || getValues('TypeOfOffice')?.value,
            officeCode || getValues('OfficeCode'),
            city || ''
          )
        }
      } else {
        addToast({
          title: 'Some Error Occured',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error verifying token:', error)
    } finally {
      hideSpinner()
    }
  }

  // Fetch data from API
  const fetchOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.CUSTOMER_COMPANY_LIST
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: {},
        fields: [],
        page: inputValue ? 1 : 0,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  // Handle input change and trigger API call
  const handleInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchOptions(inputValue)
    }
  }

  const handleSelectionChange = (CustomerCompany) => {
    if (!CustomerCompany) {
      fetchOptions() // Call the function to perform API request
      setOptions([])
    } else {
      getCustomerCompanyDetails(CustomerCompany?.value)
    }
  }

  const setCustomerNameWithCodeAddLocation = (
    customerShortName,
    branchShortName = '',
    selectedCity = ''
  ) => {
    const value = `${customerShortName || ''}  ${branchShortName || ''}  ${
      getValues('OfficeCode') || ''
    }  ${selectedCity || ''}`
    setValue('ShortName', value)
  }

  // Fetch data from API
  const fetchTypeOfOfficeOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.TYPE_OF_OFFICE_LIST
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: {},
        fields: [],
        page: inputValue ? 1 : 0,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
          shortName: item.shortCode,
        }))
        setTypeOfOfficeOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  // Handle input change and trigger API call
  const handleTypeOfOfficeInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchTypeOfOfficeOptions(inputValue)
    }
  }

  const handleTypeOfOfficeSelectionChange = (TypeOfOffice) => {
    if (!TypeOfOffice) {
      fetchTypeOfOfficeOptions() // Call the function to perform API request
      setTypeOfOfficeOptions([])
    } else {
      setBranchShortName(TypeOfOffice.shortName)
      const value = `${customerShortNameCopy}  ${TypeOfOffice.shortName}  ${
        getValues('OfficeCode') || ''
      }  ${selectedCity || ''}`
      setValue('ShortName', value)
    }
  }

  // Fetch data from API
  const fetchReportingOptions = async () => {
    if (getValues('CustomerCompany')?.value) {
      try {
        setIsLoading(true)
        const apiUrl = apiConfig.CUSTOMER_LIST
        const payload = {
          keyword: '',
          filter: {
            customerCompany: getValues('CustomerCompany')?.value,
          },
          fields: [],
          page: 1,
        }
        const response = await apiService.apiCall('post', apiUrl, payload)
        if (response?.data?.value) {
          const newOptions = response?.data?.data?.results.map((item) => ({
            label: item.name,
            value: item._id,
          }))
          setReportingOptions(newOptions)
        }
      } catch (error) {
        console.error('Error fetching options:', error)
      } finally {
        setIsLoading(false)
      }
    } else {
      addToast({
        title: 'Please select customer company',
        type: 'warning',
      })
    }
  }

  // Handle input change and trigger API call
  const handleReportingInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchReportingOptions(inputValue)
    }
  }

  const handleReportingSelectionChange = (ReportingTo) => {
    if (!ReportingTo) {
      fetchReportingOptions() // Call the function to perform API request
      setReportingOptions([])
    }
  }

  // Fetch data from API
  const getOneTypeOfOffice = async (
    customerShortName = '',
    typeOfOfficeId,
    officeCode = '',
    city = ''
  ) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.TYPE_OF_OFFICE_DETAIL
      const payload = {
        _id: typeOfOfficeId,
        checkRoles: false,
        accessOf: ['typeOfOfficeEdit'],
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        setBranchShortName(response?.data?.data?.shortCode)
        const value = `${customerShortName}  ${response?.data?.data?.shortCode}`
        setValue('ShortName', value)
        if (city || officeCode) {
          setCustomerNameWithCodeAddLocation(
            customerShortName,
            response?.data?.data?.shortCode,
            city
          )
        }
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSelectCity = (city) => {
    setSelectedCity(city.cityName)
    const value = `${customerShortNameCopy}   ${branchShortName}   ${getValues(
      'OfficeCode'
    )} ${city.cityName}`
    setValue('ShortName', value)
  }

  /**
   * Handles form submission for creating or editing a customer.
   * Gathers form data, prepares a payload for API submission,
   * and sends the data to create or update a customer record.
   * Displays success or error messages based on the API response.
   *
   * @param {Object} formData - The data collected from the form.
   * @param {Object} formData.CustomerCompany - The selected customer company.
   * @param {string} formData.CustomerCompany.label - The customer company short name.
   * @param {string} formData.CustomerCompany.value - The customer company ID or value.
   * @param {string} formData.OfficeCode - The office code associated with the customer.
   * @param {Object} formData.City - The city information.
   * @param {string} formData.City.label - The city name.
   * @param {string} formData.City.value - The city ID or value.
   * @param {string} formData.ShortName - The short name of the customer.
   * @param {string} formData.CreditLimitExhausted - The credit limit exhausted value.
   * @param {string} formData.CreditLimitPending - The credit limit pending value.
   * @param {Object} formData.TypeOfOffice - The type of office information.
   * @param {string} formData.TypeOfOffice.label - The type of office short name.
   * @param {string} formData.TypeOfOffice.value - The type of office ID or value.
   * @param {Object} formData.ReportingTo - The reporting office information.
   * @param {string} formData.ReportingTo.value - The reporting office ID or value.
   * @param {string} formData.CreditLimitAlloted - The credit limit allotted to the customer.
   * @param {string} formData.Address - The customer's address.
   * @param {string} formData.PostalCode - The postal code (pincode) of the customer's location.
   * @param {string} formData.formattedAddress - The formatted address of the customer's location.
   * @param {string} formData.Latitude - The latitude of the customer's location.
   * @param {string} formData.Longitude - The longitude of the customer's location.
   * @param {string} formData.DirectNumber - The customer's direct contact number.
   * @param {string} formData.PhoneNumber1 - The primary phone number of the customer.
   * @param {string} formData.PhoneNumber2 - The secondary phone number of the customer.
   * @param {string} formData.EmailId - The customer's email address.
   * @param {boolean} isIndividualCustomer - Indicates if the customer is an individual.
   * @param {boolean} allowBilling - Indicates if billing is allowed for this customer.
   * @param {string} branchShortName - The short name for the branch.
   * @param {string} selectedCategory - The category selected for the customer (e.g., Active, Prospective).
   * @param {string} id - The unique ID of the customer if editing an existing record.
   * @param {Object} userInfo - Information about the logged-in user.
   * @param {string} userInfo.employee - The employee ID of the user approving the customer.
   * @param {string} userInfo.email - The email of the logged-in user.
   * @param {string} userInfo.name - The name of the logged-in user.
   *
   * @returns {Promise<void>} - A promise that resolves when the form submission process completes.
   */
  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      const userInfo = getUserInfo()
      const apiUrl = apiConfig.CUSTOMER_CREATE
      const payload = {
        _id: id ? id : undefined,
        typeOfCustomer: isIndividualCustomer,
        allowBilling: allowBilling,
        officers: [],
        companyShortName: formData?.CustomerCompany?.label,
        TOFShortName1: branchShortName,
        officeCode: formData.OfficeCode,
        city1: formData?.City?.label,
        name: formData.ShortName,
        creditLimitExhausted: formData.CreditLimitExhausted,
        creditLimitPending: formData.CreditLimitPending,
        customerCompany: formData?.CustomerCompany?.value,
        typeOfOffice: formData?.TypeOfOffice?.value,
        TOFShortName: formData?.TypeOfOffice?.label,
        reportingTo: formData?.ReportingTo?.value,
        creditLimitAlloted: formData.CreditLimitAlloted,
        city: formData?.City?.value,
        address: formData.Address,
        pincode: formData.PostalCode,
        formatted_address: formData.formattedAddress,
        lat: formData.Latitude,
        lng: formData.Longitude,
        category: selectedCategory,
        direct: formData.DirectNumber,
        phone1: formData.PhoneNumber1,
        phone2: formData.PhoneNumber2,
        email: formData.EmailId,
        customerSegment: customerSegment._id,
        approvedBy: userInfo.employee,
        users: {
          email: userInfo.email,
          name: userInfo.name,
        },
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        addToast({
          title: `Customer "${formData.CustomerCompany.label}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        navigate(`/customers/customer-list`)
      } else {
        addToast({
          title: `Customer creation failed.`,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  const getFormattedName = (data) => {
    const {
      city: {
        name: cityName,
        _id: cityId,
        district: {
          name: districtName,
          state: {
            name: stateName,
            zone: { name: zoneName, country: { name: countryName } = {} } = {},
          } = {},
        } = {},
      } = {},
    } = data
    return {
      label: `${cityName}, ${districtName}, ${stateName}, ${zoneName}, ${countryName}`,
      value: cityId,
    }
  }

  /**
   * Fetches the details of a specific company and sets the form values.
   *
   * @async
   * @function getCompanyDetails
   * @returns {Promise<void>} A promise that resolves when the company details are fetched and the form values are set.
   */
  const getCompanyDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.CUSTOMER_DETAIL.replace(':id', id)
      const payload = {
        _id: id,
        checkRoles: false,
        accessOf: ['CustomerEdit'],
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data === 'Access Denied') {
        addToast({
          title: 'Access Denied',
          type: 'error',
        })
        navigate(`/customers/customer-list`)
      } else if (response?.data?.value) {
        const customerData = response?.data?.data
        setCustomerShortNameCopy(customerData?.name)
        setValue('OfficeCode', customerData?.officeCode)
        setSelectedCity(customerData?.city?.name)
        getCustomerCompanyDetails(
          customerData?.customerCompany?._id,
          customerData?.typeOfOffice?._id,
          customerData?.officeCode,
          customerData?.city?.name
        )
        setValue('CustomerCompany', {
          label: customerData?.customerCompany?.name,
          value: customerData?.customerCompany?._id,
        })
        setValue('CreditLimitExhausted', customerData?.creditLimitExhausted)
        setValue('CreditLimitPending', customerData?.creditLimitPending)
        setValue('CreditLimitAlloted', customerData?.creditLimitAlloted)
        setValue('City', getFormattedName(customerData))
        setValue('Address', customerData?.address)
        setValue('PostalCode', customerData?.pincode)
        setValue('Latitude', customerData?.lat)
        setValue('Longitude', customerData?.lng)
        setValue('DirectNumber', customerData?.direct)
        setValue('PhoneNumber1', customerData?.phone1)
        setValue('PhoneNumber2', customerData?.phone2)
        setValue('EmailId', customerData?.email)
        setValue('ReportingTo', {
          label: customerData?.reportingTo?.name,
          value: customerData?.reportingTo?._id,
        })
        setValue('TypeOfOffice', {
          label: customerData?.typeOfOffice?.name,
          value: customerData?.typeOfOffice?._id,
        })
        setAllowBilling(customerData?.allowBilling)
        setShowInactive(customerData?.showInactive)
        setCustomerStatus(customerData?.status)
        setIndividualCustomer(customerData?.typeOfCustomer)
        setSelectedCategory(customerData?.category)
      } else {
        addToast({
          title: 'Some Error Occured',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error verifying token:', error)
    } finally {
      hideSpinner()
    }
  }

  const handleCancel = () => {
    navigate(`/customers/customer-list`)
  }

  useEffect(() => {
    if (id) {
      getCompanyDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="mt-7 grid grid-cols-2 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <p className="text-xl font-bold text-navy-700">
              {id ? 'Edit' : 'Add'} Customer
            </p>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <div className="flex w-full justify-end">
              <SwitchWithText
                textLeft="OFF"
                textRight="ON"
                checked={isCustomerActive}
                onChange={changeCustomerStatus}
              />
            </div>
          </div>
        </div>

        <div className="mt-4 grid grid-cols-2 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <div className="mb-2 flex items-center justify-between">
              <label className="w-1/3 text-sm font-bold">
                Type Of Customer
              </label>
              <div className="flex w-2/3 justify-end">
                <SwitchWithText
                  textLeft="Company"
                  textRight="Individual"
                  checked={isIndividualCustomer}
                  onChange={changeCustomerType}
                  extraClass="after:w-[80px] peer-checked:after:left-6"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-2 grid grid-cols-2 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-4 block text-sm font-bold">
              Customer Company <span className="text-red-500">*</span>
            </label>
            <Controller
              name="CustomerCompany"
              control={control}
              rules={{ required: 'Customer company is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={options}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handleInputChange}
                  onFocus={() => fetchOptions()}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(CustomerCompany) => {
                    field.onChange(CustomerCompany)
                    handleSelectionChange(CustomerCompany) // Call a function to handle API call
                  }}
                  styles={dropDownStyleWithErrorAndFocus(error)}
                />
              )}
            />
            {errors.CustomerCompany && (
              <div className="error-message text-right text-red-500">
                {errors.CustomerCompany.message}
              </div>
            )}
          </div>
          {!isIndividualCustomer && (
            <>
              <div className="col-span-2 lg:col-span-1">
                <label className="mb-4 block text-sm font-bold">
                  Type Of Office <span className="text-red-500">*</span>
                </label>
                <Controller
                  name="TypeOfOffice"
                  control={control}
                  rules={{ required: 'Type of office is required' }}
                  render={({ field, fieldState: { error } }) => (
                    <Select
                      {...field}
                      options={typeOfOfficeOptions}
                      placeholder="Search and select..."
                      classNamePrefix="select"
                      isClearable
                      isLoading={isLoading}
                      onInputChange={handleTypeOfOfficeInputChange}
                      onFocus={() => fetchTypeOfOfficeOptions()}
                      noOptionsMessage={() => 'Type to search...'}
                      onChange={(TypeOfOffice) => {
                        field.onChange(TypeOfOffice)
                        handleTypeOfOfficeSelectionChange(TypeOfOffice) // Call a function to handle API call
                      }}
                      styles={dropDownStyleWithErrorAndFocus(error)}
                    />
                  )}
                />
                {errors.TypeOfOffice && (
                  <div className="error-message text-right text-red-500">
                    {errors.TypeOfOffice.message}
                  </div>
                )}
              </div>
              <div className="col-span-2 lg:col-span-1">
                <InputField
                  extra="mb-2 w-full"
                  label="Customer Short Name"
                  id="ShortName"
                  type="text"
                  placeholder="Enter short name"
                  registrationProps={register('ShortName', {
                    required: 'Short name is required',
                  })}
                  isFieldRequired={true}
                  state={errors.ShortName && 'error'}
                  readOnly={true}
                />
                <div className="error-message text-right">
                  {errors.ShortName?.message}
                </div>
              </div>
              <div className="col-span-2 lg:col-span-1">
                <InputField
                  extra="mb-2 w-full"
                  label="Office Code"
                  id="OfficeCode"
                  type="text"
                  placeholder="Enter customer code"
                  registrationProps={register('OfficeCode', {
                    required: 'Office code is required',
                  })}
                  isFieldRequired={true}
                  state={errors.OfficeCode && 'error'}
                  onKeyUp={() => {
                    setCustomerNameWithCodeAddLocation(
                      customerShortNameCopy,
                      branchShortName,
                      selectedCity
                    )
                  }}
                />
                <div className="error-message text-right">
                  {errors.OfficeCode?.message}
                </div>
              </div>
              <div className="col-span-2 lg:col-span-1">
                <label className="w-1/3 text-sm font-bold">
                  Category <span className="text-red-500">*</span>
                </label>
                <div className="mt-4 flex">
                  <div className="flex w-full items-center">
                    <Radio
                      name="category" // Same name for grouping radio buttons
                      value="Active"
                      id="active"
                      checked={selectedCategory === 'Active'}
                      {...register('category', {
                        required: !selectedCategory
                          ? 'Please select a category'
                          : false,
                      })}
                      onChange={() => {
                        setSelectedCategory('Active')
                        handleCategoryChange('Active')
                      }}
                      className="focus:ring-2 focus:ring-blue-500 focus:outline-none checked:bg-blue-500"
                    />
                    <label htmlFor="active" className="ml-2">
                      Active
                    </label>
                  </div>

                  <div className="flex w-full items-center">
                    <Radio
                      name="category" // Same name for grouping radio buttons
                      value="Prospective"
                      id="prospective"
                      checked={selectedCategory === 'Prospective'}
                      {...register('category', {
                        required: !selectedCategory
                          ? 'Please select a category'
                          : false,
                      })}
                      onChange={() => {
                        setSelectedCategory('Prospective')
                        handleCategoryChange('Prospective')
                      }}
                      className="focus:ring-2 focus:ring-blue-500 focus:outline-none checked:bg-blue-500"
                    />
                    <label htmlFor="prospective" className="ml-2">
                      Prospective
                    </label>
                  </div>
                </div>

                {/* Display error message if validation fails */}
                {errors.category && !selectedCategory && (
                  <div className="mt-2 text-sm text-red-500">
                    {errors.category.message}
                  </div>
                )}
              </div>
              <div className="col-span-2 lg:col-span-1">
                <label className="mb-4 block text-sm font-bold">
                  Reporting To
                </label>
                <Controller
                  name="ReportingTo"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={reportingToOptions}
                      placeholder="Search and select..."
                      classNamePrefix="select"
                      isClearable
                      isLoading={isLoading}
                      onInputChange={handleReportingInputChange}
                      onFocus={() => fetchReportingOptions()}
                      noOptionsMessage={() => 'Type to search...'}
                      onChange={(ReportingTo) => {
                        field.onChange(ReportingTo)
                        handleReportingSelectionChange(ReportingTo) // Call a function to handle API call
                      }}
                      styles={dropDownStyleWithoutErrorAndFocus()}
                    />
                  )}
                />
              </div>
            </>
          )}

          {!isIndividualCustomer && (
            <>
              <div className="col-span-2 lg:col-span-1">
                <div className="mt-4 flex items-center justify-between">
                  <label className="w-1/3 text-sm font-bold">
                    Allow Billing
                  </label>
                  <div className="flex w-2/3 justify-end">
                    <SwitchWithText
                      textLeft="OFF"
                      textRight="ON"
                      checked={allowBilling}
                      onChange={changeAllowBilling}
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-2 lg:col-span-1">
                <div className="mt-4 flex items-center justify-between">
                  <label className="w-1/3 text-sm font-bold">
                    Show InActive Customer Assignment
                  </label>
                  <div className="flex w-2/3 justify-end">
                    <SwitchWithText
                      textLeft="OFF"
                      textRight="ON"
                      checked={showInactive}
                      onChange={changeShowInactive}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {!isIndividualCustomer && (
          <div className="mt-6 grid grid-cols-1 gap-3">
            <h4 className="text-md font-bold text-gray-700">Credit Limit</h4>
            <InputField
              extra="mb-2 w-full"
              label="Credit Limit Alloted"
              id="CreditLimitAlloted"
              type="text"
              placeholder="Enter credit limit alloted"
              registrationProps={register('CreditLimitAlloted')}
            />

            <InputField
              extra="mb-2 w-full"
              label="Credit Limit Exhausted"
              id="CreditLimitExhausted"
              type="text"
              placeholder="Enter credit limit exhausted"
              registrationProps={register('CreditLimitExhausted')}
              readOnly
            />

            <InputField
              extra="mb-2 w-full"
              label="Credit Limit Pending"
              id="CreditLimitPending"
              type="text"
              placeholder="Enter credit limit pending"
              registrationProps={register('CreditLimitPending')}
              readOnly
            />
          </div>
        )}

        <div className="mt-7 grid grid-cols-2 gap-3">
          <AddressComponent
            control={control}
            register={register}
            setValue={setValue}
            getValues={getValues}
            onSelectCity={handleSelectCity}
            errors={errors}
          />

          <div className="col-span-2 lg:col-span-1">
            <h4 className="text-md mb-2 font-bold text-gray-700">Contact</h4>
            <InputField
              extra="mb-2 w-full"
              label="Direct Number"
              id="DirectNumber"
              type="text"
              placeholder="Enter direct number"
              registrationProps={register('DirectNumber')}
            />

            <InputField
              extra="mb-2 w-full"
              label="Phone Number 1"
              id="PhoneNumber1"
              type="text"
              placeholder="Enter phone number"
              registrationProps={register('PhoneNumber1')}
            />

            <InputField
              extra="mb-2 w-full"
              label="Phone Number 2"
              id="PhoneNumber2"
              type="text"
              placeholder="Enter phone number"
              registrationProps={register('PhoneNumber2')}
            />

            <InputField
              extra="mb-2 w-full"
              label="Email Id"
              id="EmailId"
              type="text"
              placeholder="Enter email id"
              registrationProps={register('EmailId')}
            />
          </div>
        </div>

        {/* Button aligned to the right */}
        <div className="mt-4 flex w-full justify-end">
          <button
            className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
            type="submit"
          >
            <span>{id ? 'Update' : 'Save'}</span>
          </button>

          <button
            className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base focus:outline-none focus:ring-2 focus:ring-gray-700 active:ring-2 active:ring-gray-500`}
            type="button"
            onClick={handleCancel}
          >
            <span>Cancel</span>
          </button>
        </div>
      </form>
    </Card>
  )
}

export default AddCustomer
