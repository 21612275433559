import React, { useState, useCallback } from 'react'
import { Controller } from 'react-hook-form'
import MapComponent from 'components/map/mapComponent'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import debounce from 'lodash.debounce'
import InputField from 'components/fields/InputField'
import Select from 'react-select'
const googleKey = process.env.REACT_APP_GOOGLE_KEY
const apiService = ApiCaller()
const AddressComponent = ({
  control,
  register,
  setValue,
  getValues,
  errors,
  onSelectCity,
  addressType,
  h4ClassName,
}) => {
  const [location, setLocation] = useState({ lat: '', lng: '' })
  const [formattedAddress, setFormattedAddress] = useState()
  const [cityOptions, setCityOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  // Fetch data from API
  const fetchCityOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.COMPANY_CITY_LIST
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: {},
        fields: [],
        page: inputValue ? 1 : 0,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
          cityName: item.city,
        }))
        setCityOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  // Handle input change and trigger API call
  const handleCityInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchCityOptions(inputValue)
    }
  }

  const handleCitySelectionChange = (City) => {
    if (!City) {
      fetchCityOptions() // Call the function to perform API request
      setCityOptions([])
    } else {
      if (onSelectCity) {
        onSelectCity(City)
      }
    }
  }

  const getGeoLocationInfo = async (address) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${googleKey}`
    )
      .then((response) => {
        if (!response.ok) {
          setValue('Latitude', '')
          setValue('Longitude', '')
        }
        return response.json()
      })
      .then((data) => {
        setLocation(data.results[0]?.geometry?.location || '')
        setFormattedAddress(data.results[0]?.formatted_address || '')
        setValue('formattedAddress', data.results[0]?.formatted_address || '')
        setValue(
          addressType === 'Home'
            ? 'HomeLatitude'
            : addressType === 'Office'
              ? 'OfficeLatitude'
              : 'Latitude',
          data.results[0]?.geometry?.location?.lat || ''
        )
        setValue(
          addressType === 'Home'
            ? 'HomeLongitude'
            : addressType === 'Office'
              ? 'OfficeLongitude'
              : 'Longitude',
          data.results[0]?.geometry?.location?.lng || ''
        )
      })
      .catch((error) => {
        console.error('Error verifying token:', error)
      })
  }

  const debouncedKeyUp = useCallback(
    debounce((value) => {
      getGeoLocationInfo(value)
    }, 1000),
    []
  )

  return (
    <div className="col-span-2 lg:col-span-1">
      <h4 className={`text-md mb-2 font-bold text-gray-700 ${h4ClassName}`}>
        {addressType === 'Office' ? 'Site' : addressType} Address
      </h4>
      <label className="mb-4 block text-sm font-bold">
        {addressType === 'Office' ? 'Site' : addressType} City{' '}
        <span className="text-red-500">*</span>
      </label>
      <Controller
        name={
          addressType === 'Home'
            ? 'HomeCity'
            : addressType === 'Office'
              ? 'OfficeCity'
              : 'City'
        }
        control={control}
        rules={{ required: 'City is required' }}
        render={({ field, fieldState: { error } }) => (
          <Select
            {...field}
            options={cityOptions}
            placeholder="Search and select..."
            classNamePrefix="select"
            isClearable
            isLoading={isLoading}
            onInputChange={handleCityInputChange}
            onFocus={() => fetchCityOptions()}
            noOptionsMessage={() => 'Type to search...'}
            onChange={(City) => {
              field.onChange(City)
              handleCitySelectionChange(City) // Call a function to handle API call
            }}
            styles={{
              control: (base, state) => ({
                ...base,
                minHeight: '44px',
                borderRadius: '0.75rem',
                borderWidth: '1px',
                marginBottom: '6px',
                borderColor: error
                  ? 'red'
                  : state.isFocused
                    ? 'rgb(59, 130, 246)' // Tailwind blue-500 for focus
                    : 'rgba(218, 222, 236, 1)', // Default gray color

                // Blue outline (ring) on focus, red if error
                boxShadow: state.isFocused
                  ? `0 0 0 1px ${error ? 'red' : 'rgb(59, 130, 246)'}`
                  : '',

                '&:hover': {
                  borderColor: error ? 'red' : 'rgb(59, 130, 246)', // Hover blue if no error
                },
              }),
              placeholder: (base) => ({
                ...base,
                fontWeight: 'normal',
                fontSize: '14px',
                color: error ? 'red' : '#999', // Red placeholder on error
              }),
              menu: (base) => ({
                ...base,
                width: '100%', // Width of the dropdown menu
                maxHeight: '250px', // Max height for the dropdown menu
                borderRadius: '8px',
                zIndex: 49, // Set a higher zIndex to ensure the menu appears above other elements
              }),
              menuList: (base) => ({
                ...base,
                maxHeight: '250px', // Max height of the list container for scrolling
                padding: 0, // Optional padding for the menu list
                zIndex: 49, // Set zIndex for the menu list as well if needed
              }),
              singleValue: (base) => ({
                ...base,
                color: '#333', // Color for selected text
              }),
              dropdownIndicator: (base, state) => ({
                ...base,
                color: state.isFocused
                  ? 'rgb(59, 130, 246)'
                  : 'rgba(156, 163, 175, 1)', // Blue dropdown indicator on focus
                '&:hover': {
                  color: 'rgb(59, 130, 246)',
                },
              }),
              indicatorSeparator: (base) => ({
                ...base,
                display: 'none', // Remove separator
              }),
            }}
          />
        )}
      />
      {errors?.[
        addressType === 'Home'
          ? 'HomeCity'
          : addressType === 'Office'
            ? 'OfficeCity'
            : 'City'
      ] && (
        <div className="error-message text-right text-red-500">
          {
            errors?.[
              addressType === 'Home'
                ? 'HomeCity'
                : addressType === 'Office'
                  ? 'OfficeCity'
                  : 'City'
            ]?.message
          }
        </div>
      )}

      <InputField
        extra="mb-2 w-full"
        label="Address"
        id="Address"
        type="text"
        placeholder="Enter address"
        registrationProps={register(
          addressType === 'Home'
            ? 'HomeAddress'
            : addressType === 'Office'
              ? 'OfficeAddress'
              : 'Address',
          { required: 'Address is required' }
        )}
        isFieldRequired={true}
        state={
          errors?.[
            addressType === 'Home'
              ? 'HomeAddress'
              : addressType === 'Office'
                ? 'OfficeAddress'
                : 'Address'
          ] && 'error'
        }
      />
      <div className="error-message text-right">
        {
          errors?.[
            addressType === 'Home'
              ? 'HomeAddress'
              : addressType === 'Office'
                ? 'OfficeAddress'
                : 'Address'
          ]?.message
        }
      </div>

      <InputField
        extra="mb-2 w-full"
        label="Location Name / Area Name / Postal Code"
        id="PostalCode"
        type="text"
        placeholder="Enter Location Name / Area Name / Postal Code"
        registrationProps={register(
          addressType === 'Home'
            ? 'HomePostalCode'
            : addressType === 'Office'
              ? 'OfficePostalCode'
              : 'PostalCode',
          {
            required: 'This field is required',
          }
        )}
        isFieldRequired={true}
        state={
          errors?.[
            addressType === 'Home'
              ? 'HomePostalCode'
              : addressType === 'Office'
                ? 'OfficePostalCode'
                : 'PostalCode'
          ] && 'error'
        }
        onKeyUp={(event) => {
          debouncedKeyUp(event.target.value)
        }}
      />
      <div className="error-message text-right">
        {
          errors?.[
            addressType === 'Home'
              ? 'HomePostalCode'
              : addressType === 'Office'
                ? 'OfficePostalCode'
                : 'PostalCode'
          ]?.message
        }
      </div>

      <InputField
        extra="mb-2 w-full"
        label="Latitude"
        id="Latitude"
        type="text"
        registrationProps={register(
          addressType === 'Home'
            ? 'HomeLatitude'
            : addressType === 'Office'
              ? 'OfficeLatitude'
              : 'Latitude'
        )}
      />

      <InputField
        extra="mb-2 w-full"
        label="Longitude"
        id="Longitude"
        type="text"
        registrationProps={register(
          addressType === 'Home'
            ? 'HomeLongitude'
            : addressType === 'Office'
              ? 'OfficeLongitude'
              : 'Longitude'
        )}
      />

      {!addressType &&
        getValues(
          addressType === 'Home'
            ? 'HomeLatitude'
            : addressType === 'Office'
              ? 'OfficeLatitude'
              : 'Latitude'
        ) &&
        getValues(
          addressType === 'Home'
            ? 'HomeLongitude'
            : addressType === 'Office'
              ? 'OfficeLongitude'
              : 'Longitude'
        ) && (
          <MapComponent
            lat={getValues(
              addressType === 'Home'
                ? 'HomeLatitude'
                : addressType === 'Office'
                  ? 'OfficeLatitude'
                  : 'Latitude'
            )}
            lng={getValues(
              addressType === 'Home'
                ? 'HomeLongitude'
                : addressType === 'Office'
                  ? 'OfficeLongitude'
                  : 'Longitude'
            )}
          />
        )}

      {getValues('Latitude') && getValues('Longitude') && (
        <div>
          <InputField
            extra="mb-2 w-full"
            id="formattedAddress"
            type="hidden"
            registrationProps={register('formattedAddress')}
          />
          {formattedAddress}
        </div>
      )}
    </div>
  )
}

export default AddressComponent
