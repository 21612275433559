import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MdEdit } from 'react-icons/md'
import TooltipHorizon from 'components/tooltip'
import { RiDeleteBinLine } from 'react-icons/ri'
import { BiCloudDownload } from 'react-icons/bi'
import { useSpinner } from 'common/SpinnerLoader'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import moment from 'moment'

const apiService = ApiCaller()

const SelectFSR = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const modalRef = useRef(null) // Ref for the modal content
  const { showSpinner, hideSpinner } = useSpinner()

  const [isPopupOpen, setIsPopUpOpen] = useState(false)
  const [templates, setTemplates] = useState([]) // To store the template list
  const [fsrs, setFsrs] = useState([])

  const openPopup = () => {
    setIsPopUpOpen(true)
  }

  const closePopup = () => {
    setIsPopUpOpen(false)
  }

  // Close popup if clicked outside the modal
  const handleOverlayClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      closePopup() // Close the popup if click is outside the modal content
    }
  }

  const fetchFsrTemplateList = async () => {
    try {
      const payload = {
        page: 1,
        keyword: '',
      }
      const apiUrl = apiConfig.FSR_TEMPLATE_LIST
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        setTemplates(response?.data?.data?.results)
      }
    } catch (error) {
      console.error('Error fetching template list:', error)
    }
  }

  const fetchFsrs = async () => {
    try {
      const payload = {
        _id: id,
      }
      const apiUrl = apiConfig.GET_FSRS
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        setFsrs(response?.data?.data?.fsrs)
      }
    } catch (error) {
      console.error('Error fetching FSRs:', error)
    }
  }
  useEffect(() => {
    fetchFsrs()
  }, [])

  // Fetch template list (mock API call)
  useEffect(() => {
    if (isPopupOpen) {
      fetchFsrTemplateList()
    }
  }, [isPopupOpen])

  const saveSelectedTemplate = async (template) => {
    try {
      showSpinner()
      const payload = {
        templateFsr: template._id,
        _id: id,
      }
      const apiUrl = apiConfig.GENERATE_FSR_FROM_GPT
      const response = await apiService.apiCall('post', apiUrl, payload, {
        timeout: 120000,
      })
      fetchFsrs()
    } catch (error) {
      console.error('Error creating FSR usin template: ', error)
    } finally {
      hideSpinner()
    }
  }

  // Handle template selection
  const handleTemplateSelect = (template) => {
    if (template) {
      saveSelectedTemplate(template)
      closePopup()
    }
  }

  const handleEditFsr = (fsr) => {
    navigate(`/templates/fsr-templates-view/${id}/${fsr._id}`)
  }

  const handleDeleteFSR = async (fsr) => {
    console.log('Delete selected fsr', fsr)
  }

  return (
    <div className="container mx-auto mt-8 rounded-lg bg-white p-2 shadow-md overflow-y-auto">
      <div className="flex items-center justify-between mb-2 mx-2 p-2 rounded-lg">
        <h2 className="ml-2 text-2xl font-semibold">FSR</h2>
        <button
          className={`cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
      hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
          type="submit"
          onClick={openPopup}
        >
          <span>Create Using Template</span>
        </button>
      </div>
      <div className="flex flex-col items-start justify-between mb-4 mx-2 p-2 rounded-lg">
        <div className="mt-2 min-h-[100px] w-full overflow-x-scroll xl:overflow-hidden">
          <table className="w-full border-collapse">
            <thead className="">
              <tr className="items-center border-b border-gray-200">
                <th className="px-4 py-2 text-left text-xs text-gray-600">
                  User Name
                </th>
                <th className="px-4 py-2 text-left text-xs text-gray-600">
                  FSR Template Type
                </th>
                <th className="px-4 py-2 text-left text-xs text-gray-600">
                  Date
                </th>
                <th className="px-4 py-2 text-left text-xs text-gray-600">
                  Status
                </th>
                <th className="px-4 py-2 text-left text-xs text-gray-600">
                  PDF
                </th>
                <th className="px-4 py-2 text-left text-xs text-gray-600">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Example Row 1 */}
              {fsrs.map((fsr) => (
                <tr key={fsr._id} className="border-b border-gray-200">
                  <td className="px-4 py-2 text-left font-medium text-navy-700">
                    Amey
                  </td>
                  <td className="px-4 py-2 text-left font-medium text-navy-700">
                    {fsr.name}
                  </td>
                  <td className="px-4 py-2 text-left font-medium text-navy-700">
                    {moment(fsr.timestamp).format('DD-MM-YYYY')}
                  </td>
                  <td className="px-4 py-2 text-left font-medium text-navy-700">
                    {fsr.approvalStatus}
                  </td>
                  <td className="px-4 py-2">
                    <div className="justify-left space-x-2">
                      <div className="w-max rounded-xl py-1 text-sm">
                        <TooltipHorizon
                          extra="border border-gray-200"
                          trigger={
                            <p
                              className="flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white"
                              onClick={() => console.log('Dowload John Doe')}
                            >
                              <span>
                                <BiCloudDownload className="text-xl" />
                              </span>
                            </p>
                          }
                          content="Download"
                          placement="top"
                        />
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-2 flex space-x-2">
                    <div className="justify-left flex space-x-2">
                      <div className="w-max rounded-xl py-1 text-sm">
                        <TooltipHorizon
                          extra="border border-gray-200"
                          trigger={
                            <p
                              className="flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white"
                              onClick={() => handleEditFsr(fsr)}
                            >
                              <span>
                                <MdEdit className="text-xl" />
                              </span>
                            </p>
                          }
                          content="Edit"
                          placement="top"
                        />
                      </div>
                      <div className="w-max rounded-xl py-1 text-sm">
                        <TooltipHorizon
                          extra="border border-gray-200"
                          trigger={
                            <p
                              className="flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white"
                              onClick={() => handleDeleteFSR(fsr)}
                            >
                              <span>
                                <RiDeleteBinLine className="text-xl" />
                              </span>
                            </p>
                          }
                          content="Delete"
                          placement="top"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
              {/* Add more rows as needed */}
            </tbody>
          </table>
        </div>
      </div>

      {isPopupOpen && (
        <div
          className="relative z-[99]"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
          onClick={handleOverlayClick}
        >
          {/* <!-- Overlay background --> */}
          <div
            className="fixed inset-0 bg-gray-500/75 transition-opacity"
            aria-hidden="true"
          ></div>
          {/* <!-- Modal container --> */}
          <div className="fixed inset-0 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              {/* <!-- Modal content --> */}
              <div
                ref={modalRef}
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
              >
                {/* <!-- Title section --> */}
                <div className="bg-brand-500 px-2 pb-4 sm:p-2 sm:pb-2">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-2 text-center sm:ml-2 sm:mt-0 sm:text-left">
                      <h2
                        className="text-lg font-semibold text-white"
                        id="modal-title"
                      >
                        Select FSR Templates
                      </h2>
                    </div>
                  </div>
                </div>
                {/* Table */}
                <div className="max-h-60 overflow-y-auto px-3 mt-3">
                  <table className="w-full border-collapse">
                    <thead className="mt-4">
                      <tr className="items-center border-b border-gray-200">
                        <th className="px-4 py-2 text-left text-xs text-gray-600">
                          Template Name
                        </th>

                        <th className="px-4 py-2 text-center text-xs text-gray-600">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {templates.map((template) => (
                        <tr
                          key={template._id}
                          className="border-b border-gray-200"
                        >
                          <td className="px-4 py-2 text-left font-medium text-navy-700">
                            {template.name}
                          </td>
                          <td className="px-4 py-2 flex space-x-2 justify-center">
                            <div className="justify-center flex space-x-2">
                              <div className="w-max rounded-xl py-1 text-sm">
                                <TooltipHorizon
                                  extra="border border-gray-200"
                                  trigger={
                                    <p
                                      className="flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white"
                                      onClick={() =>
                                        handleTemplateSelect(template)
                                      }
                                    >
                                      <span>
                                        <MdEdit className="text-xl" />
                                      </span>
                                    </p>
                                  }
                                  content="Edit"
                                  placement="top"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SelectFSR
