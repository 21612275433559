import { readFileBasedOnId } from 'common/commonFunction'

const getInitials = (name) => {
  return name
    .split(' ')
    .map((word) => word[0])
    .join('')
    .toUpperCase()
}
const Card = ({ data, expandedNodes, toggleExpand }) => {
  return (
    <ul>
      <div className="flex justify-center ">
        {data.map((node) => (
          <li key={node.id}>
            <div className="card">
              <div className="image pt-[20px]">
                {node.photo ? (
                  <img
                    src={readFileBasedOnId(node?.photo)}
                    alt={node.name}
                    className="rounded-full  mx-auto mb-1"
                  />
                ) : (
                  <div className="bg-gray-300 text-white rounded-full text-[24px] w-[50px] h-[50px] flex items-center justify-center mx-auto mb-1">
                    {node.initials || getInitials(node.name)}
                  </div>
                )}
                <div className="card-body">
                  <h3 className="font-semibold text-md ">{node.name}</h3>
                  <p className="text-blue-600 text-sm mt-1">{node.title}</p>
                  <p className="text-blue-600 text-sm mt-2">{node.location}</p>
                </div>
                <div className="card-footer ">
                  <p className="text-blue-600 text-sm font-semibold">
                    {node.role}
                  </p>
                  {node.children && node.children.length > 0 && (
                    <button
                      onClick={() => toggleExpand(node.id)}
                      className={`button relative flex justify-center items-center bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1 bg-blue-500 text-white rounded-full w-6 h-6 text-md ${
                        expandedNodes[node.id] ? 'expanded' : ''
                      }`}
                    >
                      {expandedNodes[node.id] ? (
                        '-'
                      ) : (
                        <>{node.children.length}</>
                      )}
                    </button>
                  )}
                </div>
              </div>
              <div className="card-body"></div>

              <p className="text-blue-600 text-xs font-semibold">{node.role}</p>
            </div>
            {node.children?.length > 0 && expandedNodes[node.id] && (
              <Card
                data={node.children}
                expandedNodes={expandedNodes}
                toggleExpand={toggleExpand}
              />
            )}
          </li>
        ))}
      </div>
    </ul>
  )
}

export default Card
