import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import Card from 'components/card'
import { useParams, useNavigate } from 'react-router-dom'
import { useSpinner } from 'common/SpinnerLoader'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import { useToaster } from 'common/Toaster'
import moment from 'moment'
import SwitchWithText from 'components/switch-with-text'
import InputField from 'components/fields/InputField'
import {
  dropDownStyleWithErrorAndFocus,
  dropDownStyleWithoutErrorAndFocus,
} from 'common/commonFunction'

const apiService = ApiCaller()
const AddOfficer = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { showSpinner, hideSpinner } = useSpinner()
  const { addToast } = useToaster()
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [showPassword, setShowPassword] = useState(false)
  const [roles, setRoles] = useState([])
  const [selectedRoles, setSelectedRoles] = useState([])
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      customer: '',
      Salutation: '',
      FirstName: '',
      LastName: '',
      DateOfBirth: null,
      Designation: '',
      Email: '',
      OfficeNumber: '',
      Password: '',
      MobileNumber: '',
      Role: [],
    },
  })
  const [isOfficerActive, setOfficerStatus] = useState(false)

  const changeOfficerStatus = () => {
    setOfficerStatus(!isOfficerActive)
  }
  const handleCancel = () => {
    navigate(`/customers/officer-list`)
  }

  const handleInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      fetchOptions(inputValue)
    }
  }

  const handleSelectionChange = (company) => {
    if (!company) {
      fetchOptions() // Call the function to perform API request
      setOptions([])
    }
  }

  useEffect(() => {
    if (id) {
      getOfficerDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.OFFICER_CREATE
      const payload = {
        _id: id ? id : undefined,
        customer: formData.customer.fullObject,
        salutation: formData.Salutation.value,
        firstName: formData.FirstName,
        lastName: formData.LastName,
        name: formData.FirstName + ' ' + formData.LastName,
        designation: formData.Designation,
        officeNumber: formData.OfficeNumber,
        mobileNumber: formData.MobileNumber,
        birthDate: formData.DateOfBirth,
        email: formData.Email,
        password: formData.Password,
        isActive: isOfficerActive,
        role: selectedRoles.map((role) => role.fullObject),
      }

      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        addToast({
          title: `Officer "${formData.customer.label}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        navigate(`/customers/officer-list`)
      } else {
        const errorMessage = response?.data?.error?.message || 'Unknown error'
        addToast({
          title: `Officer creation failed: ${errorMessage}`,
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error creating officer:', error)
      addToast({
        title: 'Error creating officer',
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  const getOfficerDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.OFFICER_DETAIL
      const payload = {
        _id: id,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data === 'Access Denied') {
        addToast({
          title: 'Access Denied',
          type: 'error',
        })
        navigate(`/company-setup/officer-list`)
      } else if (response?.data?.value) {
        const officerData = response?.data?.data
        setOfficerStatus(officerData?.isActive)
        setValue('Salutation', {
          label: officerData?.salutation || '',
          value: officerData?.salutation || '',
        })
        setValue('FirstName', officerData?.firstName || '')
        setValue('LastName', officerData?.lastName || '')
        setValue('Designation', officerData?.designation || '')
        setValue('OfficeNumber', officerData?.officeNumber || '')
        setValue('MobileNumber', officerData?.mobileNumber || '')
        setValue(
          'DateOfBirth',
          officerData?.birthDate
            ? moment(new Date(officerData.birthDate)).format('YYYY-MM-DD')
            : null
        )
        setValue('Email', officerData?.email || '')
        setValue('Password', officerData?.password || '')
        setValue('customer', {
          label: officerData?.customer?.name || '',
          value: officerData?.customer?._id || '',
          fullObject: officerData?.customer || null,
        })
        const rolesWithFullObject = officerData.role.map((role) => ({
          label: role.name,
          value: role._id,
          fullObject: role,
        }))
        setSelectedRoles(rolesWithFullObject)
      } else {
        addToast({
          title: 'Some Error Occured',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error fetching officer details:', error)
    } finally {
      hideSpinner()
    }
  }

  const fetchOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.CUSTOMER_LIST
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: {},
        page: inputValue ? 1 : 0,
      }

      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
          fullObject: item,
        }))
        setOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const salutationOptions = [
    { value: 'Mr', label: 'Mr.' },
    { value: 'Ms', label: 'Ms.' },
    { value: 'Mrs', label: 'Mrs.' },
    { value: 'Dr', label: 'Dr.' },
  ]

  const fetchRole = async () => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.ROLE_LIST

      const payload = {
        role: selectedRoles.map((role) => role.fullObject),
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const fetchedRoles = response?.data?.data?.results.map((role) => ({
          label: role.name,
          value: role._id,
          fullObject: role,
        }))
        setRoles(fetchedRoles)
      }
    } catch (error) {
      console.error('Error fetching roles:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev)
  }

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="mt-7 grid grid-cols-2 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <p className="text-xl font-bold text-navy-700">
              {id ? 'Edit' : 'Add'} Officer
            </p>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <div className="flex w-full justify-end">
              <SwitchWithText
                textLeft="OFF"
                textRight="ON"
                checked={isOfficerActive}
                onChange={changeOfficerStatus}
              />
            </div>
          </div>
        </div>

        <div className="mt-7 grid grid-cols-2 gap-3">
          <div className="col-span-2 ">
            <label className="mb-2 block text-sm font-bold text-navy-700">
              Customer Company
              <span className="text-red-500"> * </span>
            </label>
            <Controller
              name="customer"
              control={control}
              rules={{ required: 'Customer coompany is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={options}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  onFocus={() => fetchOptions()}
                  isLoading={isLoading}
                  onInputChange={handleInputChange}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(company) => {
                    field.onChange(company)
                    handleSelectionChange(company)
                  }}
                  styles={dropDownStyleWithErrorAndFocus(error)}
                />
              )}
            />
            {errors.customer && (
              <div className="error-message text-right text-red-500">
                {errors.customer.message}
              </div>
            )}
          </div>

          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold text-navy-700">
              Salutation
              <span className="text-red-500"> * </span>
            </label>
            <Controller
              name="Salutation"
              control={control}
              rules={{ required: 'Salutation is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={salutationOptions}
                  placeholder="Select salutation"
                  isClearable
                  classNamePrefix="select"
                  styles={dropDownStyleWithErrorAndFocus(error)}
                />
              )}
            />
            {errors.Salutation && (
              <div className="error-message text-right text-red-500">
                {errors.Salutation.message}
              </div>
            )}
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full "
              label="First Name"
              id="FirstName"
              type="text"
              placeholder="Enter first name"
              registrationProps={register('FirstName', {
                required: 'First name is required',
              })}
              isFieldRequired={true}
              state={errors.FirstName && 'error'}
            />
            <div className="error-message text-right">
              {errors.FirstName?.message}
            </div>
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="Last Name"
              id="LastName"
              type="text"
              placeholder="Enter last name"
              registrationProps={register('LastName', {
                required: 'Last name is required',
              })}
              isFieldRequired={true}
              state={errors.LastName && 'error'}
            />
            <div className="error-message text-right">
              {errors.LastName?.message}
            </div>
          </div>

          <div className="col-span-2 mb-4 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold text-navy-700">
              Date of Birth
            </label>
            <Controller
              name="DateOfBirth"
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  type="date"
                  autoComplete="off"
                  placeholder="dd/mm/yyyy"
                  className="w-full rounded-xl border p-2 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                />
              )}
            />
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="Designation"
              id="Designation"
              type="text"
              placeholder="Enter designation"
              registrationProps={register('Designation', {
                required: 'Designation is required',
              })}
              isFieldRequired={true}
              state={errors.Designation && 'error'}
            />
            <div className="error-message text-right">
              {errors.Designation?.message}
            </div>
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="Email ID"
              id="Email"
              type="email"
              placeholder="Enter email ID"
              registrationProps={register('Email', {
                required: 'Email is required',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Invalid email address',
                },
              })}
              isFieldRequired={true}
              state={errors.Email && 'error'}
            />
            <div className="error-message text-right">
              {errors.Email?.message}
            </div>
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="Office Number"
              id="OfficeNumber"
              type="text"
              placeholder="Enter officer number"
              registrationProps={register('OfficeNumber')}
            />
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="Password"
              id="Password"
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter password"
              registrationProps={register('Password', {
                required: 'Password is required',
                minLength: {
                  value: 6,
                  message: 'Password must be at least 6 characters',
                },
              })}
              isFieldRequired={true}
              hasToggle={true}
              state={errors.Password && 'error'}
              showPassword={showPassword}
              togglePasswordVisibility={togglePasswordVisibility}
            />
            <div className="error-message text-right">
              {errors.Password?.message}
            </div>
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="mb-2 w-full"
              label="Mobile Number"
              id="MobileNumber"
              type="tel"
              placeholder="Enter mobile number"
              registrationProps={register('MobileNumber', {
                pattern: {
                  value: /^[0-9]{10}$/,
                  message: 'Invalid mobile number',
                },
              })}
            />
          </div>

          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold text-navy-700">
              Role
            </label>
            <Controller
              name="Role"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={roles}
                  placeholder="Select role(s)"
                  isLoading={isLoading}
                  isMulti
                  value={selectedRoles}
                  onFocus={() => fetchRole()}
                  onChange={(selected) => {
                    setSelectedRoles(selected || [])
                    field.onChange(selected)
                  }}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  styles={dropDownStyleWithoutErrorAndFocus()}
                />
              )}
            />
          </div>
        </div>
        <div className="mt-4 flex w-full justify-end">
          <button
            className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
            type="submit"
          >
            <span>{id ? 'Update' : 'Save'}</span>
          </button>

          <button
            className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
              hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base focus:outline-none focus:ring-2 focus:ring-gray-700 active:ring-2 active:ring-gray-500`}
            type="button"
            onClick={handleCancel}
          >
            <span>Cancel</span>
          </button>
        </div>
      </form>
    </Card>
  )
}

export default AddOfficer
