import React, { useState, useRef } from 'react'
import { FaTrash } from 'react-icons/fa'
import CrawfordConstants from 'common/config/crawfordConstants'
import TooltipHorizon from '../../../../components/tooltip'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import { BiSolidSelectMultiple } from 'react-icons/bi'
import { PiSelectionFill } from 'react-icons/pi'
import { useToaster } from 'common/Toaster'
import notepadLogo from 'assets/img/others/notepadLogo.png'
import pdfLogo from 'assets/img/others/pdfLogo.png'
import wordLogo from 'assets/img/others/wordLogo.png'
import excelLogo from 'assets/img/others/excelLogo.png'
import { useSpinner } from 'common/SpinnerLoader'
import { MdCloudDownload } from 'react-icons/md'
import { readFileBasedOnId } from 'common/commonFunction'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { MdKeyboardArrowUp } from 'react-icons/md'

const apiService = ApiCaller()

const ImageGallery = ({
  images,
  assignmentTimeline,
  userInfo,
  fetchAssignmentAndTimeLineDetails,
}) => {
  const [selectedImages, setSelectedImages] = useState([])
  const [assignmentFile, setAssignmetFile] = useState(null)
  const [attachementOpen, setAttachementOpen] = useState(false)
  const timelineDocRef = useRef(null) //
  const { NO_DATA } = CrawfordConstants
  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()
  const [imageViewerOpen, setImageViewerOpen] = useState(false)
  const [fileData, setFileData] = useState()
  const getFileLogo = (fileUrl, extension) => {
    const extensionMap = {
      pdf: pdfLogo,
      doc: wordLogo,
      docx: wordLogo,
      xls: excelLogo,
      xlsx: excelLogo,
      png: fileUrl,
      jpg: fileUrl,
      jpeg: fileUrl,
      txt: notepadLogo,
      default: notepadLogo,
    }
    return (
      extensionMap[extension && extension.toLowerCase()] || extensionMap.default
    )
  }

  // Toggle all images
  const toggleSelectAll = () => {
    if (selectedImages.length === images.length) {
      setSelectedImages([]) // Deselect all if already selected
    } else {
      setSelectedImages(images.map((img) => img._id)) // Select all
    }
  }

  const toggleSelect = (id) => {
    setSelectedImages((prev) =>
      prev.includes(id) ? prev.filter((imgId) => imgId !== id) : [...prev, id]
    )
  }

  // Function to remove multiple items by array of _ids
  const removeByIds = (array, ids) => {
    return array.filter((item) => !ids.includes(item._id))
  }

  // Function to remove an item by _id
  const removeById = (array, id) => {
    return array.filter((item) => item._id !== id)
  }

  const deleteSelected = () => {
    if (selectedImages.length === images.length) {
      saveAssignment([], 'deleted')
    } else {
      const updatedImagesData = removeByIds(images, selectedImages)
      saveAssignment(updatedImagesData, 'deleted')
    }
  }

  const deleteImage = (id) => {
    const updatedImagesData = removeById(images, id)
    saveAssignment(updatedImagesData, 'deleted')
  }

  const saveAssignmentTimeLine = async (files, actionName) => {
    try {
      showSpinner()
      const data = {
        timelineId: assignmentTimeline?.timeline?._id,
        lastTimelineObj: {
          file: files.flat() || [],
          employee: userInfo.employee,
          type: 'Normal',
          title: `Attachment(s) ${actionName}`,
          attachment: files.flat() || [],
        },
        employeeId: userInfo.employee,
        type: 'photos',
      }
      const apiUrl = apiConfig.SAVE_ASSIGNMENT_TIME_LINE
      const response = await apiService.apiCall('post', apiUrl, data)
      if (response?.data?.value) {
        fetchAssignmentAndTimeLineDetails()
        addToast({
          title: `Attachment(s) ${actionName} successfully.`,
          type: 'success',
        })
      }
    } catch (error) {
      console.error('Error uploading files:', error)
    } finally {
      hideSpinner()
    }
  }

  const uploadFiles = async (files) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.UPLOAD_IMAGE
      const uploadedFiles = []
      for (const file of files) {
        const data = new FormData()
        data.append('isTimelineAttachement', true)
        data.append('file', file)
        // Upload each file
        const response = await apiService.apiCall('post', apiUrl, data)
        if (response?.data?.value) {
          uploadedFiles.push(response?.data?.data)
        }
      }
      // Set all uploaded files together
      if (timelineDocRef.current) {
        timelineDocRef.current.value = '' // Clears the selected file
      }
      setAssignmetFile(uploadedFiles)
      saveAssignmentTimeLine(uploadedFiles, 'uploaded')
    } catch (error) {
      console.error('Error uploading files:', error)
    } finally {
      hideSpinner()
    }
  }

  const handleAssignmentFiles = (e) => {
    const selectedFiles = e.target.files
    if (selectedFiles?.length) {
      uploadFiles(selectedFiles)
    }
  }

  const saveAssignment = async (files, actionName) => {
    try {
      showSpinner()
      const data = {
        _id: assignmentTimeline?.assignment?._id,
        photos: files || [],
        users: {
          email: userInfo.email,
          name: userInfo.name,
        },
      }
      const apiUrl = apiConfig.SAVE_ASSIGNMENT
      const response = await apiService.apiCall('post', apiUrl, data)
      if (response?.data?.value) {
        addToast({
          title: `Attachment(s) ${actionName} successfully.`,
          type: 'success',
        })
        saveAssignmentTimeLine([], actionName)
        setSelectedImages([])
      }
    } catch (error) {
      console.error('Error uploading files:', error)
    } finally {
      hideSpinner()
    }
  }

  const openAttachement = (fileData) => {
    setFileData(fileData)
    setImageViewerOpen(true)
  }

  return (
    <div className="mt-2 h-fit w-full border-0 rounded-2xl">
      <Accordion
        className="bg-white border-0 rounded-2xl"
        defaultIndex={[0]}
        allowMultipleExpanded={true}
        allowZeroExpanded={true}
      >
        <AccordionItem className="bg-white border-0 p-2 ml-2 rounded-2xl">
          <AccordionItemHeading
            className="pr-6"
            onClick={() => {
              setAttachementOpen(!attachementOpen)
            }}
          >
            <AccordionItemButton className="flex items-center justify-between py-2">
              <p className="flex block text-sm font-bold">Attachments</p>
              <div className="flex items-center gap-2">
                {/* New Button */}
                <div className="col-span-2 lg:col-span-1">
                  <div className="flex w-full justify-end">
                    <label
                      htmlFor="timeline-doc"
                      className={`w-20 h-8 px-4 py-1 cursor-pointer items-center justify-center rounded-xl bg-brand-500 text-xs text-white transition duration-200
                              hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
                    >
                      Upload
                    </label>
                    <input
                      type="file"
                      id="timeline-doc"
                      ref={timelineDocRef} // Attach ref to the input
                      className="hidden" // Hide the default input
                      onChange={handleAssignmentFiles}
                      multiple // Enables multi-select
                    />
                  </div>
                </div>
                {/* Arrow Icon */}
                {attachementOpen ? (
                  <MdKeyboardArrowDown className="text-lg text-gray-600" />
                ) : (
                  <MdKeyboardArrowUp className="text-lg text-gray-600" />
                )}
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>

          <AccordionItemPanel className="py-0">
            {images.length > 0 && (
              <div className="p-4">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex justify-between items-center">
                    <span className="text-gray-600 mr-2">
                      {selectedImages.length} image(s) selected
                    </span>
                    <div className="flex justify-between items-center">
                      <TooltipHorizon
                        extra="border border-gray-200 dark:border-gray-700"
                        trigger={
                          <p>
                            <span>
                              <button
                                className="mr-2 bg-gray-500 text-white h-6 w-6 p-1.5 rounded-full hover:bg-gray-600 hover:opacity-80"
                                onClick={toggleSelectAll}
                              >
                                {selectedImages.length === images.length ? (
                                  <PiSelectionFill className="h-3 w-3" />
                                ) : (
                                  <BiSolidSelectMultiple className="h-3 w-3" />
                                )}
                              </button>
                            </span>
                          </p>
                        }
                        content={
                          selectedImages.length === images.length
                            ? 'Unselect All'
                            : 'Select All'
                        }
                        placement="top"
                      />

                      {selectedImages.length > 0 && (
                        <>
                          <TooltipHorizon
                            extra="border border-gray-200 dark:border-gray-700"
                            trigger={
                              <p>
                                <span>
                                  <button
                                    className="mr-2 bg-red-500 text-white h-6 w-6 p-1.5 rounded-full hover:bg-red-600 hover:opacity-80"
                                    onClick={deleteSelected}
                                  >
                                    <FaTrash className="h-3 w-3" />
                                  </button>
                                </span>
                              </p>
                            }
                            content="Delete All"
                            placement="top"
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-8 gap-4">
                  {images.map((image) => (
                    <div>
                      <div
                        key={image._id}
                        className="relative border rounded-lg overflow-hidden shadow-md"
                      >
                        <img
                          src={getFileLogo(
                            image?.url,
                            image?.fileName && image?.fileName.split('.').pop()
                          )}
                          alt={image.fileName}
                          className={`w-full h-[150px] object-cover`}
                          // onClick={() => openAttachement(image)}
                        />
                        <div className="absolute top-1 left-2">
                          <input
                            type="checkbox"
                            className="w-4 h-4"
                            checked={selectedImages.includes(image._id)}
                            onChange={() => toggleSelect(image._id)}
                          />
                        </div>
                        <div className="absolute bottom-2 right-2 flex space-x-2">
                          <TooltipHorizon
                            extra="border border-gray-200 dark:border-gray-700"
                            trigger={
                              <p>
                                <span>
                                  <button
                                    className="bg-red-500 opacity-40 text-white h-6 w-6 p-1.5 rounded-full hover:bg-red-600 hover:opacity-80"
                                    onClick={() => deleteImage(image._id)}
                                    title="Delete"
                                  >
                                    <FaTrash className="h-3 w-3" />
                                  </button>
                                </span>
                              </p>
                            }
                            content="Delete"
                            placement="top"
                          />
                          <TooltipHorizon
                            extra="border border-gray-200"
                            trigger={
                              <a
                                href={readFileBasedOnId(image.file)}
                                download="attachment"
                                className="bg-blue-500 opacity-40 text-white h-6 w-6 p-1.5 rounded-full hover:bg-blue-600 hover:opacity-80"
                              >
                                <span>
                                  <MdCloudDownload className="h-3 w-3" />
                                </span>
                              </a>
                            }
                            content="Download"
                            placement="top"
                          />
                        </div>
                      </div>
                      <div className="text-center border rounded-lg bg-gray-500 mb-4 text-sm text-black">
                        <TooltipHorizon
                          extra="border border-gray-200 dark:border-gray-700"
                          trigger={
                            <p
                              className="cursor-pointer items-center hover:font-medium w-[150px] truncate px-2 py-1"
                              title={image.fileName}
                            >
                              <span>{image.fileName || 'Unnamed Image'}</span>
                            </p>
                          }
                          content={image.fileName}
                          placement="top"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {images.length === 0 && (
              <div className="flex justify-between items-center mb-4">
                <div className="text-md flex w-full justify-center text-center text-gray-500 dark:text-gray-400">
                  {NO_DATA}
                </div>
              </div>
            )}

            {imageViewerOpen && (
              <div
                className="relative z-[9999]"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
              >
                {/* Overlay */}
                <div
                  className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                  aria-hidden="true"
                ></div>

                {/* Modal container */}
                <div className="fixed inset-0 flex items-center justify-center overflow-y-auto p-4 sm:p-6">
                  {/* Modal content */}
                  <div className="relative w-full max-w-2xl transform overflow-hidden rounded-lg bg-white shadow-xl sm:my-8">
                    {/* Header */}
                    <div className="flex justify-between items-center bg-brand-500 px-4 py-3 sm:px-6">
                      <h2
                        className="text-lg font-semibold text-white"
                        id="modal-title"
                      >
                        Attachement - {fileData.fileName}
                      </h2>
                      {/* Close Icon */}
                      <button
                        onClick={() => setImageViewerOpen(false)}
                        className="text-white hover:text-gray-300"
                        aria-label="Close modal"
                      >
                        &#10005; {/* Unicode for '×' */}
                      </button>
                    </div>
                    {/* Body */}
                    <div className="h-full overflow-y-auto px-2 py-2 sm:p-4 max-h-[450px]">
                      {fileData?.fileName
                        .split('.')
                        .pop()
                        .toLowerCase()
                        .includes(['jpg', 'jpeg', 'png', 'gif']) ? (
                        <img
                          src={readFileBasedOnId(fileData.file)}
                          alt="Placeholder"
                        />
                      ) : (
                        <iframe
                          src={readFileBasedOnId(fileData.file)}
                          width="100%"
                          height="600px"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default ImageGallery
