import React, { useState, useEffect } from 'react'
import { useSpinner } from 'common/SpinnerLoader'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import './hierarchy.css'
import CrawfordConstants from 'common/config/crawfordConstants'
import Card from './Card'
import { FaDatabase } from 'react-icons/fa'

const getInitials = (name) => {
  return name
    .split(' ')
    .map((word) => word[0])
    .join('')
    .toUpperCase()
}

const transformData = (data) => {
  return data.map((item) => ({
    id: item._id,
    name: item.name,
    title: item.isSurveyor ? 'Surveyor' : 'Non-Surveyor',
    location: item.location,
    role: 'Employee',
    initials: getInitials(item.name),
    children: item.reportsTo ? transformData(item.reportsTo) : [],
    photo: item.photo,
  }))
}

const Hierarchy = () => {
  const [expandedNodes, setExpandedNodes] = useState({})
  const [hierarchyData, setHierarchyData] = useState([])
  const { showSpinner, hideSpinner } = useSpinner()
  const apiService = ApiCaller()
  const { NO_DATA } = CrawfordConstants

  const toggleExpand = (id) => {
    setExpandedNodes((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  useEffect(() => {
    const fetchHierarchyData = async () => {
      try {
        showSpinner()
        const apiUrl = apiConfig.HIERARCHY_DETAIL
        const userData = localStorage.getItem('userData')
        const parsedData = userData ? JSON.parse(userData) : null
        const id = parsedData ? parsedData?.employee : null
        const payload = {
          _id: id,
        }
        const response = await apiService.apiCall('post', apiUrl, payload)
        if (response?.data?.value) {
          const transformedData = transformData(response.data.data)
          setHierarchyData(transformedData)
        } else {
          setHierarchyData([])
        }
      } catch (error) {
        console.error('Error fetching hierarchy data:', error)
      } finally {
        hideSpinner()
      }
    }

    fetchHierarchyData()
  }, [])

  return (
    <div className="org-tree !overflow-x-auto h-screen bg-white">
      {hierarchyData.length > 0 ? (
        <Card
          data={hierarchyData}
          expandedNodes={expandedNodes}
          toggleExpand={toggleExpand}
        />
      ) : (
        <div className="flex flex-col items-center justify-center mt-[200px] text-center text-gray-500 dark:text-gray-400">
          <div className="text-4xl mb-2">
            <FaDatabase />
          </div>
          <div className="text-2xl">{NO_DATA}</div>
        </div>
      )}
    </div>
  )
}

export default Hierarchy
