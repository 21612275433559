import React from 'react'
import { FaBuilding } from 'react-icons/fa'
import { IoEarthSharp } from 'react-icons/io5'
import AddFSR from 'views/FsrMasters/FSR/components/AddFSR'
import FSRList from 'views/FsrMasters/FSR'

const fsrMasterRoutes = [
  // Masters List Page
  {
    name: 'FSR',
    layout: '/afterLogin',
    path: '/masters/fsr-list',
    icon: <FaBuilding className="text-inherit h-6 w-6" />,
    component: <FSRList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Masters',
    breadcrum: [
      { label: 'Masters', link: '', breadCrumDisabled: true },
      { label: 'List', link: '', breadCrumDisabled: true },
    ],
  },

  // Masters Create Page
  {
    name: 'FSR',
    layout: '/afterLogin',
    path: '/masters/fsr-create',
    icon: <IoEarthSharp className="text-inherit h-6 w-6" />,
    component: <AddFSR />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Masters',
    breadcrum: [
      { label: 'Masters', link: '', breadCrumDisabled: true },
      { label: 'FSR', link: '/masters/fsr-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },

  // Masters Edit Page
  {
    name: 'FSR',
    layout: '/afterLogin',
    path: '/masters/fsr-edit/:id',
    icon: <IoEarthSharp className="text-inherit h-6 w-6" />,
    component: <AddFSR />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Masters',
    breadcrum: [
      { label: 'Masters', link: '', breadCrumDisabled: true },
      { label: 'FSR', link: '/masters/fsr-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
]

export default fsrMasterRoutes
