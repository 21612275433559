import React, { useState, useEffect } from 'react'
// import { FaTrash } from 'react-icons/fa'
import CrawfordConstants from 'common/config/crawfordConstants'
import TooltipHorizon from '../../../../components/tooltip'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
// import { useToaster } from 'common/Toaster'
import { useSpinner } from 'common/SpinnerLoader'
import { MdCloudDownload } from 'react-icons/md'
import { useParams, useNavigate } from 'react-router-dom'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { MdKeyboardArrowUp } from 'react-icons/md'

const TimeLineInvoice = () => {
  const apiService = ApiCaller()
  const navigate = useNavigate()
  const { NO_DATA } = CrawfordConstants
  const [invoiceOpen, setInvoiceOpen] = useState(false)
  const { id } = useParams()
  const { showSpinner, hideSpinner } = useSpinner()
  const [invoiceData, setInvoiceData] = useState([])
  const getTimelineInvoice = async () => {
    try {
      showSpinner()
      const data = {
        _id: id,
      }
      const apiUrl = apiConfig.GET_TIMELINE_INVOICE
      const response = await apiService.apiCall('post', apiUrl, data)
      if (response?.data?.value) {
        setInvoiceData(response?.data?.data?.invoice)
      }
    } catch (error) {
      console.error('Error uploading files:', error)
    } finally {
      hideSpinner()
    }
  }

  useEffect(() => {
    getTimelineInvoice()
  }, [])

  return (
    <div className="mt-2 h-fit w-full border-0 rounded-2xl">
      <Accordion
        className="bg-white border-0 rounded-2xl"
        defaultIndex={[0]}
        allowMultipleExpanded={true}
        allowZeroExpanded={true}
      >
        <AccordionItem className="bg-white border-0 p-2 ml-2 rounded-2xl">
          <AccordionItemHeading
            className="pr-6"
            onClick={() => {
              setInvoiceOpen(!invoiceOpen)
            }}
          >
            <AccordionItemButton className="flex items-center justify-between py-2">
              <p className="flex block text-sm font-bold">Invoice</p>
              <div className="flex items-center gap-2">
                {/* New Button */}
                <button
                  className={`w-28 h-8 p-1 cursor-pointer items-center justify-center rounded-xl bg-brand-500 text-xs text-white transition duration-200
                              hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base focus:outline-none focus:ring-2 focus:ring-brand-700 active:ring-2 active:ring-brand-300`}
                  type="button"
                  onClick={() => {
                    navigate(`/assignments/create-invoice/${id}`)
                  }}
                >
                  <span>Add Invoice</span>
                </button>
                {/* Arrow Icon */}
                {invoiceOpen ? (
                  <MdKeyboardArrowDown className="text-lg text-gray-600" />
                ) : (
                  <MdKeyboardArrowUp className="text-lg text-gray-600" />
                )}
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>

          <AccordionItemPanel className="py-0">
            <div class="container mx-auto p-4">
              <table className="w-full">
                <thead className="w-full">
                  <tr className="items-center border-b border-gray-200">
                    <th className="px-4 pb-[10px] text-left text-xs text-gray-600">
                      Sr.No
                    </th>
                    <th className="px-4 pb-[10px] text-left text-xs text-gray-600">
                      Created By
                    </th>
                    <th className="px-4 pb-[10px] text-left text-xs text-gray-600">
                      Invoice Number
                    </th>
                    <th className="px-4 pb-[10px] text-left text-xs text-gray-600">
                      GrandTotal
                    </th>
                    <th className="px-4 pb-[10px] text-left text-xs text-gray-600">
                      Status
                    </th>
                    <th className="px-4 pb-[10px] text-left text-xs text-gray-600">
                      Actions
                    </th>
                    <th className="px-4 pb-[10px] text-left text-xs text-gray-600">
                      Hardcopy Sent
                    </th>
                    <th className="px-4 pb-[10px] text-left text-xs text-gray-600">
                      Softcopy Sent
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceData.length > 0 &&
                    invoiceData.map((invoice, index) => (
                      <tr
                        key={invoice._id}
                        className="border-b border-gray-200"
                      >
                        <td className="px-4 py-2 text-left font-medium text-navy-700">
                          {index + 1}
                        </td>
                        <td className="px-4 py-2 text-left font-medium text-navy-700">
                          {invoice?.createdBy?.name || '-'}
                        </td>
                        <td className="px-4 py-2 text-left font-medium text-navy-700">
                          {invoice?.invoiceNumber || '-'}
                        </td>
                        <td className="px-4 py-2 text-left font-medium text-navy-700">
                          {invoice?.grandTotal || '-'}
                        </td>
                        <td className="px-4 py-2 text-left font-medium text-navy-700">
                          {invoice?.approvalStatus || '-'}
                        </td>
                        <td className="px-4 py-2 text-left font-medium text-navy-700">
                          <TooltipHorizon
                            extra="border border-gray-200"
                            trigger={
                              <p
                                className="flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white"
                                onClick={() => console.log('Dowload John Doe')}
                              >
                                <span>
                                  <MdCloudDownload className="text-xl" />
                                </span>
                              </p>
                            }
                            content="Download"
                            placement="top"
                          />
                        </td>
                        <td className="px-4 py-2 text-left font-medium text-navy-700">
                          {invoice?.isHardcopySent || '-'}
                        </td>
                        <td className="px-4 py-2 text-left font-medium text-navy-700">
                          {invoice?.isSoftcopySent || '-'}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {/* Display message if no data */}
              {invoiceData.length === 0 && (
                <div className="text-sm mt-3 flex w-full justify-center text-center text-gray-500 font-bold">
                  {NO_DATA}
                </div>
              )}
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default TimeLineInvoice
